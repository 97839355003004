import update from 'immutability-helper';

const v = require('voca');
const validator = require("email-validator");
const Dot = require('dot-object');
const RouteParser = require('route-parser');


class VarHelper {

  detectType = variable => {
    if (variable === null) return 'null';
    switch(typeof variable) {
      case "number": return "number";
      case "string": return "string";
      case "undefined": return "undefined";
      case "boolean": return "boolean";
      case "function": return "function";
      case "object": 
        if(variable.constructor === Array) return "array";
        if(variable.constructor === Error) return "error";
        if(variable.constructor === Promise) return "promise";
      break;
      default: return "object";
    }
  }

  isArr = varriable => this.detectType(varriable) === 'array';

  filterEmptyObjProp = obj => {
    let newObj = {};
    for (let prop in obj) {
      let value = obj[prop];
      if (value !== "" && value !== null && value !== undefined) newObj[prop] = value;
    }
    return newObj;
  }

  isEmail = (email) => validator.validate(email);

  mapImg = ({ lat, lng, zoom = 15, size = '640x480' }) => `https://s6.dev.houselink.info/utils/map-static?lat=${lat}&lng=${lng}&zoom=${zoom}&size=${size}&markers=${lat},${lng}`;

  roundNumber = num => {
    const roundBillion = Math.round(num / 1000000000) * 1000000000;
    if (roundBillion > 0) return roundBillion;
    const roundMillion = Math.round(num / 1000000) * 1000000;
    if (roundMillion > 0) return roundMillion;
    const roundThoudsand = Math.round(num / 1000) * 1000;
    if (roundThoudsand > 0) return roundThoudsand;
    const roundHundread = Math.round(num / 100) * 100;
    if (roundHundread > 0) return roundHundread;
    return Math.round(num);
  }

  numberWithCommas = (x, dotString = ',') => {
    if (!x) return x;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  numberVNDToWords = (number) => {
    return this.textMoney(number);
  }

  bodauTiengViet = str => {
    if (str == undefined) return '';
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ|ệ|ề/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ|ự/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    // str = str.replace(/ /g, "_");
    return str;
  }

  urlSlug = string => {
    return v.kebabCase(this.bodauTiengViet(string));
  }

  youtubeId = (url) => {
    if (url == undefined) return false;
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[7].length == 11) {
      return match[7];
    } else if (url.includes('youtube.com') && !url.includes('?v')) {
      // https://www.youtube.com/e6XdhaleZ-Q
      let parts = url.split("/");
      return parts[parts.length - 1];
    } else {
      return url;
    }
  }

  youtubeImg = (link, size = '0') => {
    const youtubeId = this.youtubeId(link);
    return `https://img.youtube.com/vi/${youtubeId}/${size}.jpg`;
  }

  youtubeMetaUrl = (url) => {
    let id = this.youtubeId(url);
    if (id === false) return undefined;
    return `https://www.youtube.com/v/${id}`;
  }

  

  dotNotate = (obj, _target, _prefix) => {
    let target = _target || {};
    let prefix = _prefix || '';
    Object.keys(obj).forEach((key) => {
      if (typeof obj[key] === 'object') {
        this.dotNotate(obj[key], target, `${prefix}${key}.`);
      } else {
        target[prefix + key] = obj[key];
      }
    });
    return target;
  }

  /*
    let foo = { c: { b: 1 } };
    let bar =  this.dot(foo);
    console.log(bar["c.b"]) // -> 1

    really helpful for TextInput Wrapper
  */
  dot = obj => this.dotNotate(obj, undefined, undefined);

  dotState = {
    get: (parent, label) => {
      if (!label.includes('.')) return parent.state[label];
      let { state } = parent;
      let dotObj = this.dot(state);
      return dotObj[label];
    },
    set: (parent, label, value) => {
      if (!label.includes('.')) {
        return parent.setState({ [label]: value });
      }
      const statePropertyName = label.substring(0, label.indexOf('.'));
      const stateCurrentValue = parent.state[statePropertyName];

      // create new stateValue
      let dotPartArray = label.split('.');
      dotPartArray.shift();
      let updateSchemaDefined = { [dotPartArray.join('.')]: { $set: value } };
      const stateNewValue = update(stateCurrentValue, Dot.object(updateSchemaDefined));

      return parent.setState({ [statePropertyName]: stateNewValue });
    },
  };

  dotParent = (parent) => ({
    get: (label) => {
      if (!label.includes('.')) return parent.state[label];
      let { state } = parent;
      let dotObj = this.dot(state);
      return dotObj[label];
    },
    set: (label, value, callback) => {
      if (!label.includes('.')) {
        return parent.setState({ [label]: value }, callback);
      }
      const statePropertyName = label.substring(0, label.indexOf('.'));
      const stateCurrentValue = parent.state[statePropertyName];

      // create new stateValue
      let dotPartArray = label.split('.');
      dotPartArray.shift();
      let updateSchemaDefined = { [dotPartArray.join('.')]: { $set: value } };
      const stateNewValue = update(stateCurrentValue, Dot.object(updateSchemaDefined));

      return parent.setState({ [statePropertyName]: stateNewValue }, callback);
    },
    setReturnValue: (label, value) => {
      if (!label.includes('.')) {
        return { label, value };
      }
      const statePropertyName = label.substring(0, label.indexOf('.'));
      const stateCurrentValue = parent.state[statePropertyName];

      // create new stateValue
      let dotPartArray = label.split('.');
      dotPartArray.shift();
      let updateSchemaDefined = { [dotPartArray.join('.')]: { $set: value } };
      const stateNewValue = update(stateCurrentValue, Dot.object(updateSchemaDefined));

      return { label: [statePropertyName], value: stateNewValue };
    }
  })

  parseRoute = (schema, url) => {
    if (schema === undefined || url === undefined) return false;
    const route = new RouteParser(schema);
    return route.match(url);
  }
}

export default new VarHelper();