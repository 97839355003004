import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Container from "components/Misc/Container";

class UserCreate extends Component {

  state = {
    phone: '',
    password: '',
    mienBac: false,
    mienNam: false,
  }

  createUser = async (e) => {
    e.preventDefault();
    const { phone, name, password, mienBac, mienNam } = this.state;
    if (!phone || !password || !name) {
      return window.notify.error('Cần điền đủ thông tin');
    }
    if (!mienBac && !mienNam) {
      return window.notify.error('Cần chọn miền Bắc hoặc miền Nam');
    }
    const res = await Store.User.creatNormalUser({
      phone, name, password, registerRegion: mienBac ? 'Bắc' : 'Nam',
    })
    if (res.success) {
      window.notify.success('Tạo tài khoản người chơi thành công');
      this.props.history.push(mienBac ? "/admin/users-mien-bac" : "/admin/users-mien-nam");
    } else {
      window.notify.error('Có lỗi xảy ra');
    }
  }

  onChangeRegion = (stateLabel) => {
    this.setState({
      mienBac: false,
      mienNam: false,
      [stateLabel]: true,
    })
  }

  render() {
    return (
      <Container>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Tạo tài khoản người chơi"
                  category="Lưu ý: đây chỉ là màn hình dùng để test, trên trang quản trị sẽ không có tính năng tạo người chơi"
                  content={
                    <form onSubmit={this.createUser}>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Tên",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.name,
                            onChange: e => this.setState({ name: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Số điện thoại",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "Viết liền, xuất phát từ số 0",
                            value: this.state.phone,
                            onChange: e => this.setState({ phone: e.target.value }),
                          },
                          {
                            label: "Mật khẩu",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.password,
                            onChange: e => this.setState({ password: e.target.value }),
                          }
                        ]}
                      />
                      
                      <Checkbox
                        number={1}
                        isChecked={this.state.mienBac}
                        label="Miền Bắc"
                        onChange={() => this.onChangeRegion('mienBac')}
                        usePropChecked
                      />
                      <Checkbox
                        number={2}
                        isChecked={this.state.mienNam}
                        label="Miền Nam"
                        onChange={() => this.onChangeRegion('mienNam')}
                        usePropChecked
                      />
                      <Button bsStyle="danger" pullRight fill type="submit">
                        Tạo tài khoản
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(UserCreate);
