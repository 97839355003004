import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"

import { TimeHelper } from "../../helpers"

@observer
class UserPlay extends Component {

  state = {
    activePage: 1,
    lastPage: 1,
    list: [],
  }

  onReady = async () => {
    this.getListUser();
  }

  getListUser = async () => {
    const { activePage } = this.state;
    const res = await Store.User.getUserPlay(activePage);
    if (res.success) {
      this.setState({
        activePage: res.page,
        lastPage: res.pages,
        list: res.data,
      })
    }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.getListUser());
  }

  getData = (val, label1, label2) => {
    if (!val.videoData) return '';
    if (!val.videoData[label1]) return '';
    if (!val.videoData[label1][label2]) return '';
    return val.videoData[label1][label2];
  }

  addVideoToList = async userData => {
    if (!userData.video) return;
    const res = await Store.Video.pinVideo({
      url: userData.video,
      thumb: userData.videoThumb,
      name: userData.name,
      userId: userData._id,
    });
    if (res.success) {
      window.notify.success('Đã thêm');
    } else {
      window.notify.error(res.message);
    }
  }

  render() {
    const { list } = this.state;
    const thArray = ["Tên", "Preview", "", "Khu Vực", "FB Like | Share", "YT View | Like", "TT Like | CMT", "Thời gian cập nhật"];
    const tdArray = list.slice().map(val => {
      return [
        val._id,
        val.name,
        !val.video ? '' : <video style={{ width: 250, height: 'auto' }} controls>
          <source src={val.video} type="video/mp4" />
        </video>,
        <Button bsStyle="danger" block fill onClick={() => this.addVideoToList(val)}>Thêm vào list</Button>,
        val.registerRegion,
        `${this.getData(val, 'facebook', 'like')} | ${this.getData(val, 'facebook', 'share')}`,
        `${this.getData(val, 'youtube', 'view')} | ${this.getData(val, 'youtube', 'like')}`,
        `${this.getData(val, 'tiktok', 'like')} | ${this.getData(val, 'tiktok', 'comment')}`,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ]
    })
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title={`Các video quay từ người chơi`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={prop[0]}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    {index === 3 ? val : (
                                      <Link to={"/admin/user-profile/" + prop[0]} style={{ color: 'black' }}>
                                        {val}
                                      </Link>
                                    )}
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'right' }}>
                  <Paging
                    last={this.state.lastPage || 1}
                    activePage={this.state.activePage}
                    onChangePage={this.onChangePage}
                  />
                </div>
                <div className="clearfix" />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(UserPlay);