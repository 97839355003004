import CryptoJS from 'crypto-js';
import update from 'immutability-helper';
const md5 = require('md5');
const GlobalEvent = require('js-events-listener');
const Dot = require('dot-object');

export const clientEncode = (string) => {
  return CryptoJS.AES.encrypt(string, 'f1f9b8c8580a69273737a153602de6c9c97ed95d095d7bf734124a9c567a7871').toString();
};

export const clientDecode = (string) => {
  return CryptoJS.AES.decrypt(string, 'f1f9b8c8580a69273737a153602de6c9c97ed95d095d7bf734124a9c567a7871').toString(CryptoJS.enc.Utf8);
};

class Persist {
  save = async (value, localStorageItem) => {
    if (typeof localStorage == "undefined") return;
    if (value == undefined) return;
    let stringValue = typeof value !== "object" ? String(value) : JSON.stringify(value);
    const oldDecyptedValue = localStorage.getItem(md5(localStorageItem));
    if (oldDecyptedValue !== null) {
      const oldValue = clientDecode(oldDecyptedValue);
      if (oldValue == stringValue) return;
    }
    await localStorage.setItem(md5(localStorageItem), clientEncode(stringValue));
  }

  sync = async (localStorageItem, type) => {
    if (typeof localStorage == "undefined") return;
    let value = localStorage.getItem(md5(localStorageItem));
    if (value == null) return undefined;
    value = clientDecode(value);
    if (type == "string") return value;
    if (type == "number") return Number(value);
    if (type == "object" || type == "array") {
      try {
        let parsed = JSON.parse(value);
        return parsed;
      } catch (err) {
        return undefined;
      }
    }
    if (type == "boolean") return value === "true" ? true : false;
  }
}

export default class StoreClass {

  persist = new Persist();

  getValue = (label) => {
    if (!label.includes('.')) {
      return this[label];
    }

    const parts = label.split('.');
    let data = this[parts[0]];
    for (let i = 0; i < parts.length - 1; i++) {
      data = data[parts[i + 1]];
    }
    return data;
  }

  setValue = (label, value) => {
    if (!label.includes('.')) {
      this[label] = value;
      return;
    }
    const statePropertyName = label.substring(0, label.indexOf('.'));
    const stateCurrentValue = this[statePropertyName];

    // create new stateValue
    let dotPartArray = label.split('.');
    dotPartArray.shift();
    let updateSchemaDefined = { [dotPartArray.join('.')]: { $set: value } };
    const stateNewValue = update(stateCurrentValue, Dot.object(updateSchemaDefined));

    this[statePropertyName] = stateNewValue;
  }

  id = Math.floor(Math.random() * 1000000) + '' + Math.floor(Math.random() * 1000000);

  ready = false;

  makeReady = ( ) => {
    this.ready = true;
    GlobalEvent.emit("STORE_READY_" + this.id);
  }
  onReady = (callback = undefined) => new Promise((resolve, reject) => {
    const run = () => {
      typeof callback === 'function' && callback();
      resolve();
    }
    if (this.ready) run();
    else GlobalEvent.on("STORE_READY_" + this.id, run);
  });
}