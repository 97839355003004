const VOUCHER_TYPE = {
  K100: {
    type: 'K100',
    des: '100,000 VND'
  },
  K50: {
    type: 'K50',
    des: '50,000 VND'
  },
  P3: {
    type: 'P3',
    des: 'Buffet 3 người tặng 1'
  },
}

const STATUS = {
  USED: {
    type: 'USED',
    des: 'Đã sử dụng',
  },
  NOT_USED: {
    type: 'NOT_USED',
    des: 'Chưa sử dụng',
  },
}

class VoucherHelper {

  type = VOUCHER_TYPE;

  getDes = (type) => {
    if (!type) return '';
    return !!VOUCHER_TYPE[type] ? VOUCHER_TYPE[type].des : '';
  }

  getStatus = (status) => {
    if (!status) return '';
    return !!STATUS[status] ? STATUS[status].des : '';
  }

  getValue = (type, oneBuffetMoney) => {
    if (type === VOUCHER_TYPE.K50.type) return 50000;
    if (type === VOUCHER_TYPE.K100.type) return 100000;
    if (type === VOUCHER_TYPE.P3.type) return oneBuffetMoney;
  }
}

export default new VoucherHelper();