import React from "react";
import ReactDOM from "react-dom";

import { HashRouter, BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
// import "./assets/sass/light-bootstrap-dashboard-react.scss?v=1.3.0";
import "assets/sass/light-bootstrap-dashboard-pro-react.scss?v=1.2.0";
import "./assets/css/demo.css";
import "./assets/css/pe-icon-7-stroke.css";
import "./assets/sass/custom.scss";
import "./assets/sass/invoice.scss";

import AdminLayout from "layouts/Admin.jsx";
import store from './store';
import { Provider } from 'mobx-react';

import SMoneyLookUp from 'views/SMoney/SMoneyLookUp';
import ImportSMoney from 'views/SMoney/ImportSMoney';
import Image from 'views/Image';

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Switch>
        {/* <Route path="/admin" render={props => <AdminLayout {...props} />} />
        <Redirect from="/" to="/admin/tra-cuu" /> */}
        <Route path="/image" component={Image} />
        <Route path="/import-excel" component={ImportSMoney} />
        <Route path="/" component={SMoneyLookUp} />
      </Switch>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);
