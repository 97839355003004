import React, { Component } from 'react';
import qs from 'qs';
import MoneyImage from 'components/MoneyImage/MoneyImage';

const getParamFromUrlQuery = () => {
  let obj = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  if (obj.name) {
    // let index = window.location.search.indexOf('name='+obj.name);
    // let str = window.location.search.slice(index, window.location.search.length);
    let regexp = /&name=(.*)&font=/;
    const match = window.location.search.match(regexp);
    if (!!match && !!match[1]) {
      obj.name = match[1];
    }
  }
  return obj;
}

export default class Image extends Component {
  render() {
    console.log(getParamFromUrlQuery());
    const { seri, code, displayValue, currency } = getParamFromUrlQuery()
    return (
      <MoneyImage
        seri={seri}
        code={code}
        displayValue={displayValue}
        currency={currency}
      />
    )
  }
}