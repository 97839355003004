import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

const GOOGLE_API_KEY = "AIzaSyBkTHYX9Mh_AGgb88JMWwNjWknHs3IjCII";

const MyMapComponent = withScriptjs(withGoogleMap((props) => {
  // console.log('MyMapComponent', props);
  return (
    <GoogleMap
      {...props}
      defaultZoom={props.defaultZoom || 15}
      defaultCenter={props.defaultCenter}
      center={props.center}
      ref={props.innerRef}
    >
      {/* <Marker position={{ lat: -34.397, lng: 150.644 }} /> */}
      {props.renderMarker()}
    </GoogleMap>
  );
}))


export default class Maps extends Component {

  static propTypes = {
    isMarkerShown: PropTypes.bool,
    googleMapURL: PropTypes.string,
    loadingElement: PropTypes.node,
    containerElement: PropTypes.node,
    mapElement: PropTypes.node,
    listLatLngAdress: PropTypes.array,
    center: PropTypes.object,
  }

  static defaultProps = {
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`,
    center: { lat: 21.0313509, lng: 105.7809917 },
    defaultCenter: { lat: 21.0313509, lng: 105.7809917 },
  }

  componentDidMount() {
    // console.log('Maps props', this.props);
  }

  handleClick = (val) => {
    // if (!val.url) return;
    // window.open(val.url);
    !!this.props.onClickItem && this.props.onClickItem(val);
  }

  render() {
    return (
      <MyMapComponent
        {...this.props}
        renderMarker={() => {
          const { listLatLngAdress, isMarkerShown } = this.props;
          if (listLatLngAdress == undefined || listLatLngAdress.length === 0) return null;
          if (!isMarkerShown) return null;
          return (
            <Fragment>
              {listLatLngAdress.map((val, index) => val.lat == undefined ? null : (
                <MarkerWithLabel key={"marker"+index}
                  position={{ lat: val.lat, lng: val.lng }}
                  labelAnchor={new google.maps.Point(75, 90)}
                  labelStyle={{ fontSize: 10, padding: 10, color: 'white' }}
                  onClick={() => this.handleClick(val)}
                >
                  <div className="cursor" style={{ display: 'block', borderRadius: 10, backgroundColor: '#EB4234', width: 150, padding: 10, textAlign: 'center'}}>{val.name}</div>
                </MarkerWithLabel>
              ))}
            </Fragment>
          )
        }}
      />
    );
  }

}