import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Card extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable frames = [];

  getCards = async ({ code, used, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
    };
    if (code) {
      defaultQuery['filter[code][$regex]'] = code;
    }
    if (used === 'true') {
      defaultQuery['filter[used]'] = 'true';
    } else if (used === 'false') {
      defaultQuery['filter[used][$ne]'] = 'true';
    }
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/card', defaultQuery);
    const res = await Request.post(url, {});
    return res.data;
  }

  createCard = async ({ url }) => {
    const res = await Request.post('/frame/add', { url, public: false });
    return res.data;
  }

  removeCard = async (id) => {
    const res = await Request.post('/frame/delete', { id });
    return res.data;
  }
}

export default new Card();
