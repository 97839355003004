import Dashboard from "views/Dashboard.jsx";

import UserProfile from "views/User/UserProfile";
import UserMienBac from "views/User/UserMienBac";
import UserMienNam from "views/User/UserMienNam";
import UserCreate from "views/User/UserCreate";
import UserRegionHOC from "views/User/UserRegionHOC";

import TableList from "views/TableList.jsx";
import Typography from "views/Typography.jsx";
import Icons from "views/Icons.jsx";
import Maps from "views/Maps.jsx";
import Notifications from "views/Notifications.jsx";

import RestaurantList from "views/Restaurant/RestaurantList";
import RestaurantCreate from "views/Restaurant/RestaurantCreate";
import RestaurantEdit from "views/Restaurant/RestaurantEdit";

import BillCreate from "views/Bill/BillCreate"
import BillDetail from "views/Bill/BillDetail"
import BillRestaurent from "views/Bill/BillRestaurent"

import Frame from "views/Frame"
import System from "views/System"

import VoucherRegionHOC from "views/Voucher/VoucherRegionHOC";
import BillRegionHOC from "views/Bill/BillRegionHOC";
import LuckyDrawRegionHOC from "views/LuckyDraw/LuckyDrawRegionHOC";

import ClipMau from "views/Dancing/ClipMau";
import UserPlay from "views/Dancing/UserPlay";
import ListPin from "views/Dancing/ListPin";
import AddVideo from "views/Dancing/AddVideo";

import TraCuu from "views/TraCuu";

import WebThumbnail from "views/WebThumbnail/WebThumbnailList";
import WebThumbnailCreate from "views/WebThumbnail/WebThumbnailCreate";
import WebThumbnailDetail from "views/WebThumbnail/WebThumbnailDetail";
import ListTheCao from "views/TheCao/ListTheCao";
import ImportTheCao from "views/TheCao/ImportTheCao";
import ImportCGV from "views/CGVCode/ImportCGV";
import ListCGV from "views/CGVCode/ListCGV";

import SMoneyListing from "views/SMoney/SMoneyListing";
import SMoneyCreate from "views/SMoney/SMoneyCreate";
import SMoneyDetail from "views/SMoney/SMoneyDetail";
import SMoneyLookUp from "views/SMoney/SMoneyLookUp";
import SMoneyImport from "views/SMoney/SMoneyImport";

import OrderDetail from "views/OrderDetail";

const dashboardRoutes = [
  {
    path: "/tra-cuu",
    name: "Tra cứu ngày tháng",
    icon: "pe-7s-search",
    component: SMoneyLookUp,
    layout: "/admin",
    showInRestaurant: false,
  },
  // {
  //   path: "/dashboard",
  //   name: "Đăng nhập",
  //   nameWhenLoggedIn: "Đơn hàng",
  //   icon: "pe-7s-graph",
  //   component: Dashboard,
  //   layout: "/admin",
  //   showInRestaurant: false,
  // },
  // {
  //   path: "/smoneys",
  //   name: "Tiền",
  //   icon: "pe-7s-wallet",
  //   layout: "/admin",
  //   component: SMoneyListing,
  // },
  {
    path: "/create-smoney",
    layout: "/admin",
    name: "Thêm mới Tiền",
    mini: "TK",
    component: SMoneyCreate,
    hideInSidebar: true,
  },
  {
    path: "/smoney-detail/:id",
    layout: "/admin",
    name: "Xem chi tiết Tiền",
    mini: "TK",
    component: SMoneyDetail,
    hideInSidebar: true,
  },
  {
    path: "/order-detail/:id",
    layout: "/admin",
    name: "Xem chi tiết Đơn hàng",
    mini: "TK",
    component: OrderDetail,
    hideInSidebar: true,
  },
  {
    path: "/import-smoney",
    layout: "/admin",
    name: "Nhập liệu từ excel",
    mini: "TK",
    component: SMoneyImport,
    hideInSidebar: true,
  },
  // {
  //   path: "/restaurant/edit/:id",
  //   name: "Chỉnh sửa nhà hàng",
  //   icon: "pe-7s-map-2",
  //   component: RestaurantEdit,
  //   layout: "/admin",
  //   hideInSidebar: true,
  // },
  // {
  //   path: "/restaurant/create",
  //   name: "Tạo nhà hàng",
  //   icon: "pe-7s-map-2",
  //   component: RestaurantCreate,
  //   layout: "/admin",
  //   hideInSidebar: true,
  // },
  // {
  //   path: "/restaurant",
  //   name: "Nhà hàng",
  //   icon: "pe-7s-map-2",
  //   component: RestaurantList,
  //   layout: "/admin"
  // },
  // {
  //   path: "/users",
  //   name: "Người chơi",
  //   icon: "pe-7s-user",
  //   state: "openUsers",
  //   collapse: true,
  //   views: [
  //     {
  //       path: "/users-mien-bac",
  //       layout: "/admin",
  //       name: "Miền Bắc",
  //       mini: "MB",
  //       component: UserRegionHOC('Bắc'),
  //     },
  //     {
  //       path: "/users-mien-nam",
  //       layout: "/admin",
  //       name: "Miền Nam",
  //       mini: "MN",
  //       component: UserRegionHOC('Nam'),
  //     },
  //     {
  //       path: "/create-user",
  //       layout: "/admin",
  //       name: "Tạo tài khoản",
  //       mini: "TK",
  //       component: UserCreate,
  //       hideInSidebar: true,
  //     },
  //     {
  //       path: "/user-profile/:id",
  //       layout: "/admin",
  //       name: "Chi tiết tài khoản",
  //       mini: "TK",
  //       component: UserProfile,
  //       hideInSidebar: true,
  //     },
  //   ]
  // },
  // {
  //   path: "/voucher",
  //   name: "Voucher",
  //   icon: "pe-7s-wallet",
  //   layout: "/admin",
  //   collapse: true,
  //   views: [
  //     {
  //       path: "/vouchers-mien-bac",
  //       layout: "/admin",
  //       name: "Miền Bắc",
  //       mini: "MB",
  //       component: VoucherRegionHOC('Bắc'),
  //     },
  //     {
  //       path: "/vouchers-mien-nam",
  //       layout: "/admin",
  //       name: "Miền Nam",
  //       mini: "MN",
  //       component: VoucherRegionHOC('Nam'),
  //     },
  //   ]
  // },
  // {
  //   path: "/luckydraw",
  //   name: "Lucky Draw",
  //   icon: "pe-7s-ticket",
  //   layout: "/admin",
  //   collapse: true,
  //   views: [
  //     {
  //       path: "/luckydraw-mien-bac",
  //       layout: "/admin",
  //       name: "Miền Bắc",
  //       mini: "MB",
  //       component: LuckyDrawRegionHOC('Bắc'),
  //     },
  //     {
  //       path: "/luckydraw-mien-nam",
  //       layout: "/admin",
  //       name: "Miền Nam",
  //       mini: "MN",
  //       component: LuckyDrawRegionHOC('Nam'),
  //     },
  //   ]
  // },
  // {
  //   path: "/bill/my-restaurent",
  //   name: "Danh sách hóa đơn",
  //   icon: "pe-7s-note2",
  //   component: BillRestaurent,
  //   layout: "/admin",
  //   showInRestaurant: true,
  //   showInAdmin: false,
  // },
  // {
  //   path: "/bill/create",
  //   name: "Tạo hóa đơn",
  //   icon: "pe-7s-note2",
  //   component: BillCreate,
  //   layout: "/admin",
  //   showInRestaurant: true,
  //   showInAdmin: false,
  // },
  // {
  //   path: "/bill/detail/:id",
  //   name: "Chi tiết hóa đơn",
  //   icon: "pe-7s-note2",
  //   component: BillDetail,
  //   layout: "/admin",
  //   showInRestaurant: false,
  //   showInAdmin: false,
  // },
  // {
  //   path: "/bill",
  //   name: "Hóa đơn",
  //   icon: "pe-7s-note2",
  //   layout: "/admin",
  //   collapse: true,
  //   views: [
  //     {
  //       path: "/bills-mien-bac",
  //       layout: "/admin",
  //       name: "Miền Bắc",
  //       mini: "MB",
  //       component: BillRegionHOC('Bắc'),
  //     },
  //     {
  //       path: "/bills-mien-nam",
  //       layout: "/admin",
  //       name: "Miền Nam",
  //       mini: "MN",
  //       component: BillRegionHOC('Nam'),
  //     },
  //   ]
  // },
  // {
  //   path: "/card/import",
  //   name: "Nhập thẻ cào",
  //   icon: "pe-7s-note2",
  //   component: ImportTheCao,
  //   layout: "/admin",
  //   showInAdmin: false,
  // },
  // {
  //   path: "/card",
  //   name: "Mã thẻ cào",
  //   icon: "pe-7s-note2",
  //   layout: "/admin",
  //   component: ListTheCao,
  // },
  // {
  //   path: "/cgv-code/import",
  //   name: "Nhập thẻ cào",
  //   icon: "pe-7s-note2",
  //   component: ImportCGV,
  //   layout: "/admin",
  //   showInAdmin: false,
  // },
  // {
  //   path: "/cgv-code",
  //   name: "Mã CGV",
  //   icon: "pe-7s-note2",
  //   layout: "/admin",
  //   component: ListCGV,
  // },
  // {
  //   path: "/frame",
  //   name: "Frame Avatar",
  //   icon: "pe-7s-id",
  //   component: Frame,
  //   layout: "/admin",
  // },
  // {
  //   path: "/dancing",
  //   name: "Dancing",
  //   icon: "pe-7s-video",
  //   layout: "/admin",
  //   collapse: true,
  //   views: [
  //     {
  //       path: "/dancing-clip-mau",
  //       layout: "/admin",
  //       name: "Clip Mẫu",
  //       mini: "CM",
  //       component: ClipMau,
  //     },
  //     {
  //       path: "/dancing-nguoi-choi",
  //       layout: "/admin",
  //       name: "Người Chơi",
  //       mini: "NC",
  //       component: UserPlay,
  //     },
  //     {
  //       path: "/dancing-list-pin",
  //       layout: "/admin",
  //       name: "List Pin",
  //       mini: "LP",
  //       component: ListPin,
  //     },
  //     {
  //       path: "/dancing-add-clip",
  //       layout: "/admin",
  //       name: "Thêm video",
  //       mini: "TV",
  //       component: AddVideo,
  //     },
  //   ],
  // },
  // {
  //   path: "/search",
  //   name: "Tra Cứu",
  //   icon: "pe-7s-search",
  //   component: TraCuu,
  //   layout: "/admin",
  // },
  // {
  //   path: "/thumbnail/detail/:id",
  //   name: "Web Thumbnail Detail",
  //   icon: "pe-7s-graph2",
  //   component: WebThumbnailDetail,
  //   layout: "/admin",
  //   hideInSidebar: true,
  // },
  // {
  //   path: "/thumbnail/create",
  //   name: "Tạo Web Thumbnail",
  //   icon: "pe-7s-graph2",
  //   component: WebThumbnailCreate,
  //   layout: "/admin",
  //   hideInSidebar: true,
  // },
  // {
  //   path: "/thumbnail",
  //   name: "Web Thumbnail",
  //   icon: "pe-7s-graph2",
  //   component: WebThumbnail,
  //   layout: "/admin",
  // },
  // {
  //   path: "/system",
  //   name: "Hệ Thống",
  //   icon: "pe-7s-settings",
  //   component: System,
  //   layout: "/admin",
  // },
  // {
  //   path: "/typography",
  //   name: "Typography",
  //   icon: "pe-7s-news-paper",
  //   component: Typography,
  //   layout: "/admin"
  // },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "pe-7s-science",
  //   component: Icons,
  //   layout: "/admin"
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "pe-7s-map-marker",
  //   component: Maps,
  //   layout: "/admin"
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   icon: "pe-7s-bell",
  //   component: Notifications,
  //   layout: "/admin"
  // }
];

export default dashboardRoutes;
