import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Table
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Container from "components/Misc/Container";
import CompState from "components/Misc/CompStateData";
import { Link } from 'react-router-dom';
import avatar from "assets/img/user.png";
import cover from "assets/img/user_cover.jpg";
import Store from "../../store";
import { thArray, tdArray } from "variables/Variables.jsx";
import { VoucherHelper, TimeHelper, VarHelper } from "../../helpers";

class UserProfile extends Component {

  state = {
    userNotFound: false,
    userInfo: {
      luckyCode: [],
      vouchers: [],
      name: '',
      registerRegion: '',
      phone: '',
      email: '',
      video: '',
      videoData: {
        facebook: {
          link: '',
          like: '',
          share: '',
        },
        youtube: {
          link: '',
          view: '',
          like: '',
        },
        tiktok: {
          link: '',
          like: '',
          comment: '',
        },
      }
    },
    bills: [],
    cgvs: [],
  }

  dot = CompState.dot(this);

  onReady = async () => {
    const id = this.props.match.params.id;
    if (!id) return this.setState({ userNotFound: true })
    const res = await Store.User.getUserInfo({ id });
    if (res.success) {
      this.setState({
        userInfo: {
          ...this.state.userInfo,
          ...res.data,
          videoData: {
            ...this.state.userInfo.videoData,
            ...res.data.videoData
          }
        }
      })
      this.getBillUser();
      this.getCGV();
    } else {
      window.notify.error(res.message);
      if (res.message === "NOT_EXISTED") this.setState({ userNotFound: true })
    }
  }

  getCGV = async () => {
    const id = this.props.match.params.id;
    const res = await Store.Voucher.getUserCGV(id);
    if (res.success) {
      this.setState({ cgvs: res.data });
    }
  }

  getBillUser = async () => {
    const id = this.props.match.params.id;
    const res = await Store.Bill.getBillOneUser({ userId: id });
    if (res.success) {
      const findCgv = res.data.find(val => !!val.cgvCode);
      if (!!findCgv) {
        const r = await Store.CGVCode.getCGVById(findCgv.cgvCode);
        if (r.success) {
          findCgv.cgvData = r.data;
        }
      }
      this.setState({ bills: res.data })
    }
  }

  giveVoucher = async () => {
    const { userInfo } = this.state;
    if (!userInfo._id) return window.notify.error('Có lỗi xảy ra');
    const res = await Store.Voucher.giveVoucherTest({ user_id: userInfo._id });
    if (res.success) {
      window.location.reload();
    }
  }

  updateProfile = async (e) => {
    e.preventDefault();
    const { userInfo } = this.state;
    const res = await Store.User.updateUser(userInfo._id, {
      email: userInfo.email,
      phone: userInfo.phone,
      name: userInfo.name,
      video: userInfo.video,
      videoData: userInfo.videoData,
    });
    if (res.success) {
      window.notify.success("Cập nhật thành công");
    }
  }

  renderVoucher = () => {
    const { userInfo } = this.state;
    const titles = ["Voucher", "Giá trị", "Trạng thái", "Ngày hiệu lực"];
    const rows = userInfo.vouchers.map(val => {
      return [
        val._id,
        val.code,
        VoucherHelper.getDes(val.type),
        VoucherHelper.getStatus(val.status),
        TimeHelper.changeFormat(undefined, val.startDate),
      ];
    });
    return (
      <Row>
        <Col md={12}>
          <Card
            title="Danh sách Voucher"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover>
                <thead>
                  <tr>
                    {titles.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((val, i) => {
                          if (i === 0) return null;
                          return <td key={i}>{val}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    )
  }

  renderLuckyDraw = () => {
    const { userInfo } = this.state;
    const titles = ["Mã bốc thăm Lucky Draw", "Ngày cấp"];
    const rows = userInfo.luckyCode.map(val => {
      return [
        val._id,
        val.code,
        TimeHelper.changeFormat(undefined, val.createdAt),
      ];
    });
    return (
      <Row>
        <Col md={12}>
          <Card
            title="Danh sách Lucky Draw"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover>
                <thead>
                  <tr>
                    {titles.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((val, i) => {
                          if (i === 0) return null;
                          return <td key={i}>{val}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    )
  }

  renderBills = () => {
    const { bills } = this.state;
    const titles = ["Tông tiền", "Nhà hàng", "Số Check", "Lucky Draw", "Số lượng Combo", "CGV", "Ngày cập nhật"];
    const rows = bills.map(val => {
      return [
        val._id,
        VarHelper.numberWithCommas(val.afterVoucherMoneyVAT) + ' VND',
        val.restaurantAddress,
        val.soCheck,
        !!val.luckyCode ? <p style={{ fontSize: 12 }}>{val.luckyCode.map((val, i) => <Fragment key={"lucky-"+i}><span>{val.code}</span><br /></Fragment>)}</p> : '',
        val.numberOfCombo,
        !!val.cgvCode && !!val.cgvData ? <p style={{ fontSize: 12 }}>Seri: {val.cgvData.code}<br/>Pin: {val.cgvData.pin}</p> : '',
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ];
    });
    return (
      <Row>
        <Col md={12}>
          <Card
            title="Danh sách Hóa đơn"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover>
                <thead>
                  <tr>
                    {titles.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((val, index) => {
                          if (index === 0) return null;
                          return (
                            <td key={index}>
                              <Link to={"/admin/bill/detail/" + prop[0]} style={{ color: 'black' }}>
                                {val}
                              </Link>
                            </td>
                          )
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    )
  }

  renderCGVs = () => {
    const { cgvs } = this.state;
    const titles = ["Mã Seri", "Mã Pin", "Ngày cập nhật"];
    const rows = cgvs.map(val => {
      return [
        val._id,
        val.code,
        val.pin,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ];
    });
    return (
      <Row>
        <Col md={12}>
          <Card
            title="Danh sách CGV"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover>
                <thead>
                  <tr>
                    {titles.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((val, index) => {
                          if (index === 0) return null;
                          return (
                            <td key={index}>
                              {val}
                            </td>
                          )
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    )
  }

  render() {
    const { userNotFound } = this.state;
    if (userNotFound) return <div className="content">Không tìm thấy người chơi phù hợp</div>;
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={8}>
                <Card
                  title="Thông tin người chơi"
                  category="Giả lập tạm thời phần tặng mã voucher. Phần này lúc chạy thực tế sẽ không có"
                  content={
                    <form onSubmit={this.updateProfile}>
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Tên",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.name,
                            onChange: e => this.dot.setValue('userInfo.name', e.target.value),
                          },
                          {
                            label: "Khu Vực",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.registerRegion,
                            readOnly: true,
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Số điện thoại",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.phone,
                            onChange: e => this.dot.setValue('userInfo.phone', e.target.value),
                          },
                          {
                            label: "Địa chỉ Email",
                            type: "email",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.email,
                            onChange: e => this.dot.setValue('userInfo.email', e.target.value),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "UTM",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.utm,
                            readOnly: true,
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Video dự thi",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.video,
                            onChange: e => this.dot.setValue('userInfo.video', e.target.value),
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-6", "col-md-2", "col-md-2", "col-md-2"]}
                        properties={[
                          {
                            label: "User video link facebook",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.facebook.link,
                            onChange: e => this.dot.setValue('userInfo.videoData.facebook.link', e.target.value),
                          },
                          {
                            label: "Like",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.facebook.like,
                            onChange: e => this.dot.setValue('userInfo.videoData.facebook.like', e.target.value),
                          },
                          {
                            label: "Share",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.facebook.share,
                            onChange: e => this.dot.setValue('userInfo.videoData.facebook.share', e.target.value),
                          },
                          {
                            label: "Open",
                            customRender: () => (
                              <div>
                                <Button style={{ width: '100%' }} bsStyle="warning" fill onClick={() => this.openLink(this.state.userInfo.videoData.facebook.link)}>Go</Button>
                              </div>
                            )
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-6", "col-md-2", "col-md-2", "col-md-2"]}
                        properties={[
                          {
                            label: "User video link youtube",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.youtube.link,
                            onChange: e => this.dot.setValue('userInfo.videoData.youtube.link', e.target.value),
                          },
                          {
                            label: "View",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.youtube.view,
                            onChange: e => this.dot.setValue('userInfo.videoData.youtube.view', e.target.value),
                          },
                          {
                            label: "Like",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.youtube.like,
                            onChange: e => this.dot.setValue('userInfo.videoData.youtube.like', e.target.value),
                          },
                          {
                            label: "Open",
                            customRender: () => (
                              <div>
                                <Button style={{ width: '100%' }} bsStyle="warning" fill onClick={() => this.openLink(this.state.userInfo.videoData.youtube.link)}>Go</Button>
                              </div>
                            )
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-6", "col-md-2", "col-md-2", "col-md-2"]}
                        properties={[
                          {
                            label: "User video link Tiktok",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.tiktok.link,
                            onChange: e => this.dot.setValue('userInfo.videoData.tiktok.link', e.target.value),
                          },
                          {
                            label: "Like",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.tiktok.like,
                            onChange: e => this.dot.setValue('userInfo.videoData.tiktok.like', e.target.value),
                          },
                          {
                            label: "Comment",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.videoData.tiktok.comment,
                            onChange: e => this.dot.setValue('userInfo.videoData.tiktok.comment', e.target.value),
                          },
                          {
                            label: "Open",
                            customRender: () => (
                              <div>
                                <Button style={{ width: '100%' }} bsStyle="warning" fill onClick={() => this.openLink(this.state.userInfo.videoData.tiktok.link)}>Go</Button>
                              </div>
                            )
                          },
                        ]}
                      />

                      {!!this.state.userInfo.vote && this.state.userInfo.vote.hasVote && (
                        <p>Người chơi này đã tham gia Vote vào lúc {TimeHelper.changeFormat(undefined, this.state.userInfo.vote.data.timestamp)}</p>
                      )}
                      {!!this.state.userInfo.cgv && this.state.userInfo.cgv !== 'NEED_VERIFY' && (
                        <p>Người chơi này đã được tặng mã CGV. {!!this.state.userInfo.cgvBill && <Link to={"/admin/bill/detail/" + this.state.userInfo.cgvBill}><strong>Tra hóa đơn</strong></Link>}</p>
                      )}

                      <Button bsStyle="danger" pullRight fill type="submit">
                        Update Profile
                      </Button>
                      {/* <Button bsStyle="danger" pullRight fill onClick={this.giveVoucher}>
                        Tặng Voucher Test
                      </Button> */}
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
              <Col md={4}>
                <UserCard
                  bgImage={cover}
                  avatar={this.state.userInfo.avatar || avatar}
                  name={this.state.userInfo.name}
                  userName={this.state.userInfo.phone}

                />

                {!!this.state.userInfo.video &&
                  <video style={{ width: '100%', height: 'auto' }} controls>
                    <source src={this.state.userInfo.video} type="video/mp4" />
                  </video>}
              </Col>
            </Row>
            {this.renderVoucher()}
            {this.renderLuckyDraw()}
            {this.renderBills()}
            {this.renderCGVs()}
          </Grid>
        </div>
      </Container>
    );
  }

  openLink = (url) => {
    window.open(url, '_blank');
  }
}

export default UserProfile;
