import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Table,
  Button as Btn
} from "react-bootstrap";
import Container from "components/Misc/Container"
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Invoice from "components/Invoice/Invoice";
import Select from "react-select";

import Store from "../../store";
import { VoucherHelper, VarHelper, TimeHelper } from "../../helpers";

class BillDetail extends Component {

  state = {
    numberOfVoucher: 0,
    vouchers: [],
    numberOfCombo: 0,
    phoneInput: '',
    numberOfGuess: 0,
    name: '',
    phone: '',
    money: '',
    moneyVAT: '',
    luckydraw: [],
    userInfo: {
      name: '',
      phone: '',
    },
    buffet: null,
    didCreateBill: false,
    notFound: false,
    billCode: '',
    restaurantAddress: '',
    soCheck: '',
  }

  onReady = async () => {
    const _id = this.props.match.params.id;
    if (!_id) return this.setState({ notFound: true });
    const res =  await Store.Bill.getBillDetail({ _id });
    if (res.success) {
      console.log(res.data);
      const { billMoney, userName, phone, numberOfCombo, numberOfGuess, vouchers, buffetValue, luckyCode, billCode, restaurantAddress, soCheck } = res.data;
      this.setState({
        money: billMoney,
        userInfo: {
          name: userName,
          phone: phone,
        },
        numberOfCombo,
        numberOfGuess,
        vouchers,
        buffet: !buffetValue ? undefined : { value: buffetValue, label: String(buffetValue).slice(0,3) + 'k' },
        luckydraw: luckyCode,
        billCode,
        restaurantAddress,
        soCheck
      })
    } else if (res.message === "NOT_EXISTED") {
      this.setState({ notFound: true });
    } else {
      window.notify.error(res.message);
    }
  }

  useVoucher = (item) => {
    let { vouchers, buffet } = this.state;
    if (item.type === VoucherHelper.type.P3.type && !buffet) {
      return window.notify.error("Cần chọn suất Buffet của khách trước");
    }
    const checkExisted = vouchers.find(val => val.voucher === item.code);
    if (!!checkExisted) return window.notify.error("Voucher này đã có trong danh sách");
    vouchers.push({ _id: item._id, voucher: item.code, description: "Voucher "+ VoucherHelper.getDes(item.type), value: VoucherHelper.getValue(item.type, !!buffet ? buffet.value : 0) })
    this.setState({ vouchers });
  }

  renderLuckyCard = (val, i, type) => {
    const removeLuckyCard = () => {
      if (type === 'combo') {
        this.setState({ numberOfCombo: Math.max(0, this.state.numberOfCombo - 1) })
      }
      if (type === 'voucher') {
        let { vouchers } = this.state;
        let findIndex = vouchers.findIndex(val => val.voucher === val.voucher);
        this.setState({
          vouchers: [...vouchers.slice(0, findIndex), ...vouchers.slice(findIndex + 1, vouchers.length)]
        })
      }
    }
    return (
      <div key={`item-${i}-${type}`} style={{ display: 'inline-block', padding: 15, backgroundColor: 'rgba(0,0,0,0.1)', position: 'relative', marginLeft: 15, marginBottom: 15 }}>
        {val.description}
      </div>
    )
  }

  afterVoucherMoney = () => {
    const { money, vouchers } = this.state;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return value;
  }

  VATMoney = () => {
    const { money, vouchers } = this.state;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return Math.floor(value * 0.1);
  }

  afterVoucherMoneyVAT = () => {
    const { money, vouchers } = this.state;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return Math.floor(value * 1.1);
  }

  getLuckyDraw = async () => {
    let i = 0;
    const { numberOfCombo, vouchers, userInfo, buffet, numberOfGuess, } = this.state;
    let comboItems = new Array(+numberOfCombo).fill({
      description: 'Mua combo'
    })
    let voucherItems = vouchers.map(val => ({
      voucher: val,
      description: val.description
    }))
    // this.setState({ luckydraw: [...comboItems, ...voucherItems] })
    const res = await Store.Bill.createBill({
      userId: userInfo._id,
      userName: userInfo.name,
      phone: userInfo.phone,
      registerRegion: Store.User.userData.registerRegion,
      billMoney: this.state.money,
      afterVoucherMoney: this.afterVoucherMoney(),
      afterVoucherMoneyVAT: this.afterVoucherMoneyVAT(),
      buffetValue: !!buffet ? buffet.value : undefined,
      numberOfGuess,
      numberOfCombo,
      vouchers,
      items: [...comboItems, ...voucherItems],
    });
    if (res.success) {
      this.setState({
        luckydraw: res.data.luckyCode.map(val => ({ code: val.code, description: val.description })),
        didCreateBill: true,
      })
    }
  }

  renderVoucher = () => {
    const { userInfo } = this.state;
    if (!userInfo.vouchers) return null;
    const titles = ["Voucher", "Trạng thái, hiệu lực", ""];
    const rows = userInfo.vouchers.map(val => {
      return [
        val._id,
        <p>{val.code}<br /><strong className="text-danger">{VoucherHelper.getDes(val.type)}</strong></p>,
        <p>{VoucherHelper.getStatus(val.status)}<br />{TimeHelper.changeFormat(undefined, val.startDate)}</p>,
        val.status === "USED" ? null :
        <Button bsStyle="success" fill disabled={!TimeHelper.isAfter(val.startDate)} onClick={() => this.useVoucher(val)}>
          Sử dụng
        </Button>
      ];
    }) ;
    return (
      <Row>
        <Col md={12}>
          <Card
            title="Danh sách Voucher"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover>
                <thead>
                  <tr>
                    {titles.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((val, i) => {
                          if (i === 0) return null;
                          return <td key={i}>{val}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    )
  }

  render() {
    const combos = new Array(+this.state.numberOfCombo).fill({ description: 'Combo' });
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={6} id="voucher-check">
                <Card
                  title="Thông tin xuất hóa đơn"
                  category="Kiểm tra voucher, nhập thông tin thanh toán, in hóa đơn"
                  content={
                    <div>
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Tên khách",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.name,
                            readOnly: true,
                          },
                          {
                            label: "SDT",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.phone,
                            readOnly: true,
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Số lượng khách",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.numberOfGuess,
                            onChange: (e) => this.setState({ numberOfGuess: e.target.value }),
                            readOnly: true,
                          },
                          {
                            label: "Số COMBO sử dụng",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.numberOfCombo,
                            onChange: (e) => this.setState({ numberOfCombo: e.target.value }),
                            readOnly: true,
                          },
                        ]}
                      />
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <ControlLabel>Suất Buffet</ControlLabel>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={this.state.buffet}
                              onChange={value =>
                                this.setState({ buffet: value })
                              }
                              options={[
                                { value: 189000, label: "189k" },
                                { value: 199000, label: "199k" },
                                { value: 229000, label: "229k" },
                                { value: 269000, label: "269k" },
                              ]}
                              placeholder="Khách sử dụng suất Buffet nào?"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Tiền hóa đơn (Chưa VAT)",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.money,
                            onChange: e => this.setState({ money: e.target.value }),
                            readOnly: true,
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Số check",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.soCheck,
                            onChange: e => this.setState({ soCheck: e.target.value }),
                            readOnly: true,
                          },
                        ]}
                      />

                      {this.renderVoucher()}

                      <ControlLabel>Số lượng mã bốc thăm khách hàng được nhận: <strong style={{ fontSize: 20 }}>{+this.state.vouchers.length + +this.state.numberOfCombo}</strong></ControlLabel>
                      <Row style={{ marginBottom: 25 }}>
                        {combos.map((val, i) => this.renderLuckyCard(val, i, 'combo'))}
                        {this.state.vouchers.map((val, i) => this.renderLuckyCard(val, i, 'voucher'))}
                      </Row>
                      <div className="clearfix" />
                    </div>
                  }
                />
              </Col>
              <Col md={6} id="preview-invoice">
                <Card
                  title="Xem trước hóa đơn"
                  content={
                    <div className="invoice-box-container">
                      <Invoice
                        name={this.state.userInfo.name}
                        phone={this.state.userInfo.phone}
                        money={this.state.money}
                        vouchers={this.state.vouchers}
                        items={this.state.luckydraw}
                        // billCode={this.state.billCode}
                        numberOfGuess={this.state.numberOfGuess}
                        restaurantAddress={this.state.restaurantAddress}
                        soCheck={this.state.soCheck}
                      />

                      <Button bsStyle="danger" fill style={{ marginTop: 25 }} onClick={() => window.print()}>
                        In hóa đơn
                      </Button>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default BillDetail;
