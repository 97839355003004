import React from 'react';
import classnames from 'classnames';
import './ImageRatio.scss';

const ContainerRatio = (props) => {
  return (
    <div
      className={classnames("blf-container-ratio", props.className)}
      style={{
        paddingTop: `${100 / props.ratio}%`,
      }}>
        <div className="blf-container-ratio__inner">
          {props.children}
        </div>
    </div>
  );
};

export default ContainerRatio;