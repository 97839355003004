import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Video extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable videos = [];

  getVideos = async () => {
    const res = await Request.get('/video', {});
    if (res.data.success) {
      this.videos = res.data.data;
    }
    return res.data;
  }

  createVideo = async ({ url, thumb, name }) => {
    const res = await Request.post('/video/add', { url, thumb, name });
    return res.data;
  }

  removeVideo = async (id) => {
    const res = await Request.post('/video/delete', { id });
    return res.data;
  }

  getPinVideo = async () => {
    const res = await Request.get('/video_public', { });
    return res.data;
  }

  pinVideo = async ({ url, thumb, name, userId }) => {
    const res = await Request.post('/video_public/add', { url, thumb, name, userId });
    return res.data;
  }

  removePinVideo = async ({ id }) => {
    const res = await Request.post('/video_public/delete', { id });
    return res.data;
  }

}

export default new Video();
