import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class CGVCode extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable frames = [];

  getCGVCodes = async ({ code, pin, used, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
    };
    if (code) {
      defaultQuery['filter[code][$regex]'] = code;
    }
    if (pin) {
      defaultQuery['filter[pin]'] = pin;
    }
    if (used === 'true') {
      defaultQuery['filter[used]'] = 'true';
    } else if (used === 'false') {
      defaultQuery['filter[used][$ne]'] = 'true';
    }
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/cgv_code', defaultQuery);
    const res = await Request.post(url, {});
    return res.data;
  }

  getCGVById = async (id) => {
    const res = await Request.get('/cgv_code', { id });
    return res.data;
  }

  createCGVCode = async ({ url }) => {
    const res = await Request.post('/cgv_code/add', { url, public: false });
    return res.data;
  }

  removeCGVCode = async (id) => {
    const res = await Request.post('/cgv_code/delete', { id });
    return res.data;
  }

  markUsed = async id => {
    const res = await Request.post('/cgv/mark-used', { id });
    return res.data;
  }
}

export default new CGVCode();
