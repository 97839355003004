import React, { Component } from "react";
import {
  Grid, Row, Col, Table,
  Nav,
  NavItem,
  Tab
} from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import Maps from "components/Maps/MapWithMarkers"
import { withRouter } from 'react-router-dom'

@observer
class RestaurantList extends Component {

  renderTab = () => {
    const { restaurants } = Store.User;
    const thArray = ["Tên", "Địa chỉ", "Khu Vực", "Redem", "Lucky Draw", "CGV"];
    const tdArray = restaurants.slice().map(val => {
      return [
        val._id,
        val.name,
        val.address,
        val.registerRegion,
        !!val.restaurantData ? val.restaurantData.redemCount : 0,
        !!val.restaurantData ? val.restaurantData.luckyDrawCount : 0,
        !!val.restaurantData ? val.restaurantData.CGVCount : 0,
      ]
    })
    const listLatLngAdress = restaurants.slice().map(val => {
      return {
        lat: +val.location.lat,
        lng: +val.location.lng,
        name: val.name,
        _id: val._id,
      }
    })
    return (
      <Tab.Container id="tabs-with-dropdown" defaultActiveKey="info">
        <Row className="clearfix">
          <Col sm={12}>
            <Nav bsStyle="tabs">
              <NavItem eventKey="info">
                <i className="fa fa-list-ul" /> Danh sách
              </NavItem>
              <NavItem eventKey="account">
                <i className="fa fa-map-o" /> Bản đồ
              </NavItem>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content animation>
              <Tab.Pane eventKey="info">
                <Table striped hover>
                  <thead>
                    <tr>
                      {thArray.map((prop, key) => {
                        return <th key={key}>{prop}</th>;
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    {tdArray.map((prop, key) => {
                      return (
                        <tr key={key}>
                          {prop.map((val, i) => {
                            if (i === 0) return null;
                            return <td key={i}><Link to={"/admin/restaurant/edit/" + prop[0]}>{val}</Link></td>;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Tab.Pane>
              <Tab.Pane eventKey="account">
                <div style={{ width: '100%', height: 600 }}>
                  <Maps
                    isMarkerShown
                    listLatLngAdress={listLatLngAdress}
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `100%` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                    center={{
                      lat: 16.9456807,
                      lng: 106.9334659,
                    }}
                    defaultZoom={5.7}
                    onClickItem={val => {
                      this.props.history.push("/admin/restaurant/edit/"+val._id)
                    }}
                  />
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    )
  }

  render() {
    return (
      <Container onReady={Store.User.getRestaurant}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/restaurant/create">
                  <Button bsStyle="danger" block fill onClick={this.goToScreate}>
                    Tạo tài khoản nhà hàng
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title="Danh sách các tài khoản quản lý nhà hàng"
                  category="Tài khoản nhà hàng dùng để check mã voucher, in Lucky Draw"
                  ctFullWidth
                  content={this.renderTab()}
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(RestaurantList);
