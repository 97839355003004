import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Container from "components/Misc/Container";
import Card from "components/Card/Card.jsx";
import { thArray, tdArray } from "variables/Variables.jsx";
import Button from "components/CustomButton/CustomButton.jsx";

import Store from "../store"

class Frame extends Component {

  state = {
    isLoading: false,
  }

  onReady = () => {

  }

  createSampleUser = async () => {
    this.setState({ isLoading: true })
    const res = await Store.User.createSampleUsers();
    if (res.success) {
      window.notify.success(res.message)
    } else {
      window.notify.error(res.message)
    }
    this.setState({ isLoading: false })
  }

  eraseUser = async () => {
    this.setState({ isLoading: true })
    const res = await Store.User.eraseAllUsers();
    if (res.success) {
      window.notify.success(res.message)
    } else {
      window.notify.error(res.message)
    }
    this.setState({ isLoading: false })
  }

  createVouchers = async () => {
    this.setState({ isLoading: true })
    const res = await Store.Voucher.createSampleInstantVoucher();
    if (res.success) {
      window.notify.success(res.message)
    } else {
      window.notify.error(res.message)
    }
    this.setState({ isLoading: false })
  }

  clearRestaurant = async () => { 
    this.setState({ isLoading: true })
    const res = await Store.User.clearRestaurant();
    if (res.success) {
      window.notify.success(res.message)
    } else {
      window.notify.error(res.message)
    }
    this.setState({ isLoading: false })
  }

  clearVotingLeaderboard = async () => {
    this.setState({ isLoading: true })
    const res = await Store.User.clearVotingLeaderboard();
    if (res.success) {
      window.notify.success(res.message)
    } else {
      window.notify.error(res.message)
    }
    this.setState({ isLoading: false })
  }

  render() {
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={4}>
                <Button bsStyle="danger" fill onClick={this.createSampleUser} disabled={this.state.isLoading}>
                  Tạo thêm 1000 tk người chơi mẫu
                </Button>
              </Col>
              <Col md={4}>
                <Button bsStyle="danger" fill onClick={this.eraseUser} disabled={this.state.isLoading}>
                  Xóa hết tài khoản, voucher, luckydraw
                </Button>
              </Col>
              <Col md={4}>
                <Button bsStyle="danger" fill onClick={this.createVouchers} disabled={this.state.isLoading}>
                  Tạo Voucher dùng được ngay
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <Button bsStyle="danger" fill onClick={this.clearRestaurant} disabled={this.state.isLoading}>
                  Xóa dữ liệu tài khoản nhà hàng
                </Button>
              </Col>
              <Col md={4}>
                <Button bsStyle="danger" fill onClick={this.clearVotingLeaderboard} disabled={this.state.isLoading}>
                  Xóa dữ liệu voting, leaderboard
                </Button>
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default Frame;
