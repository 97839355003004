import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Table
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Container from "components/Misc/Container";
import CompState from "components/Misc/CompStateData";
import { Link } from 'react-router-dom';
import avatar from "assets/img/user.png";
import cover from "assets/img/user_cover.jpg";
import Store from "../../store";
import { thArray, tdArray } from "variables/Variables.jsx";
import { VoucherHelper, TimeHelper, VarHelper } from "../../helpers";
import Select from "react-select";
import MoneyImage from "components/MoneyImage/MoneyImage"

const displayValueOptions = [
  { label: '500', value: '500' },
  { label: '1000', value: '1000' },
  { label: '2000', value: '2000' },
  { label: '5000', value: '5000' },
  { label: '10000', value: '10000' },
  { label: '20000', value: '20000' },
  { label: '50000', value: '50000' },
  { label: '100000', value: '100000' },
  { label: '500000', value: '500000' },
];

const currencyOptions = [
  { label: 'VND', value: 'VND' },
  { label: 'USD', value: 'USD' },
];

class SMoneyDetail extends Component {

  state = {
    notFound: false,
    seri: '',
    code: '',
    displayValue: '',
    salePrice: '',
    currency: '',
    tags: [],
    hashtag: '',
    selectDisplayValue: { value: '500', label: '500' },
    selectCurrency: { value: 'VND', label: 'VND' },
  }

  dot = CompState.dot(this);

  onReady = async () => {
    console.log('here');
    const id = this.props.match.params.id;
    if (!id) return this.setState({ notFound: true })
    const res = await Store.SMoney.detail(id);
    if (res.success) {
      console.log(currencyOptions.find(val => val.value === res.data.currency));
      this.setState({
        ...res.data,
        selectDisplayValue: displayValueOptions.find(val => val.value === String(res.data.displayValue)),
        selectCurrency: currencyOptions.find(val => val.value === res.data.currency),
      });
    } else {
      window.notify.error(res.message);
      if (res.message === "NOT_EXISTED") this.setState({ notFound: true })
    }
  }

  updateSMoney = async (e) => {
    e.preventDefault();
    const { seri, code, displayValue, salePrice, currency, hashtag, id } = this.state;
    if (!seri || !displayValue || !currency) {
      return window.notify.error('Cần điền đủ thông tin');
    }
    const res = await Store.SMoney.update(id, { seri, code, displayValue: displayValue, salePrice: +salePrice, currency, tags: hashtag, })
    if (res.success) {
      window.notify.success('Lưu Tiền vào hệ thống thành công');
      this.props.history.push("/admin/smoneys");
    } else {
      window.notify.error('Có lỗi xảy ra: ' + res.message);
    }
  }

  render() {
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Xem chi tiết"
                  category=""
                  content={
                    <form onSubmit={this.updateSMoney}>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Seri",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.seri,
                            onChange: e => this.setState({ seri: e.target.value }),
                          }
                        ]}
                      />

                      <FormGroup>
                        <ControlLabel>Mệnh giá</ControlLabel>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={this.state.selectDisplayValue}
                          onChange={value =>
                            this.setState({ selectDisplayValue: value })
                          }
                          options={displayValueOptions}
                          placeholder=""
                        />
                      </FormGroup>

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Mã",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.code,
                            onChange: e => this.setState({ code: e.target.value }),
                          }
                        ]}
                      />

                      <FormGroup>
                        <ControlLabel>Loại tiền tệ</ControlLabel>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={this.state.selectCurrency}
                          onChange={value =>
                            this.setState({ selectCurrency: value })
                          }
                          options={currencyOptions}
                          placeholder=""
                        />
                      </FormGroup>

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Hashtag",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.hashtag,
                            onChange: e => this.setState({ hashtag: e.target.value }),
                          }
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Giá bán",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.salePrice,
                            onChange: e => this.setState({ salePrice: e.target.value }),
                          }
                        ]}
                      />
                      {this.state.selectCurrency && this.state.selectDisplayValue &&
                      <Row>
                        <Col md={6}>
                          <ControlLabel>Ảnh minh họa</ControlLabel>
                          <MoneyImage
                            seri={this.state.seri}
                            code={this.state.code}
                            currency={this.state.selectCurrency.value}
                            displayValue={this.state.selectDisplayValue.value}
                          />
                        </Col>
                      </Row>}
                      <Button bsStyle="danger" pullRight fill type="submit">
                        Lưu
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default SMoneyDetail;
