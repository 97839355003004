import React from 'react';
import XLSX from 'xlsx';
import _ from 'lodash';
var FileSaver = require('file-saver');

export default class ImportSMoney extends React.Component {

  state = {
    json: {},
    isProcessing: false,
    isCopied: false,
    autoDownload: false,
  }

  processExcel = (binary) => {
    var workbook = XLSX.read(binary, {
      type: 'binary'
    });

    var m1_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[0]]);
    var m2_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[1]]);
    var m3_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[2]]);
    var m4_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[3]]);

    var m5_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[4]]);
    var m6_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[5]]);
    var m7_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[6]]);
    var m8_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[7]]);

    var m9_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[8]]);
    var m10_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[9]]);
    var m11_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[10]]);
    var m12_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[11]]);

    var pair_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[12]]);
    var moneyType_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[workbook.SheetNames[13]]);

    var data = {
      m1: [], m2: [], m3: [], m4: [],
      m5: [], m6: [], m7: [], m8: [],
      m9: [], m10: [], m11: [], m12: [],
      capDoi: [], quyUoc: [],
    };

    moneyType_row_object.forEach((val) => {
      data.quyUoc.push({
        short: val['Quy ước viết tắt'],
        displayValue: val['Mệnh giá'],
        currency: val['Tiền tệ'],
        price: +val["Giá năm sinh"],
        priceYearPair: +val["Giá năm sinh kép"],
        priceYearPairSpecial: +val["Giá năm sinh kép đặc biệt"],
      });
    });

    const years = new Array(2056-1950).fill(1950).map((val, i) => String(val + i));

    [
      m1_row_object, m2_row_object, m3_row_object, m4_row_object,
      m5_row_object, m6_row_object, m7_row_object, m8_row_object,
      m9_row_object, m10_row_object, m11_row_object, m12_row_object,
    ].map((mData, monthIndex) => {
      const label = 'm' + (monthIndex + 1);
      mData.forEach((row) => {

        const dateAndMonth = String(row['Ngày']);
        const displayValue = String(row['MG']);
        if (!displayValue) return;
        const code = row["Ký tự"] || 'BA';
        
        const findMoney = data.quyUoc.find(val => val.short === displayValue || val.short === displayValue.toLowerCase());
        if (!findMoney) return;

        years.forEach((year, yearIndex) => {
          if (!row[year]) return;
  
          const short = findMoney.short;
          const salePrice = findMoney.price;
          const currency = findMoney.currency;
          const seri = String(dateAndMonth) + String(year);
  
          data[label].push({
            id: `${seri}_${code}_${currency}`,
            short,
            dateAndMonth,
            year,
            code,
            displayValue: findMoney.displayValue,
            currency,
            seri,
            salePrice,
          })
        });

      });

    })

    pair_row_object.forEach((row, rowI) => {
      const dateAndMonth = String(row['seri cặp']);
      const displayValue = String(row['Mệnh giá']);
      if (!displayValue) return;
      const code = row["Kí tự"] || 'BA';
      
      const findMoney = data.quyUoc.find(val => val.short === displayValue || val.short === displayValue.toLowerCase());
      if (!findMoney) return;
      // console.log(row);
      years.forEach((year, yearIndex) => {
        if (!row[year]) return;
        const short = findMoney.short;
        const seri = String(dateAndMonth) + String(year);
        const isSpecial = seri.substring(0, 4) === seri.substring(4, 8);
        const salePrice = isSpecial ? findMoney.priceYearPairSpecial : findMoney.priceYearPair;
        const currency = findMoney.currency;

        data.capDoi.push({
          id: `${seri}_${code}_${currency}`,
          short,
          dateAndMonth,
          year,
          code,
          displayValue: findMoney.displayValue,
          currency,
          seri,
          salePrice,
        })
      });
    });

    // console.log(data.capDoi);

    if (!this.state.autoDownload) {
      this.setState({ isProcessing: false, json: data });
      return;
    }
    
    var blob15 = new Blob([JSON.stringify(data.quyUoc)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob15, "dulieu-quy-uoc.json");
    var blob1 = new Blob([JSON.stringify(data.m1)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob1, "dulieu-thang-1.json");
    var blob2 = new Blob([JSON.stringify(data.m2)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob2, "dulieu-thang-2.json");
    var blob3 = new Blob([JSON.stringify(data.m3)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob3, "dulieu-thang-3.json");
    var blob4 = new Blob([JSON.stringify(data.m4)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob4, "dulieu-thang-4.json");
    var blob5 = new Blob([JSON.stringify(data.m5)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob5, "dulieu-thang-5.json");
    var blob6 = new Blob([JSON.stringify(data.m6)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob6, "dulieu-thang-6.json");
    var blob7 = new Blob([JSON.stringify(data.m7)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob7, "dulieu-thang-7.json");
    var blob8 = new Blob([JSON.stringify(data.m8)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob8, "dulieu-thang-8.json");
    var blob9 = new Blob([JSON.stringify(data.m9)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob9, "dulieu-thang-9.json");
    var blob10 = new Blob([JSON.stringify(data.m10)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob10, "dulieu-thang-10.json");
    var blob11 = new Blob([JSON.stringify(data.m11)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob11, "dulieu-thang-11.json");
    var blob12 = new Blob([JSON.stringify(data.m12)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob12, "dulieu-thang-12.json");
    var blob13 = new Blob([JSON.stringify(data.capDoi)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob13, "dulieu-cap-doi.json");

    this.setState({ isProcessing: false, json: data });
  }

  download = (data, name) => {
    var blob = new Blob([JSON.stringify(data)], {type: "application/json;charset=utf-8"});
    FileSaver.saveAs(blob, name);
  }

  handleChange = (e) => {
    if (Array.from(e.target.files).length === 0) return;
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.onload = (e) => {
      // console.log('onload')
      var data = e.target.result;
      this.setState({ isProcessing: true, isCopied: false }, () => {
        setTimeout(() => {
          this.processExcel(data);
        }, 1000);
      })
    };
    reader.onerror = function(ex) {
      console.log(ex);
    };
    reader.readAsBinaryString(file);
  }

  copy = () => {
    const copyToClipboard = str => {
			const el = document.createElement('textarea');
			el.value = str;
			document.body.appendChild(el);
			el.select();
			document.execCommand('copy');
			document.body.removeChild(el);
    };
    copyToClipboard(JSON.stringify(this.state.json, undefined, 2));
    this.setState({ isCopied: true });
  }

  render() {
    const { isProcessing, json, isCopied } = this.state;
    return (
      <div style={{ padding: 20 }}>
        <h3>Upload file excel:</h3>
        <div>
          <label>
            <input type="checkbox" checked={this.state.autoDownload} onChange={e => this.setState({ autoDownload: e.target.checked })} style={{ marginRight: 10 }} />
            Tự động tải xuống
          </label>
        </div>
        <div>
          <input type="file" onChange={this.handleChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
          {/* <button onClick={this.copy} style={{ float: 'right' }}>{isCopied ? 'Đã copy' : 'Copy mã' }</button>
          <div style={{ clear: 'both' }} /> */}
        </div>
        
        {isProcessing && (
          <div>
            <span>Đang xử lý...</span>
          </div>
        )}
        {/* <pre style={{ marginTop: 20 }}>{JSON.stringify(json, undefined, 2)}</pre> */}
        <div>
          {json.m1 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m1, 'dulieu-thang-1.json')}>du-lieu-thang-1.json</a></p>}
          {json.m2 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m2, 'dulieu-thang-2.json')}>du-lieu-thang-2.json</a></p>}
          {json.m3 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m3, 'dulieu-thang-3.json')}>du-lieu-thang-3.json</a></p>}
          {json.m4 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m4, 'dulieu-thang-4.json')}>du-lieu-thang-4.json</a></p>}
          {json.m5 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m5, 'dulieu-thang-5.json')}>du-lieu-thang-5.json</a></p>}
          {json.m6 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m6, 'dulieu-thang-6.json')}>du-lieu-thang-6.json</a></p>}
          {json.m7 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m7, 'dulieu-thang-7.json')}>du-lieu-thang-7.json</a></p>}
          {json.m8 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m8, 'dulieu-thang-8.json')}>du-lieu-thang-8.json</a></p>}
          {json.m9 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m9, 'dulieu-thang-9.json')}>du-lieu-thang-9.json</a></p>}
          {json.m10 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m10, 'dulieu-thang-10.json')}>du-lieu-thang-10.json</a></p>}
          {json.m11 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m11, 'dulieu-thang-11.json')}>du-lieu-thang-11.json</a></p>}
          {json.m12 && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.m12, 'dulieu-thang-12.json')}>du-lieu-thang-12.json</a></p>}
          {json.quyUoc && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.quyUoc, 'dulieu-quy-uoc.json')}>dulieu-quy-uoc.json</a></p>}
          {json.capDoi && <p><a style={{ cursor: 'pointer', color: 'black' }} onClick={() => this.download(json.capDoi, 'dulieu-cap-doi.json')}>dulieu-cap-doi.json</a></p>}
        </div>
      </div>
    );
  }
};
