import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';

@observer
class UserMienNam extends Component {

  onReady = async () => {
    Store.User.getUser({ registerRegion: 'Nam' });
  }

  render() {
    const { listUserMienNam } = Store.User;
    const thArray = ["Tên", "Khu Vực", "Redem", "Lucky Draw"];
    const tdArray = listUserMienNam.slice().map(val => {
      return [
        val._id,
        val.name,
        val.registerRegion,
        val.vouchers.length,
        val.luckyCode.length,
      ]
    })
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/create-user">
                  <Button bsStyle="danger" block fill onClick={this.goToScreate}>
                    Tạo tài khoản người chơi
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title="Danh sách người chơi khu vực phía Nam"
                  category="Tính từ Đà Nẵng trở vào"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    <Link to={"/admin/user-profile/" + prop[0]} style={{ color: 'black' }}>
                                      {val}
                                    </Link>
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default UserMienNam;
