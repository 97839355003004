import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"

import { TimeHelper, VoucherHelper, VarHelper } from "../../helpers"

@observer
class BillRestaurent extends Component {
  
  state = {
    list: [],
    activePage: 1,
    lastPage: 1,
  }

  onReady = async () => {
    this.getList();
  }

  getList = async () => {
    const { registerRegion } = this.props;
    const { activePage } = this.state;
    const res = await Store.Bill.getBills({ registerRegion: '' }, activePage);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.page,
        lastPage: res.pages || 1,
      })
    }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.getList());
  }

  render() {
    const { isBac, isNam, registerRegion } = this.props;
    const listUser = this.state.list;
    const thArray = ["Tông tiền", "Khách hàng", "Số điện thoại",  "Ngày cập nhật"];
    const tdArray = listUser.slice().map(val => {
      return [
        val._id,
        VarHelper.numberWithCommas(val.afterVoucherMoneyVAT) + ' VND',
        val.userName,
        val.phone,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ]
    })
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title={`Danh sách hóa đơn xuất ra của cửa hàng`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    <Link to={"/admin/bill/detail/" + prop[0]} style={{ color: 'black' }}>
                                      {val}
                                    </Link>
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'right' }}>
                  <Paging
                    last={this.state.lastPage}
                    activePage={this.state.activePage}
                    onChangePage={this.onChangePage}
                  />
                </div>
                <div className="clearfix" />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(BillRestaurent);