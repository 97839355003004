import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Request from "../../store/_Request";
import { HOST } from '../../store/_constants';
import Container from "components/Misc/Container";
import axios from 'axios';
import sampleCard from '../../assets/img/sample-excel-card.png'

class ImportTheCao extends Component {

  state = {
    isLoading: false,
    file: undefined,
    name: '',
  }

  importCard = async (e) => {
    e.preventDefault();
    const { file } = this.state;
    if (!file) return window.notify().error('Cần chọn file trước khi đăng');
    this.setState({ isLoading: true })
    const url = HOST + '/upload/importCardsFromExcel';
    const formData = new FormData();
    formData.append('file',file);
    const config = {
      headers: {
          'content-type': 'multipart/form-data',
          'Authorization': Request.getToken()
      }
    }
    const { data } = await axios.post(url, formData,config);
    if (data.success) {
      this.props.history.push('/admin/card');
      window.notify.success('Nhập thành công')
    } else {
      window.notify.error(data.message)
    }
    this.setState({ isLoading: false })
  }

  onChangeUpload = e => {
    const file = e.target.files[0];
    try {
      const file = e.target.files[0];
      this.setState({
        file,
        name: file.name,
      })
    } catch (err) {
    }
  }

  render() {
    return (
      <Container>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Tạo mã thẻ cào"
                  category="Nhập hàng loạt mã thẻ cào bằng cách upload file excel"
                  content={
                    <div>
                      <div>
                        <h4>Chuẩn bị file excel theo mẫu sau</h4>
                        <img src={sampleCard} style={{ width: 400, height: 'auto' }} />
                        <p>Lưu ý: Phần mã thẻ cào đặt ở <strong>cột đầu tiên, từ dòng thứ 2</strong></p>
                        <p>Lưu ý: Tên của sheet phải là <strong>Cards</strong></p>
                      </div>
                      <div style={{ marginTop: 35 }}>
                        <Button bsStyle="primary" fill onClick={() => this.uploadInput.click()} disabled={this.state.isLoading} style={{ marginRight: 15 }}>
                          <i className="fa fa-upload" /> {this.state.name ? this.state.name : 'Chọn file'}
                        </Button>
                        <Button bsStyle="default" fill onClick={() => this.setState({ name: '', file: undefined })} disabled={this.state.isLoading} style={{ marginRight: 15 }}>
                          Xóa file
                        </Button>
                        <Button bsStyle="danger" fill onClick={this.importCard} disabled={this.state.isLoading} style={{ marginRight: 15 }}>
                          Đăng
                        </Button>
                        <input type="file" style={{ display: 'none' }} ref={ref => this.uploadInput = ref} onChange={this.onChangeUpload} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                      </div>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(ImportTheCao);
