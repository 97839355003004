import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Container from "components/Misc/Container";
import Select from "react-select";
import MoneyImage from "components/MoneyImage/MoneyImage"

import './SMoneyLookUp.scss';

class SMoneyImport extends Component {

  state = {
    file: {},
    selectYear: { label: '19xx', value: '19' },
  }

  submitFile = async (e) => {
    e.preventDefault();
    const { file, selectYear } = this.state;
    if (!file.name) {
      return window.notify.error('Vui lòng chọn file');
    }
    const res = await Store.SMoney.importSMoney(file, selectYear.value);
    if (res.success) {
      return window.notify.success('Lưu Tiền vào hệ thống thành công');
    } else {
      window.notify.error('Lưu Tiền vào hệ thống thành công');
    }
  }

  onFileChange = e => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return;
    console.log(files[0]);
    this.setState({
      file: files[0],
    })
  }

  render() {
    return (
      <Container doNotRequireAuthen>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <form onSubmit={this.submitFile}>

                  <FormGroup>
                    <ControlLabel>Chọn năm</ControlLabel>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="singleSelect"
                      value={this.state.selectYear}
                      onChange={value =>
                        this.setState({ selectYear: value })
                      }
                      options={[
                        { label: '19xx', value: '19' },
                        { label: '20xx', value: '20' },
                      ]}
                      placeholder=""
                    />
                  </FormGroup>

                  <Row>
                    <Col md={9} sm={12}>

                      <FormGroup style={{ position: 'relative' }}>
                        <ControlLabel style={{ cursor: 'pointer' }} onClick={() => this.inputFile.click()}>Chọn file excel</ControlLabel>
                        <FormControl
                          type="text"
                          bsClass="form-control"
                          placeholder=""
                          value={this.state.file.name}
                          onChange={e => { }}
                          style={{ paddingLeft: 40 }}
                        />
                        <input onChange={this.onFileChange} ref={ref => this.inputFile = ref} type="file" accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" style={{ display: 'none' }} />
                        <span style={{ cursor: 'pointer', position: 'absolute', top: '50%', left: 20, }} onClick={() => this.inputFile.click()}>
                          <i className="pe-7s-file" style={{ transform: 'translate(-10px, 0px)', fontSize: '1.5em', color: 'black' }} />
                        </span>
                      </FormGroup>
                    </Col>

                    <Col md={3} sm={12}>
                      <FormGroup>
                        <ControlLabel></ControlLabel>
                        <Button block bsStyle="danger" style={{ marginTop: 5 }} fill type="submit">
                          Tải lên
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>

          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(SMoneyImport);
