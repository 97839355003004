import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class User extends StoreClass {

  constructor() {
    super();
    this.loadInitialData();
  }

  @observable restaurants = [];

  @observable userData = {
    
  };

  @observable listUserMienBac = [];
  @observable listUserMienNam = [];

  loadInitialData = async () => {
    let userData = await this.persist.sync("USER_DATA", "object");
    if (userData != undefined) {
      this.userData = userData;
      Request.setToken(this.getToken());
    }
    autorun(() => {
      this.persist.save(this.userData, "USER_DATA");
    });
    this.makeReady();
  }

  getToken = () => {
    if (!this.userData) return undefined;
    return this.userData.token;
  }

  isAuth = () => Boolean(this.getToken());

  login = async ({ username, password }) => {
    const res = await Request.post('/user/login', { username, password });
    if (res.data.success) {
      this.userData = res.data.data;
      Request.setToken(this.getToken());
    }
    return res.data;
  }

  logout = () => {
    this.userData = {};
    localStorage.clear();
  }

  getMyInfo = async () => {
    const res = await Request.post('/user/me', {});
    if (res.data.success) {
      this.userData = res.data.data;
      Request.setToken(this.getToken());
    }
    return res.data;
  }

  getRestaurant = async () => {
    const res = await Request.get('/users/listRestaurant', {});
    if (!!res.data.data) {
      this.restaurants = res.data.data;
    }
  }

  createRestaurant = async ({ phone, name, email, password, address, lat, lng, registerRegion }) => {
    const res = await Request.post('/users/create', {
      type: 'RESTAURANT',
      email,
      name,
      address,
      phone,
      location: {
        lat, lng
      },
      password,
      registerRegion
    });
    return res.data;
  }

  updateRestaurant = async (id, { email, password, address, lat, lng, registerRegion, phone, name }) => {
    const res = await Request.post(`/users/${id}/update`, {
      email, password, address, registerRegion, phone, name,
      location: {
        lat, lng
      },
    });
    return res.data;
  }

  getUser = async ({ registerRegion, name, phone, utm, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
      'filter[registerRegion]': registerRegion,
      'filter[type]': 'NORMAL',
    };
    if (name) {
      defaultQuery['filter[name][$regex]'] = name;
    }
    if (phone) {
      defaultQuery['filter[phone][$regex]'] = phone;
    }
    if (utm) {
      defaultQuery['filter[utm]'] = utm;
    }
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/users/list', defaultQuery);
    const res = await Request.post(url, { registerRegion });

    const shouldSaveToStore = !!limit ? limit <= 100 : true;
    if (res.data.success && shouldSaveToStore) {
      if (registerRegion === 'Bắc') this.listUserMienBac = res.data.data;
      if (registerRegion === 'Nam') this.listUserMienNam = res.data.data;
    }
    return res.data;
  }

  getUserPlay = async (page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
      'filter[type]': 'NORMAL',
      'filter[video][$exists]': 'true',
    };
    const url = Request.querify('/users/list', defaultQuery);
    const res = await Request.post(url, {});
    return res.data;
  }

  creatNormalUser = async ({ name, phone, password, registerRegion }) => {
    const res = await Request.post('/users/create', {
      type: 'NORMAL',
      phone, password, registerRegion, name
    });
    return res.data;
  }

  getUserInfo = async ({ id }) => {
    const res = await Request.get('/users/' + id, { });
    return res.data;
  }

  getUserInfoByPhone = async ({ phone }) => {
    const res = await Request.post('/users/find-by-phone', { phone });
    return res.data;
  }

  getUserInfoByVoucher = async ({ voucher }) => {
    const res = await Request.post('/users/find-by-voucher', { voucher });
    return res.data;
  }

  createSampleUsers = async () => {
    const res = await Request.post('/users/createSampleUsers', { });
    return res.data;
  }

  eraseAllUsers = async () => {
    const res = await Request.post('/users/eraseAllUsers', { });
    return res.data;
  }

  updateUser = async (id, data) => {
    const res = await Request.post(`/users/${id}/update`, data);
    return res.data;
  }

  clearRestaurant = async () => {
    const res = await Request.post('/users/clearRestaurant', { });
    return res.data;
  }

  removeARestaurant = async (id) => {
    const res = await Request.post('/users/remove-a-restaurent', { id });
    return res.data;
  }

  clearVotingLeaderboard = async () => {
    const res = await Request.post('/users/clear-voting-leaderboard', { });
    return res.data;
  }

  listRestaurantAddress = () => this.restaurants.map(val => ({ label: val.address, value: val.address }))
}

export default new User();
