import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Order extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable frames = [];

  getOrder = async ({ status, paymentMethod, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
    };
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/orders', defaultQuery);
    const res = await Request.get(url, {});
    return res.data;
  }

  detail = async (id) => {
    const res = await Request.get(`/orders/${id}/detail`, {});
    return res.data;
  }

  update = async (id, { status }) => {
    const res = await Request.put(`/orders/${id}/update`, { status });
    return res.data;
  }

  placeOrder = async ({ customerName, customerPhone, customerAddress, customerNote, carts, shippingFee, paymentMethod }) => {
    const res = await Request.post('/orders/place-order', { customerName, customerPhone, customerAddress, customerNote, carts, shippingFee, paymentMethod });
    return res.data;
  }

  placeOrderGoogleSheet = async ({ customerName, customerPhone, customerAddress, customerNote, cartText, shippingFee, totalFee, paymentMethod }) => {
    try {
      const url = 'https://docs.google.com/forms/u/0/d/e/1FAIpQLSej1JJrhLg0ZwjdNtjsB0B-SBHmQUrI3qwoa1PDAs87r7G_tg/formResponse';
      let res = await fetch(url, {
        method: "POST",
        headers: {
          'Content-Type': "application/x-www-form-urlencoded;charset=utf-8"
        },
        body: `entry.1566613551=${encodeURI(customerName)}&entry.753106110=${encodeURI(customerAddress)}&entry.635819854=${customerPhone}&entry.440276971=${encodeURI(customerNote)}&entry.1888552367=${encodeURI(cartText)}&entry.674026923=${shippingFee}&entry.472957743=${totalFee}&entry.34880854=${paymentMethod}`
      });
    } catch(err) {

    }
    
    return { success: true };
  }
}

export default new Order();
