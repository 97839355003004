import React, { Component, Fragment } from 'react';
import { Pagination, Row, Col } from "react-bootstrap";

export default class Paging extends Component {

  onClickFirst = () => {
    console.log('onClickFirst');
    this.props.onChangePage(1);
  }

  onClickLast = () => {
    console.log('onClickLast');
    this.props.onChangePage(this.props.last);
  }

  onClickPage = (page) => {
    this.props.onChangePage(page);
  }

  onClickNext = () => {
    const { onChangePage, activePage, last } = this.props;
    onChangePage(Math.min(last, activePage + 1));
  }

  onClickPrev = () => {
    const { onChangePage, activePage } = this.props;
    onChangePage(Math.max(1, activePage - 1));
  }

  onClickLast = () => {
    const { onChangePage, last } = this.props;
    onChangePage(last);
  }

  renderRange = (from, to, activePage) => {
    let arr = [];
    for ( let i=from; i<=to; i++) {
      arr.push(i)
    }
    return arr.map((val, i) => <Pagination.Item key={'page-'+val} active={val === activePage} onClick={() => this.onClickPage(val)}>{val}</Pagination.Item>);
  }

  renderActiveMiddlePages = () => {
    const { last, activePage } = this.props;
    if (last === 1) return null;
    if (last === 2) return null;
    if (last === 3) return this.renderRange(2, 2, activePage);
    if (last === 4) return this.renderRange(2, 3, activePage);
    if (activePage <= 4) {
      return (
        <Fragment>
          {this.renderRange(2, 4, activePage)}
          {last > 5 && <Pagination.Ellipsis />}
        </Fragment>
      )
    }
    if (activePage <= last && activePage >= last - 4) {
      return (
        <Fragment>
          {last > 5 && <Pagination.Ellipsis />}
          {this.renderRange(last-4, last-1, activePage)}
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Pagination.Ellipsis />
          {this.renderRange(activePage-2, activePage+2, activePage)}
        <Pagination.Ellipsis />
      </Fragment>
    )
  }

  render() {
    const { last, activePage, total } = this.props;
    return (
      <Row>
        <Col md={12}>
          <div style={{ float: 'right' }}>
            {!!total && <p style={{ textAlign: 'right' }}>Tổng số kết quả: {total}</p>}
            <Pagination>
              <Pagination.First onClick={this.onClickFirst} />
              <Pagination.Prev onClick={this.onClickPrev} />
              <Pagination.Item active={activePage === 1} onClick={() => this.onClickPage(1)}>{1}</Pagination.Item>
              {this.renderActiveMiddlePages()}
              {last !== 1 && <Pagination.Item active={activePage === last} onClick={() => this.onClickPage(last)}>{last}</Pagination.Item>}
              <Pagination.Next onClick={this.onClickNext} />
              <Pagination.Last onClick={this.onClickLast} />
            </Pagination>
          </div>
          <div className="clearfix" />
        </Col>
      </Row>
    )
  }
}