import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"
import ExportExcel from "components/Paging/ExportExcel"
import Search from "components/Search/Search"

import { TimeHelper, VarHelper } from "../../helpers"

@observer
class SMoneyListing extends Component {

  state = {
    activePage: 1,
    lastPage: 1,
    list: [],
    totalResults: 0,
  }

  onReady = async () => {
    this.getList();
  }

  getList = async () => {
    const { activePage, tempSearch } = this.state;
    if (!!tempSearch) return this.onSearch(tempSearch, activePage);
    const res = await Store.SMoney.getSMoney({ }, activePage);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.currentPage,
        lastPage: res.lastPage,
        totalResults: res.totalResults,
      })
    } else {
      window.notify.error(res.message);
    }
  }

  onSearch = async ({ key, value }, page = 1) => {
    const res = await Store.SMoney.getSMoney({ [key]: value }, page);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.currentPage,
        lastPage: res.lastPage,
        totalResults: res.totalResults,
        tempSearch: { key, value }
      })
    } else {
      window.notify.error(res.message);
    }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.getList());
  }

  filterData = (list, forExport = false) => {
    const thArray = ["Seri", "Mệnh giá", "Mã", "Loại tiền tệ", "Giá bán", "Hashtag", "Đơn",  "Thời gian cập nhật"];
    const tdArray = list.map(val => {
      return [
        val.id,
        val.seri,
        VarHelper.numberWithCommas(val.displayValue),
        val.code,
        val.currency,
        VarHelper.numberWithCommas(val.salePrice),
        val.tags,
        val.orderId,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ]
    })
    return forExport ? [
      ["SKU", ...thArray],
      ...tdArray,
    ] : {
      thArray, tdArray
    }
  }

  render() {
    const { list, totalResults } = this.state;
    const { thArray, tdArray } = this.filterData(list);
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/import-smoney">
                  <Button bsStyle="danger" block fill>
                    <i className="pe-7s-cloud-upload" style={{ transform: 'translate(-10px, 2px)', fontSize: '1.2em' }} /> Nhập dữ liệu từ excel
                  </Button>
                </Link>
              </Col>
              <Col md={3}>
                <Link to="/admin/create-smoney">
                  <Button bsStyle="danger" block fill>
                    Thêm mới
                  </Button>
                </Link>
              </Col>
            </Row>
            <Search
              options={[
                { value: 'seri', label: 'Seri', type: 'text' },
                { value: 'code', label: 'Mã', type: 'text' },
                { value: 'tags', label: 'Hashtag', type: 'text' },
                { value: 'displayValue', label: 'Mệnh giá', type: 'text' },
              ]}
              onSearch={this.onSearch}
            />
            <Row>
              <Col md={12}>
                <Card
                  title={`Danh sách tiền được đã nhập lên hệ thống`}
                  category={`Có ${totalResults} kết quả`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    <Link to={"/admin/smoney-detail/" + prop[0]} style={{ color: 'black' }}>
                                      {val}
                                    </Link>
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'right' }}>
                  <Paging
                    last={this.state.lastPage || 1}
                    activePage={this.state.activePage}
                    onChangePage={this.onChangePage}
                    total={this.state.total || 0}
                  />
                </div>
                <div className="clearfix" />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default SMoneyListing;