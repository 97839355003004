import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Frame extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable frames = [];

  getFrames = async () => {
    const res = await Request.get('/frame', {});
    if (res.data.success) {
      this.frames = res.data.data;
    }
    return res.data;
  }

  createFrame = async ({ url }) => {
    const res = await Request.post('/frame', { url, public: false });
    return res.data;
  }

  removeFrame = async (id) => {
    const res = await Request.post('/frame/delete', { id });
    return res.data;
  }

  updateCurrentFrame = async id => {
    const res = await Request.post('/frame/update-current', { id });
    return res.data;
  }
}

export default new Frame();
