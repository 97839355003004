import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"
import Select from "react-select";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Store from '../store'

import { TimeHelper } from '../helpers';

@observer
class TraCuu extends Component {

  state = {
    activePage: 1,
    lastPage: 1,
    list: [],
    thArray: [],
    searchType: { value: 1, label: "Mã bốc thăm Lucky Draw" },
    keyword: ''
  }

  onReady = async () => {
    this.getListUser();
  }

  getListUser = async () => {
    // const { activePage } = this.state;
    // const res = await Store.User.getUserPlay(activePage);
    // if (res.success) {
    //   this.setState({
    //     activePage: res.page,
    //     lastPage: res.pages,
    //     list: res.data,
    //   })
    // }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.search());
  }

  search = async (e) => {
    e.preventDefault();
    const { keyword, searchType, activePage } = this.state;
    if (!keyword) return window.notify.error('Vui lòng điền từ khóa và tiếp tục');
    if (searchType.label === "Mã bốc thăm Lucky Draw") {
      const res = await Store.Bill.searchLuckyDraw({ keyword }, activePage);
      if (res.success) {
        this.setState({
          list: res.data,
          activePage: res.page,
          lastPage: res.pages,
        })
      }
    }
  }

  render() {
    const thArray = this.state.thArray;
    const tdArray = this.state.list.map((val, i) => {
      return [
        val._id,
        val.userId,
        val.code,
        val.userName,
        val.phone,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ]
    })
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={12}>
                <form onSubmit={this.search}>
                  <FormInputs
                    ncols={["col-md-12"]}
                    properties={[
                      {
                        label: "Loại",
                        customRender: () => (
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="singleSelect"
                            value={this.state.searchType}
                            onChange={value =>
                              this.setState({ searchType: value })
                            }
                            options={[
                              { value: 1, label: "Mã bốc thăm Lucky Draw" },
                            ]}
                            placeholder="Loại đối tượng?"
                          />
                        )
                      },
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-12"]}
                    properties={[
                      {
                        label: "Từ khóa",
                        type: "text",
                        bsClass: "form-control",
                        placeholder: "",
                        value: this.state.keyword,
                        onChange: e => this.setState({ keyword: e.target.value }),
                      },
                    ]}
                  />
                  <Button bsStyle="danger" fill type="submit">
                    Tìm kiếm
                  </Button>
                </form>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title={`Kết quả`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index <= 1) return null;
                                return (
                                  <td key={index}>
                                    {index === 3 ? (
                                      <Link to={"/admin/user-profile/" + prop[1]} style={{ color: 'black' }}>
                                        {val}
                                      </Link>
                                    ) : val}
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'right' }}>
                  <Paging
                    last={this.state.lastPage || 1}
                    activePage={this.state.activePage}
                    onChangePage={this.onChangePage}
                  />
                </div>
                <div className="clearfix" />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(TraCuu);