import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Container from "components/Misc/Container";
import Select from "react-select";
import MoneyImage from "components/MoneyImage/MoneyImage"
import { TimeHelper, VarHelper } from "../../helpers"
import MessengerCustomerChat from 'react-messenger-customer-chat';
import { FacebookProvider, Page, CustomChat } from 'react-facebook';

import Swal from 'sweetalert2';

import _ from 'lodash';


import './SMoneyLookUp.scss';

class SMoneyLookUp extends Component {

  state = {
    date: '',
    month: '',
    year: '',
    results: [],
    resultNotFound: false,

    customerName: '',
    customerPhone: '',
    customerAddress: '',
    customerNote: '',
    carts: [],
    shippingFee: 30000,
    status: '',
    paymentMethod: '',

    selectMonth: undefined,
    selectPaymentMethod: { value: 'COD', label: 'Trả tiền khi nhận hàng (COD)' },
    monthData: [],
    monthDataYearInPair: [],
    yearPair1: '',
    yearPair2: '',

    isMonthLoading: false,
    isPairLoading: false,
  }

  componentDidMount() {
    this.searchYearInPair();
  }

  searchYearPair = async e => {
    e.preventDefault();
    let { yearPair1, yearPair2, monthDataYearInPair } = this.state;
    if (!yearPair1 || !yearPair2) {
      return alert('Cần điền đủ thông tin');
    }
    const possibleCombination = [
      `${yearPair1}${yearPair2}`,
      `${yearPair2}${yearPair1}`,
    ];
    const sameYearMoney = monthDataYearInPair.filter(val => val.year == yearPair1 || val.year == yearPair2);
    const results = [
      ...sameYearMoney.filter(val => {
        if (possibleCombination[0] === val.seri) return true;
        return false;
      }),
      ...sameYearMoney.filter(val => {
        if (possibleCombination[1] === val.seri) return true;
        return false;
      }),
    ]
    this.setState({
      results: _.unionWith(results, _.isEqual),
      resultNotFound: results.length === 0,
      date: '',
      month: '',
      year: '',
    }, () => {
      this.scrollToResult();
    })
  }

  search = async (e) => {
    e.preventDefault();
    let { date, year, selectMonth, monthData } = this.state;
    if (!date || !selectMonth || !year) {
      return alert('Cần điền đủ thông tin');
    }
    date = Number(date);
    if (!monthData || monthData.length === 0) {
      this.setState({
        results: [],
        resultNotFound: true,
      })
      return;
    }
    const sameMonthsameYearMoney = monthData.filter(val => val.year == year);
    const month = selectMonth.value;
    // const isDateTwoDigits = +date >= 10;
    // const isMonthTwoDigits = +month >= 10;
    const possibleDateAndMonth = [
      `${date}${month}`,
      `0${date}${month}`,
      `${date}0${month}`,
      `0${date}0${month}`,
    ];
    const results = sameMonthsameYearMoney.filter(val => {
      if (possibleDateAndMonth.includes(val.dateAndMonth)) return true;
      if (val.currency === 'USD') {
        for (let j = 0; j < possibleDateAndMonth.length; j++) {
          const containAndAtTheEnd = Boolean(val.dateAndMonth.length >= possibleDateAndMonth[j].length) && Boolean(val.dateAndMonth.indexOf(possibleDateAndMonth[j]) === val.dateAndMonth.length - possibleDateAndMonth[j].length);
          if (containAndAtTheEnd) return true;
        }
      }
      return false;
    });
    this.setState({
      results,
      resultNotFound: results.length === 0,
      yearPair1: '',
      yearPair2: '',
    }, () => {
      this.scrollToResult();
    })
  }

  placeOrder = async e => {
    e.preventDefault();
    const { customerName, customerPhone, customerAddress, customerNote, carts, shippingFee, selectPaymentMethod } = this.state;
    if (!customerName || !customerPhone || !customerAddress | !selectPaymentMethod) {
      return alert('Cần điền đủ thông tin: Họ và Tên, Số điện thoại và Địa chỉ nhận hàng');
    }

    if (selectPaymentMethod.value === 'BANK') {
      const shouldContinue = confirm('Hãy chắc chắn rằng bạn đã chuyển khoản trước khi bấm OK');
      if (!shouldContinue) return;
    }

    const res = await Store.Order.placeOrderGoogleSheet({
      customerName,
      customerNote,
      customerAddress,
      customerPhone,
      cartText: (() => {
        let text = '';
        carts.forEach(val => {
          text += `seri: ${val.seri} | mã: ${val.code} | mệnh giá: ${val.displayValue} | giá bán: ${val.salePrice}\n`;
        })
        return text;
      })(),
      shippingFee,
      totalFee: shippingFee + carts.reduce((a, b) => ({ salePrice: a.salePrice + b.salePrice })).salePrice,
      paymentMethod: selectPaymentMethod.value,
    });
    if (res.success) {
      // window.notify.success('Chúng tôi đã ghi nhận đơn đặt hàng của bạn và sẽ sớm liên hệ với bạn để xác nhận. Xin cảm ơn!');
      const btnRes = await Swal.fire(
        'Đặt hàng thành công',
        'Chúng tôi đã ghi nhận đơn đặt hàng của bạn và sẽ sớm liên hệ với bạn để xác nhận. Xin cảm ơn!',
        'success'
      );
      if (btnRes.isConfirmed) {
        this.setState({
          results: [],
          carts: [],
        });
      }
      return
    } else {
      alert(res.message || 'Có lỗi xảy ra, vui lòng thử lại sau.');
    }
  }

  searchMonth = async () => {
    const { selectMonth } = this.state;
    this.setState({ isMonthLoading: true });
    if (!selectMonth) return;
    const res = await Store.SMoney.searchAndParseGSheet(selectMonth.value);
    console.log('res', res);
    this.setState({
      monthData: res,
      isMonthLoading: false,
    })
  }

  searchYearInPair = async () => {
    this.setState({ isPairLoading: true })
    const res = await Store.SMoney.searchAndParseGSheetYearInPair();
    console.log('res', res);
    if (res.length === 0) {
      setTimeout(() => {
        this.searchYearInPair();
      }, 1000);
    } else {
      this.setState({
        monthDataYearInPair: res,
        isPairLoading: false,
      })
    }
  }

  renderNamSinh = () => {
    return (
      <>
        <h3 className="text-center" style={{ marginBottom: 20, color: '#30475e', fontWeight: 'bold' }}>Tìm seri tiền sinh nhật</h3>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <form onSubmit={this.search}>
                <Row>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <ControlLabel>Ngày</ControlLabel>
                      <FormControl
                        type="number"
                        bsClass="form-control"
                        placeholder="Điền ngày"
                        value={this.state.date}
                        onChange={e => this.setState({ date: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <ControlLabel>Tháng</ControlLabel>
                      {/* <FormControl
                          type="number"
                          bsClass="form-control"
                          placeholder=""
                          value={this.state.month}
                          onChange={e => this.setState({ month: e.target.value })}
                        /> */}
                      <Select
                        value={this.state.selectMonth}
                        onChange={data => this.setState({ selectMonth: data }, () => this.searchMonth())}
                        placeholder="Chọn tháng"
                        options={[
                          { value: 1, label: 'Tháng 1' },
                          { value: 2, label: 'Tháng 2' },
                          { value: 3, label: 'Tháng 3' },
                          { value: 4, label: 'Tháng 4' },
                          { value: 5, label: 'Tháng 5' },
                          { value: 6, label: 'Tháng 6' },
                          { value: 7, label: 'Tháng 7' },
                          { value: 8, label: 'Tháng 8' },
                          { value: 9, label: 'Tháng 9' },
                          { value: 10, label: 'Tháng 10' },
                          { value: 11, label: 'Tháng 11' },
                          { value: 12, label: 'Tháng 12' },
                        ]}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <ControlLabel>Năm</ControlLabel>
                      <FormControl
                        type="number"
                        bsClass="form-control"
                        placeholder="Điền năm"
                        value={this.state.year}
                        onChange={e => this.setState({ year: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <ControlLabel></ControlLabel>
                      <Button block  style={{ marginTop: 5, backgroundColor: '#30475e'  }} fill type="submit" disabled={this.state.isMonthLoading}>
                        {this.state.isMonthLoading ? 'Đang tải thêm...' : 'Tra cứu'}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Grid>
      </>
    )
  }

  renderNamSinhKep = () => {
    return (
      <>
        <h3 className="text-center" style={{ marginBottom: 20, color: '#30475e', fontWeight: 'bold' }}>Tìm năm sinh cặp đôi</h3>
        <Grid fluid>
          <Row>
            <Col md={12}>
              <form onSubmit={this.searchYearPair}>
                <Row>
                  <Col md={3} mdOffset={2} sm={12}>
                    <FormGroup>
                      <ControlLabel>Năm sinh</ControlLabel>
                      <FormControl
                        type="number"
                        bsClass="form-control"
                        placeholder="Điền năm"
                        value={this.state.yearPair1}
                        onChange={e => this.setState({ yearPair1: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3} sm={12}>
                    <FormGroup>
                      <ControlLabel>Năm sinh</ControlLabel>
                      <FormControl
                        type="number"
                        bsClass="form-control"
                        placeholder="Điền năm"
                        value={this.state.yearPair2}
                        onChange={e => this.setState({ yearPair2: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={2} sm={12}>
                    <FormGroup>
                      <ControlLabel></ControlLabel>
                      <Button block  style={{ marginTop: 5, backgroundColor: '#30475e'  }} fill type="submit" disabled={this.state.isPairLoading}>
                        {this.state.isPairLoading ? 'Đang tải thêm' : 'Tra cứu'}
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Grid>
      </>
    );
  }

  scrollToResult = () => {
    if (!window.$) return;
    if (this.state.results.length > 0) {
      window.$("html, body").animate({
        scrollTop: window.$("#search-result").offset().top
      }, 1000);
    }
  }

  render() {
    const shouldShowCheckoutForm = this.state.carts.length > 0;
    return (
      <FacebookProvider appId="321756205822559" chatSupport>
        <Container doNotRequireAuthen>
          <div className="content" style={{ paddingTop: 20, minHeight: 'calc( 100vh - 250px )' }}>
            {this.renderNamSinh()}
            {this.renderNamSinhKep()}
            <Grid fluid id="search-result">
              <Row>
                {this.state.resultNotFound ? (
                  <Col md={12}>
                    <p>Không tìm thấy kết quả nào, vui lòng thử lại với những ngày khác.</p>
                  </Col>
                ) : this.state.results.map((val, i) => (
                  <Col key={"item-" + i} md={4} sm={12} style={{ height: window.innerWidth <= 700 ? 250 : window.innerWidth <= 990 ? window.innerWidth * 0.5 : 300, marginBottom: 20 }}>
                    <MoneyImage
                      {...val}
                    />
                    <div className="money-lookup-item-container">
                      <p>Seri: <b>{val.seri}</b> | Mệnh giá: <b>{VarHelper.numberWithCommas(val.displayValue)}</b></p>
                      <p>Giá bán: <b style={{ fontSize: '1.2em' }}>{VarHelper.numberWithCommas(val.salePrice)}</b></p>
                      <label>
                        <input type="checkbox" checked={Boolean(this.state.carts.find(item => item.id === val.id))} onChange={e => {
                          const checked = e.target.checked;
                          let { carts } = this.state;
                          if (checked) {
                            const findCartIndex = carts.findIndex(item => item.id === val.id);
                            if (findCartIndex === -1) carts.push(val);
                            this.setState({ carts, shippingFee: carts.length >= 3 ? 0 : 30000 });
                          } else {
                            const findCartIndex = carts.findIndex(item => item.id === val.id);
                            if (findCartIndex !== -1) carts = [...carts.slice(0, findCartIndex), ...carts.slice(findCartIndex + 1, carts.length)];
                            this.setState({ carts, shippingFee: carts.length >= 3 ? 0 : 30000 });
                          }
                        }} />
                      Chọn mua
                    </label>
                    </div>
                  </Col>
                ))}
              </Row>
            </Grid>
            {shouldShowCheckoutForm && (
              <Grid fluid>
                <Row>
                  <Col md={6} xs={12} mdOffset={3}>
                    <p style={{ marginTop: 20 }}>Vui lòng điền các thông tin sau để tiến hành đặt hàng</p>
                    <form onSubmit={this.placeOrder}>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Họ và tên",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerName,
                            onChange: e => this.setState({ customerName: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Số điện thoại",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerPhone,
                            onChange: e => this.setState({ customerPhone: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Địa chỉ nhận hàng",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerAddress,
                            onChange: e => this.setState({ customerAddress: e.target.value }),
                          }
                        ]}
                      />
                      <FormGroup>
                        <ControlLabel>Hình thức thanh toán</ControlLabel>
                        {this.state.selectPaymentMethod.value === 'BANK' && (
                          <div>
                            <p>Vui lòng chuyển khoản về stk</p>
                            <p><b>0968361331</b></p>
                            <p><b>VPBank</b></p>
                            <p><b>NGUYEN THI HA</b></p>
                          </div>
                        )}
                        <Select
                          value={this.state.selectPaymentMethod}
                          onChange={data => this.setState({
                            selectPaymentMethod: data,
                            shippingFee: data.value === 'BANK' ? 20000 : data.value === 'COD' ? (this.state.carts.length >= 3 ? 0 : 30000) : this.state.shippingFee
                          })}
                          placeholder="Chọn hình thức thanh toán"
                          options={[
                            { value: 'COD', label: 'Trả tiền khi nhận hàng (COD)' },
                            { value: 'BANK', label: 'Chuyển khoản' },
                          ]}
                        />
                      </FormGroup>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Ghi chú",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerNote,
                            onChange: e => this.setState({ customerNote: e.target.value }),
                          }
                        ]}
                      />
                      <FormGroup>
                        <p>Tiền ship: {this.state.shippingFee}</p>
                        <p>Tiền hàng: {this.state.carts.reduce((a, b) => ({ salePrice: a.salePrice + b.salePrice })).salePrice}</p>
                        <p>Tổng cộng: {VarHelper.numberWithCommas(this.state.shippingFee + this.state.carts.reduce((a, b) => ({ salePrice: a.salePrice + b.salePrice })).salePrice)}</p>
                      </FormGroup>
                      <FormGroup>
                        <Button block  style={{ marginTop: 5, backgroundColor: '#30475e' }} fill type="submit">
                          Đặt hàng
                      </Button>
                      </FormGroup>
                    </form>
                  </Col>
                </Row>
              </Grid>
            )}
          </div>
          {/* <MessengerCustomerChat
          pageId="411938035546369"
          appId="321756205822559"
          themeColor="#fb404b"
        /> */}
          <div style={{ clear: 'both', padding: 10, borderTop: '1px solid #cecece' }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-4 col-md-offset-2 col-xs-12">
                  <h4 style={{ color: '#30475e', fontWeight: 'bold' }} >Tiền seri sinh nhật</h4>
                  <p style={{ marginBottom: 4 }} ><i className="pe-7s-global" style={{ fontSize: 26, transform: 'translateY(4px)', marginRight: 3, }} /> <b>Khotiensinhnhat.com</b></p>
                  <p style={{ marginBottom: 4 }} ><i className="pe-7s-phone" style={{ fontSize: 26, transform: 'translateY(4px)', marginRight: 3, }} /> <a style={{ color: 'black' }} href="tel:0968361331">096 836 1331</a></p>
                  <p style={{ marginBottom: 4 }} ><i className="pe-7s-mail" style={{ fontSize: 26, transform: 'translateY(4px)', marginRight: 3, }} /> <a href="mailto:hungmai1245@gmail.com" style={{ color: 'black' }}>hungmai1245@gmail.com</a></p>
                  <p style={{ marginBottom: 4 }} ><i className="pe-7s-like2" style={{ fontSize: 26, transform: 'translateY(4px)', marginRight: 5, }} /> <a href="https://www.facebook.com/profile.php?id=100026578211682" style={{ color: 'black' }}>facebook</a></p>
                </div>
                <div className="col-md-6 col-xs-12">
                  <Page href="https://www.facebook.com/Shop-%C4%90%E1%BB%93-S%C6%B0u-T%E1%BA%A7m-Ti%E1%BB%81n-seri-ng%C3%A0y-th%C3%A1ng-n%C4%83m-sinh-ng%C3%A0y-k%E1%BB%B7-ni%E1%BB%87m-108392578173262" tabs="" />
                </div>
              </div>
            </div>
            <div style={{ clear: 'both' }} />
            <CustomChat pageId="108392578173262" themeColor="#30475e" />
          </div>
        </Container>
      </FacebookProvider>
    );
  }
}

export default withRouter(SMoneyLookUp);
