import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"

import { TimeHelper } from "../../helpers"

@observer
class ClipMau extends Component {

  onReady = async () => {
    this.getListUser();
  }

  getListUser = async () => {
    Store.Video.getVideos();
  }

  render() {
    const list = Store.Video.videos.slice();
    const thArray = ["STT", "Tên", "Thumbnail", "Preview", "Voting"];
    const tdArray = list.map((val, i) => {
      return [
        val._id,
        i+1,
        val.name,
        <img src={val.thumb} style={{ width: 250, height: 'auto' }} />,
        <video style={{ width: 250, height: 'auto' }} controls>
          <source src={val.url} type="video/mp4" />
        </video>,
        val.vote,
      ]
    })
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/dancing-add-clip">
                  <Button bsStyle="danger" block fill>
                    Thêm video
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title={`Video clip mẫu của chương trình`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    {val}
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(ClipMau);