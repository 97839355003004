import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Bill extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable listBillMienBac = [];
  @observable listBillMienNam = [];
  @observable listLuckyDrawMienBac = [];
  @observable listLuckyDrawMienNam = [];

  getBills = async ({ registerRegion, userName, numberOfCombo, restaurantAddress, cgvCode, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
      'filter[registerRegion]': registerRegion,
    };
    if (userName) {
      defaultQuery['filter[userName][$regex]'] = userName;
    }
    if (numberOfCombo) {
      defaultQuery['filter[numberOfCombo]'] = numberOfCombo;
    }
    if (restaurantAddress) {
      defaultQuery['filter[restaurantAddress]'] = restaurantAddress;
    }
    if (cgvCode === 'true') {
      defaultQuery['filter[cgvCode][$exists]'] = 'true';
      defaultQuery['filter[cgvCode][$ne]'] = 'null';
    } else if (cgvCode === 'false') {
      defaultQuery['filter[cgvCode][$ne]'] = 'true';
    }
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/bills/list', defaultQuery);
    const res = await Request.post(url, { registerRegion });
    if (res.data.success) {
      if (registerRegion === 'Bắc') this.listBillMienBac = res.data.data;
      if (registerRegion === 'Nam') this.listBillMienNam = res.data.data;
    }
    return res.data;
  }

  getBillOneUser = async ({ userId }) => {
    const defaultQuery = {
      'filter[userId]': userId,
    };
    const url = Request.querify('/bills/list', defaultQuery);
    const res = await Request.post(url, { });
    return res.data;
  }

  getLuckyDraws = async ({ registerRegion, userName, code, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
      'filter[registerRegion]': registerRegion,
    };
    if (code) {
      defaultQuery['filter[code][$regex]'] = code;
    }
    if (userName) {
      defaultQuery['filter[userName][$regex]'] = userName;
    }
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/lucky-draw/list', defaultQuery);
    const res = await Request.post(url, { registerRegion });

    const shouldSaveToStore = !!limit ? limit <= 100 : true;
    if (res.data.success && shouldSaveToStore) {
      if (registerRegion === 'Bắc') this.listLuckyDrawMienBac = res.data.data;
      if (registerRegion === 'Nam') this.listLuckyDrawMienNam = res.data.data;
    }
    return res.data;
  }

  searchLuckyDraw = async ({ keyword }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
      'filter[code][$regex]': keyword,
    };
    const url = Request.querify('/lucky-draw/list', defaultQuery);
    const res = await Request.post(url, { });
    return res.data;
  }

  createBill = async (data) => {
    const {
      userId,
      userName,
      phone,
      registerRegion,
      billMoney,
      afterVoucherMoney,
      afterVoucherMoneyVAT,
      buffetValue,
      numberOfGuess,
      numberOfCombo,
      vouchers,
      items,
    } = data;
    const res = await Request.post('/bills', data);
    return res.data;
  }

  getBillDetail = async ({ _id }) => {
    const res = await Request.get('/bills/' + _id, {});
    return res.data;
  }
}

export default new Bill();
