import React, { Component, Fragment } from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { observer } from 'mobx-react';
import Store from '../../store'
import RedirectWhenNotAuthen from './RedirectWhenNotAuthen';

interface Props {
  onReady(): void,
}

export default class Container extends Component<Props, any> {

  state = {
    ready: false,
  }

  async componentDidMount() {
    await Store.User.onReady();
    this.setState({
      ready: true,
    });
    !!this.props.onReady && this.props.onReady();
  }

  render() {
    const { ready } = this.state;
    const { doNotRequireAuthen } = this.props;
    return !ready ? null : (
      <Fragment>
        {!doNotRequireAuthen && <RedirectWhenNotAuthen />}
        {this.props.children}
      </Fragment>
    )
  }
}