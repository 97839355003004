import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Container from "components/Misc/Container";
import Select from "react-select";
import MoneyImage from "components/MoneyImage/MoneyImage"

class UserCreate extends Component {

  state = {
    seri: '',
    code: '',
    displayValue: '',
    salePrice: '',
    currency: '',
    selectDisplayValue: { value: '500', label: '500' },
    selectCurrency: { value: 'VND', label: 'VND' },
    tags: '',
    hashtag: '',
  }

  createSMoney = async (e) => {
    e.preventDefault();
    const { seri, code, displayValue, salePrice, selectCurrency, selectDisplayValue, hashtag } = this.state;
    if (!seri || !displayValue || !selectCurrency) {
      return window.notify.error('Cần điền đủ thông tin');
    }
    const res = await Store.SMoney.create({ seri, code, displayValue: +selectDisplayValue.value, salePrice: +salePrice, currency: selectCurrency.value, tags: hashtag })
    if (res.success) {
      window.notify.success('Lưu Tiền vào hệ thống thành công');
      this.props.history.push("/admin/smoneys");
    } else {
      window.notify.error('Có lỗi xảy ra: ' + res.message);
    }
  }

  render() {
    return (
      <Container>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Nhập Tiền vào hệ thống"
                  category="Lưu ý: để nhập nhiều vui lòng sử dụng chức năng import excel"
                  content={
                    <form onSubmit={this.createSMoney}>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Seri",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.seri,
                            onChange: e => this.setState({ seri: e.target.value }),
                          }
                        ]}
                      />
                      <FormGroup>
                        <ControlLabel>Mệnh giá</ControlLabel>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={this.state.selectDisplayValue}
                          onChange={value =>
                            this.setState({ selectDisplayValue: value })
                          }
                          options={[
                            { label: '500', value: '500' },
                            { label: '1000', value: '1000' },
                            { label: '2000', value: '2000' },
                            { label: '5000', value: '5000' },
                            { label: '10000', value: '10000' },
                            { label: '20000', value: '20000' },
                            { label: '50000', value: '50000' },
                            { label: '100000', value: '100000' },
                            { label: '500000', value: '500000' },
                          ]}
                          placeholder=""
                        />
                      </FormGroup>

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Mã",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.code,
                            onChange: e => this.setState({ code: e.target.value }),
                          }
                        ]}
                      />

                      <FormGroup>
                        <ControlLabel>Loại tiền tệ</ControlLabel>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={this.state.selectCurrency}
                          onChange={value =>
                            this.setState({ selectCurrency: value })
                          }
                          options={[
                            { label: 'VND', value: 'VND' },
                            { label: 'USD', value: 'USD' },
                          ]}
                          placeholder=""
                        />
                      </FormGroup> 
                      
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Hashtag",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.hashtag,
                            onChange: e => this.setState({ hashtag: e.target.value }),
                          }
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Giá bán",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.salePrice,
                            onChange: e => this.setState({ salePrice: e.target.value }),
                          }
                        ]}
                      />

                      <Row>
                        <Col md={6}>
                          <ControlLabel>Ảnh minh họa</ControlLabel>
                          <MoneyImage
                            seri={this.state.seri}
                            code={this.state.code}
                            currency={this.state.selectCurrency.value}
                            displayValue={this.state.selectDisplayValue.value}
                          />
                        </Col>
                      </Row>
                      <Button bsStyle="danger" pullRight fill type="submit">
                        Thêm
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(UserCreate);
