import React, { Component, Fragment } from "react";
import moment from 'moment';

import Store from '../../store';

import logoKichi from '../../assets/img/logo-kichi-alpha.png';
import { VarHelper } from "../../helpers";

export default class Invoice extends Component {

  afterVoucherMoney = () => {
    const { money, vouchers } = this.props;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return VarHelper.numberWithCommas(value);
  }

  VATMoney = () => {
    const { money, vouchers } = this.props;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return VarHelper.numberWithCommas(Math.floor(value * 0.1));
  }

  afterVoucherMoneyVAT = () => {
    const { money, vouchers } = this.props;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return VarHelper.numberWithCommas(Math.floor(value * 1.1));
  }

  renderLuckyDraw = () => {
    const { items } = this.props;
    if (!items || items.length === 0) return null;
    return (
      <Fragment>
        <tr className="heading">
          <td>
            Tặng mã bốc thăm
          </td>
          <td>
            Mô tả
          </td>
        </tr>
        {items.map((val, i) => (
          <tr className={i === items.length - 1 ? "item last" : "item"} key={`item-${i}`}>
            <td>
              {val.code}
            </td>
            <td>
              {val.description}
            </td>
          </tr>
        ))}
      </Fragment>
    )
  }

  render() {
    const { name, phone, money, items, vouchers, billCode, numberOfGuess, restaurantAddress, soCheck } = this.props;
    return (
      <div className="invoice-box">
        <table cellPadding={0} cellSpacing={0}>
          <tbody><tr className="top">
            <td colSpan={2}>
              <table>
                <tbody>
                  <tr>
                    <td className="title">
                      <img src={logoKichi} />
                    </td>
                    {/* <td style={{ paddingTop: '2em' }}> */}
                    <td className="title-left" style={{ textAlign: 'left', paddingLeft: 15 }}>
                      Nhà hàng Kichi Kichi<br />
                      Địa chỉ: {restaurantAddress || Store.User.userData.address}<br />
                      Hotline: 19006622<br />
                      Ngày tạo hóa đơn: {moment().format("DD/MM/YYYY")}<br />
                      Số check: {soCheck}<br />
                    </td>
                  </tr>
                </tbody></table>
            </td>
          </tr>
            {/* <tr className="information">
              <td colSpan={2}>
                <table>
                  <tbody><tr>
                    <td>
                      {Store.User.userData.address}
                    </td>
                    <td>
                      Hotline: 19006622
                    </td>
                  </tr>
                  </tbody>
                </table>
              </td>
            </tr> */}
            <tr className="information">
              <td colSpan={2}>
                <table>
                  <tbody><tr>
                    <td>
                      Khách hàng: {name.toUpperCase()}<br />
                      {!!numberOfGuess &&
                      <Fragment>
                        Tổng số người: {numberOfGuess}
                      </Fragment>}
                    </td>
                    <td>
                      Điện thoại: {phone}
                    </td>
                  </tr>
                  </tbody></table>
              </td>
            </tr>
            <tr className="heading">
              <td>
                Thông tin hóa đơn
              </td>
              <td>
                Số tiền (Chưa có VAT)
              </td>
            </tr>
            <tr className="details">
              <td>
                Bữa ăn Buffet tại Kichi Kichi
              </td>
              <td>
                {VarHelper.numberWithCommas(money)} VND
              </td>
            </tr>
            {vouchers.map((val, i) => (
              <tr className={i === items.length - 1 ? "item last" : "item"} key={`item-${i}`}>
                <td>
                  {val.voucher} : {val.description}
                </td>
                <td>
                  {!!val.value ? `- ${val.value} VND` : ''}
                </td>
              </tr>
            ))}
            <tr className="total">
              <td>Tiền hàng</td>
              <td>
                {this.afterVoucherMoney()} VND
              </td>
            </tr>
            <tr className="total">
              <td>Thuế</td>
              <td>
                {this.VATMoney()} VND
              </td>
            </tr>
            <tr className="total">
              <td >Tổng tiền</td>
              <td>
                {this.afterVoucherMoneyVAT()} VND
              </td>
            </tr>
            <tr><td/><td/></tr>
            <tr><td/><td/></tr>
            {this.renderLuckyDraw()}
          </tbody></table>

          <p style={{ fontSize: '0.7em', textAlign: 'center', marginTop: 15 }}>Vui lòng truy cập TRUNGLON2019.KICHI.COM.VN để biết thêm thông về giải thưởng đang chờ bạn</p>
      </div>
    )
  }
}