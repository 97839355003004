import React, { Component, Fragment } from "react";
import ChartistGraph from "react-chartist";
import { Redirect } from "react-router-dom";
import { Card } from "components/Card/Card.jsx";
import { Link } from 'react-router-dom';
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { StatsCard } from "components/StatsCard/StatsCard.jsx";
import { Tasks } from "components/Tasks/Tasks.jsx";
import { ControlLabel } from "react-bootstrap";
import Paging from "components/Paging/Paging"
import {
  dataPie,
  legendPie,
  dataSales,
  optionsSales,
  responsiveSales,
  legendSales,
  dataBar,
  optionsBar,
  responsiveBar,
  legendBar
} from "variables/Variables.jsx";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Container from "components/Misc/Container";
import { TimeHelper, VoucherHelper, VarHelper } from "../helpers"
import Store from '../store';
import { observer } from 'mobx-react';

const orderStatusOptions = [
  { label: 'Chưa xử lý', value: 'PENDING' },
  { label: 'Hoàn thành', value: 'PAID_AND_SHIPPED' },
  { label: 'Đã nhận tiền, chưa ship', value: 'PAID_AND_NOT_SHIPPED' },
  { label: 'Đang ship, chưa nhận tiền', value: 'SHIPPING_AND_NOT_PAID' },
  { label: 'Đang ship, đã nhận tiền', value: 'SHIPPING_AND_PAID' },
];

@observer
class Dashboard extends Component {

  state = {
    username: '',
    password: '',
    list: [],
  }

  async componentDidMount() {
    await Store.User.onReady();
    if (Store.User.isAuth()) {
      this.getList();
    }
  }

  getList = async () => {
    const { activePage, tempSearch } = this.state;
    if (!!tempSearch) return this.onSearch(tempSearch, activePage);
    const res = await Store.Order.getOrder({}, activePage);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.page,
        lastPage: res.pages,
        total: res.total,
      })
    }
  }

  onSearch = async ({ key, value }, page = 1) => {
    const res = await Store.SMoney.getSMoney({ [key]: value }, page);
    if (res.success) {
      this.setState({
        activePage: res.page,
        lastPage: res.pages,
        total: res.total,
        tempSearch: { key, value }
      })
    }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.getList());
  }

  login = async e => {
    !!e && e.preventDefault();
    const { username, password } = this.state;
    if (!username || !password) {
      return alert('Vui lòng điền đủ thông tin');
    }
    const res = await Store.User.login({ username, password });
    if (res.success) {
      window.notify.success("Đăng nhập thành công");
      await this.getList();
    } else {
      window.notify.error(res.message);
    }
  }

  renderLogin = () => {
    return (
      <div className="content middle">
        <Col md={4} xs={12}>
          <Card
            title="Vui lòng đăng nhập để tiếp tục"
            content={
              <form onSubmit={this.login}>
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Username",
                      type: "text",
                      bsClass: "form-control",
                      placeholder: "Username",
                      value: this.state.email,
                      onChange: e => this.setState({ username: e.target.value })
                    },
                  ]}
                />
                <FormInputs
                  ncols={["col-md-12"]}
                  properties={[
                    {
                      label: "Password",
                      type: "password",
                      bsClass: "form-control",
                      placeholder: "Input your password",
                      value: this.state.password,
                      onChange: e => this.setState({ password: e.target.value })
                    }
                  ]}
                />
                <Button bsStyle="danger" block fill type="submit">
                  Đăng nhập
                </Button>
              </form>
            }
          />
        </Col>
      </div>
    );
  }

  filterData = (list, forExport = false) => {
    const thArray = ["Sản phẩm", "Tiền khách phải trả", "Tên", "SDT", "Địa chỉ", "Ghi chú", "Thời gian đặt", "Trạng thái"];
    const tdArray = list.map(val => {
      const displayStatus = orderStatusOptions.find(item => item.value === val.status);
      return [
        val.id,
        <>{val.carts.map((p, i) => (
          <>
            {i !== 0 && <br />}
            {p.displayValue} | {p.seri}
          </>
        ))}</>,
        val.totalPrice,
        val.customerName,
        val.customerPhone,
        val.customerAddress,
        val.customerNote,
        TimeHelper.changeFormat(undefined, val.createdAt),
        !!displayStatus ? displayStatus.label : val.status,
      ]
    })
    return forExport ? [
      ["ID", ...thArray],
      ...tdArray,
    ] : {
        thArray, tdArray
      }
  }

  render() {
    const isAuth = Store.User.isAuth();
    if (!isAuth) return this.renderLogin();
    const { list } = this.state;
    const { thArray, tdArray } = this.filterData(list);
    return (
      <div className="content">
        <Grid fluid>
          <Row>
            <Col md={12}>
              <Card
                title={`Danh sách đơn hàng`}
                category={''}
                ctTableFullWidth
                ctTableResponsive
                content={
                  <Table striped hover>
                    <thead>
                      <tr>
                        {thArray.map((prop, key) => {
                          return <th key={key}>{prop}</th>;
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {tdArray.map((prop, key) => {
                        return (
                          <tr key={key}>
                            {prop.map((val, index) => {
                              if (index === 0) return null;
                              return (
                                <td key={index}>
                                  <Link to={"/admin/order-detail/" + prop[0]} style={{ color: 'black' }}>
                                    {val}
                                  </Link>
                                </td>
                              )
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                }
              />
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div style={{ float: 'right' }}>
                <Paging
                  last={this.state.lastPage || 1}
                  activePage={this.state.activePage}
                  onChangePage={this.onChangePage}
                  total={this.state.total || 0}
                />
              </div>
              <div className="clearfix" />
            </Col>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
