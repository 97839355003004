import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Table,
  Button as Btn
} from "react-bootstrap";
import Container from "components/Misc/Container"
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Invoice from "components/Invoice/Invoice";
import Select from "react-select";
import { observer } from 'mobx-react';
import avatar from "assets/img/faces/face-3.jpg";
import Store from "../../store";
import { VoucherHelper, VarHelper, TimeHelper } from "../../helpers";

@observer
class VoucherCheck extends Component {

  state = {
    numberOfVoucher: 0,
    vouchers: [],
    numberOfCombo: 0,
    phoneInput: '',
    voucherInput: '',
    numberOfGuess: 0,
    money: '',
    moneyVAT: '',
    luckydraw: [],
    userInfo: {
      name: '',
      phone: '',
    },
    buffet: null,
    didCreateBill: false,
    billCode: '',
    soCheck: '',
  }

  checkUserViaVoucher = async () => {
    const { voucherInput } = this.state;
    if (!voucherInput) return window.notify.error("Cần nhập voucher");
    const res = await Store.User.getUserInfoByVoucher({ voucher: voucherInput });
    if (res.success) {
      this.setState({
        userInfo: res.data
      });
    } else if (res.message === "NOT_EXISTED") {
      window.notify.error("Voucher không tồn tại");
    }
  }

  checkUser = async () => {
    const { phoneInput } = this.state;
    if (!phoneInput) return window.notify.error("Cần nhập số điện thoại");
    const res = await Store.User.getUserInfoByPhone({ phone: phoneInput });
    if (res.success) {
      this.setState({
        userInfo: res.data
      });
    } else if (res.message === "NOT_EXISTED") {
      window.notify.error("Tài khoản của khách hàng chưa tồn tại trên hệ thống, vui lòng hướng dẫn khách tạo tài khoản");
    }
  }

  useVoucher = (item) => {
    if (Store.User.userData.registerRegion === 'Nam') {
      let { vouchers, buffet } = this.state;
      console.log('item', item);
      if (item.type === VoucherHelper.type.P3.type && !buffet) {
        return window.notify.error("Cần chọn suất Buffet của khách trước");
      }
      const checkExisted = vouchers.find(val => val.voucher === item.code);
      if (!!checkExisted) return window.notify.error("Voucher này đã có trong danh sách");
      vouchers.push({ _id: item._id, voucher: item.code, description: "Voucher "+ VoucherHelper.getDes(item.type), value: VoucherHelper.getValue(item.type, !!buffet ? buffet.value : 0) })
      this.setState({ vouchers });
    } else {
      // miền Bắc k trừ 1 suất Buffet nữa
      let { vouchers, buffet } = this.state;
      console.log('item', item);
      const checkExisted = vouchers.find(val => val.voucher === item.code);
      if (!!checkExisted) return window.notify.error("Voucher này đã có trong danh sách");
      vouchers.push({ _id: item._id, voucher: item.code, description: "Voucher "+ VoucherHelper.getDes(item.type), value: VoucherHelper.getValue(item.type, 0) })
      this.setState({ vouchers });
    }
  }

  renderLuckyCard = (val, i, type) => {
    const removeLuckyCard = () => {
      if (type === 'combo') {
        this.setState({ numberOfCombo: Math.max(0, this.state.numberOfCombo - 1) })
      }
      if (type === 'voucher') {
        let { vouchers } = this.state;
        let findIndex = vouchers.findIndex(val => val.voucher === val.voucher);
        this.setState({
          vouchers: [...vouchers.slice(0, findIndex), ...vouchers.slice(findIndex + 1, vouchers.length)]
        })
      }
    }
    return (
      <div key={`item-${i}-${type}`} style={{ display: 'inline-block', padding: 15, backgroundColor: 'rgba(0,0,0,0.1)', position: 'relative', marginLeft: 15, marginBottom: 15 }}>
        {val.description}
        <i className="fa fa-times cursor" style={{ marginLeft: 10 }} onClick={removeLuckyCard} />
      </div>
    )
  }

  afterVoucherMoney = () => {
    const { money, vouchers } = this.state;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return value;
  }

  VATMoney = () => {
    const { money, vouchers } = this.state;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return Math.floor(value * 0.1);
  }

  afterVoucherMoneyVAT = () => {
    const { money, vouchers } = this.state;
    let value = money;
    vouchers.forEach(val => {
      value -= val.value;
    });
    return Math.floor(value * 1.1);
  }

  getLuckyDraw = async () => {
    return alert("Chương trình đã kết thúc. Hệ thống dừng cấp mã bốc thăm. Cảm ơn các bạn đã đồng hành cùng chương trình")
    let i = 0;
    const { numberOfCombo, vouchers, userInfo, buffet, numberOfGuess, soCheck, money } = this.state;
    if (!userInfo._id) {
      return window.notify.error('Cần chọn 1 khách trước khi tạo hóa đơn')
    }
    if (!soCheck || !money) {
      return window.notify.error('Vui lòng điền đủ các trường thông tin Số Check và tiền')
    }
    let comboItems = new Array(+numberOfCombo).fill({
      description: 'Mua combo'
    })
    let voucherItems = vouchers.map(val => ({
      voucher: val,
      description: val.description
    }))
    // this.setState({ luckydraw: [...comboItems, ...voucherItems] })
    const res = await Store.Bill.createBill({
      userId: userInfo._id,
      userName: userInfo.name,
      phone: userInfo.phone,
      registerRegion: Store.User.userData.registerRegion,
      billMoney: this.state.money,
      afterVoucherMoney: this.afterVoucherMoney(),
      afterVoucherMoneyVAT: this.afterVoucherMoneyVAT(),
      buffetValue: !!buffet ? buffet.value : undefined,
      numberOfGuess,
      numberOfCombo,
      vouchers,
      items: [...comboItems, ...voucherItems],
      soCheck
    });
    if (res.success) {
      this.setState({
        luckydraw: res.data.luckyCode.map(val => ({ code: val.code, description: val.description })),
        didCreateBill: true,
        billCode: res.data.billCode,
      })
    }
  }

  renderVoucher = () => {
    const { userInfo } = this.state;
    if (!userInfo.vouchers) return null;
    const titles = ["Voucher", "Trạng thái, hiệu lực", ""];
    const rows = userInfo.vouchers.map(val => {
      return [
        val._id,
        <p>{val.code}<br /><strong className="text-danger">{VoucherHelper.getDes(val.type)}</strong></p>,
        <p>{VoucherHelper.getStatus(val.status)}<br />{TimeHelper.changeFormat(undefined, val.startDate)}</p>,
        val.status === "USED" ? null :
        <Button bsStyle="success" fill disabled={!TimeHelper.isAfter(val.startDate)} onClick={() => this.useVoucher(val)}>
          Sử dụng
        </Button>
      ];
    }) ;
    return (
      <Row>
        <Col md={12}>
          <Card
            title="Danh sách Voucher"
            ctTableFullWidth
            ctTableResponsive
            content={
              <Table striped hover>
                <thead>
                  <tr>
                    {titles.map((prop, key) => {
                      return <th key={key}>{prop}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {rows.map((prop, key) => {
                    return (
                      <tr key={key}>
                        {prop.map((val, i) => {
                          if (i === 0) return null;
                          return <td key={i}>{val}</td>;
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            }
          />
        </Col>
      </Row>
    )
  }

  render() {
    const combos = new Array(+this.state.numberOfCombo).fill({ description: 'Combo' });
    return (
      <Container>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={6} id="voucher-check">
                <Card
                  title="Thông tin xuất hóa đơn"
                  category="Kiểm tra voucher, nhập thông tin thanh toán, in hóa đơn"
                  content={
                    <div>
                      <ControlLabel>Tra cứu thông tin khách hàng</ControlLabel>
                      <Row style={{ marginBottom: 25 }}>
                        <Col md={6}>
                          <FormControl
                            type="text"
                            bsClass="form-control"
                            placeholder="Nhập SDT để kiểm tra"
                            name="phone-of-guess"
                            value={this.state.phoneInput}
                            onChange={e => this.setState({ phoneInput: e.target.value })}
                          />
                        </Col>
                        <Col md={6}>
                          <Button bsStyle="info" fill onClick={this.checkUser}>
                            Kiểm tra SDT
                          </Button>
                        </Col>
                      </Row>
                      <ControlLabel>Tra cứu Voucher khách hàng cung cấp</ControlLabel>
                      <Row style={{ marginBottom: 25 }}>
                        <Col md={6}>
                          <FormControl
                            type="text"
                            bsClass="form-control"
                            placeholder="Nhập Voucher để kiểm tra"
                            name="phone-of-guess"
                            value={this.state.voucherInput}
                            onChange={e => this.setState({ voucherInput: e.target.value })}
                          />
                        </Col>
                        <Col md={6}>
                          <Button bsStyle="info" fill onClick={this.checkUserViaVoucher}>
                            Kiểm tra Voucher
                          </Button>
                        </Col>
                      </Row>
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Tên khách",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.name,
                            readOnly: true,
                          },
                          {
                            label: "SDT",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.userInfo.phone,
                            readOnly: true,
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Số lượng khách",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.numberOfGuess,
                            onChange: (e) => this.setState({ numberOfGuess: e.target.value }),
                            // readOnly: Store.User.userData.registerRegion === 'Bắc',
                          },
                          {
                            label: "Số COMBO sử dụng",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.numberOfCombo,
                            onChange: (e) => this.setState({ numberOfCombo: e.target.value }),
                            readOnly: Store.User.userData.registerRegion === 'Bắc',
                          },
                        ]}
                      />
                      <Row>
                        <Col md={12}>
                          <FormGroup>
                            <ControlLabel>Suất Buffet</ControlLabel>
                            <Select
                              className="react-select primary"
                              classNamePrefix="react-select"
                              name="singleSelect"
                              value={this.state.buffet}
                              onChange={value =>
                                this.setState({ buffet: value })
                              }
                              options={[
                                { value: 149000, label: "149k" },
                                { value: 159000, label: "159k" },
                                { value: 169000, label: "169k" },
                                { value: 189000, label: "189k" },
                                { value: 199000, label: "199k" },
                                { value: 229000, label: "229k" },
                                { value: 269000, label: "269k" },
                              ]}
                              placeholder="Khách sử dụng suất Buffet nào?"
                              isDisabled={Store.User.userData.registerRegion === 'Bắc'}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Tiền hóa đơn (Chưa VAT)",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.money,
                            onChange: e => this.setState({ money: e.target.value })
                          },
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Số check",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.soCheck,
                            onChange: e => this.setState({ soCheck: e.target.value })
                          },
                        ]}
                      />

                      {this.renderVoucher()}

                      <ControlLabel>Số lượng mã bốc thăm khách hàng được nhận: <strong style={{ fontSize: 20 }}>{+this.state.vouchers.length + +this.state.numberOfCombo}</strong></ControlLabel>
                      <Row style={{ marginBottom: 25 }}>
                        {combos.map((val, i) => this.renderLuckyCard(val, i, 'combo'))}
                        {this.state.vouchers.map((val, i) => this.renderLuckyCard(val, i, 'voucher'))}
                      </Row>
                      {Store.User.userData.registerRegion === 'Bắc' &&
                      <p>Vói các mã được tặng từ việc mua combo, hãy hướng dẫn khách hàng sử dụng thẻ cào để lấy. Hệ thống chỉ hỗ trợ tặng mã bốc thăm ngay lập tức với Voucher</p>}
                      <Button bsStyle={this.state.didCreateBill ? "success" : "danger"} fill onClick={this.state.didCreateBill ? () => window.location.reload() : this.getLuckyDraw}>
                        {this.state.didCreateBill ? 'Tạo hóa đơn mới' : 'Tạo hóa đơn và Lấy mã bốc thăm'}
                      </Button>
                      <div className="clearfix" />
                    </div>
                  }
                />
              </Col>
              <Col md={6} id="preview-invoice">
                <Card
                  title="Xem trước hóa đơn"
                  content={
                    <div className="invoice-box-container">
                      <Invoice
                        name={this.state.userInfo.name}
                        phone={this.state.userInfo.phone}
                        money={this.state.money}
                        vouchers={this.state.vouchers}
                        items={this.state.luckydraw}
                        // billCode={this.state.billCode}
                        numberOfGuess={this.state.numberOfGuess}
                        soCheck={this.state.soCheck}
                      />

                      <Button bsStyle="danger" fill style={{ marginTop: 25 }} onClick={() => window.print()}>
                        In hóa đơn
                      </Button>
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default VoucherCheck;
