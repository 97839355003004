import React, { Component } from 'react';
import XLSX from 'xlsx';

export default class ExportExcel extends Component {

  state = {
    isLoading: false,
  }

  exportExcel = async () => {
    if (this.state.isLoading) return;
    this.setState({ isLoading: true })
    const { getListAll, filterData, name } = this.props;
    if (typeof getListAll !== 'function' || typeof filterData !== 'function') {
      window.notify.error('Có lỗi xảy ra');
      return this.setState({ isLoading: false });
    }

    const res = await getListAll();
    if (res.success) {

      let duLieu = filterData(res.data, true);
      let filename = name || "exported_excel.xlsx";
      var ws_name = "SheetJS";
      var wb = XLSX.utils.book_new(), ws = XLSX.utils.aoa_to_sheet(duLieu);

      /* add worksheet to workbook */
      XLSX.utils.book_append_sheet(wb, ws, ws_name);

      /* write workbook */
      XLSX.writeFile(wb, filename);

      this.setState({ isLoading: false });
    } else {
      window.notify.error(res.message);
      this.setState({ isLoading: false });
    }
  }

  render() {
    const { isLoading } = this.state
    return (
      <p onClick={this.exportExcel} className="cursor text-danger" style={{ textAlign: 'right' }}><i className="fa fa-download" /> {isLoading ? 'Vui lòng đợi ...' : 'Xuất Excel'}</p>
    );
  }
}