import StoreClass from './_StoreClass';
import Request from './_Request';
import moment from 'moment';
import { observable, autorun } from 'mobx';

const CountType = {
  USER_B: 'USER_B',
  USER_N: 'USER_N',

  VOUCHER_B: 'VOUCHER_B',
  VOUCHER_B_K100: 'VOUCHER_B_K100',
  VOUCHER_B_K50: 'VOUCHER_B_K50',
  VOUCHER_B_P3: 'VOUCHER_B_P3',
  VOUCHER_N: 'VOUCHER_N',

  VOUCHER_N_K100: 'VOUCHER_N_K100',
  VOUCHER_N_K50: 'VOUCHER_N_K50',
  VOUCHER_N_P3: 'VOUCHER_N_P3',
  LUCKYDRAW_B: 'LUCKYDRAW_B',
  LUCKYDRAW_N: 'LUCKYDRAW_N',
  
  BILL_B: 'BILL_B',
  BILL_N: 'BILL_N',

  CGV_B: 'CGV_B',
  CGV_N: 'CGV_N',
};

const VoucherTypes = {
  K100: 'K100',
  K50: 'K50',
  P3: 'P3',
}

class Count extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable counts = [];

  @observable userStepStats = {
    step1: 0,
    step2: 0,
    step3: 0,
  };

  @observable userWeekly = { bac: [],  nam: [] };

  @observable luckydrawWeekly = { bac: [],  nam: [] };

  @observable voucherMoneyMienNam = { voucherUsed: 0, totalMoney: 0, totalMoneyVoucher: 0 };
  @observable voucherMoneyMienBac = { voucherUsed: 0, totalMoney: 0, totalMoneyVoucher: 0 };

  getCountAll = async () => {
    const res = await Request.get('/counts', {});
    if (res.data.success) {
      this.counts = res.data.data;
    }
  }

  getCountUser = (region) => {
    const type = region === 'Bắc' ? CountType.USER_B : CountType.USER_N;
    const thisRegion = this.counts.find(val => val.type === type);
    return !!thisRegion ? thisRegion.count : 0;
  }

  getCountVoucher = (region) => {
    const type = region === 'Bắc' ? CountType.VOUCHER_B : CountType.VOUCHER_N;
    const thisRegion = this.counts.find(val => val.type === type);
    return !!thisRegion ? thisRegion.count : 0;
  }

  getCountVoucherByType = (region, voucherType) => {
    let type = (() => {
      switch (voucherType) {
        case VoucherTypes.K100: return region === 'Bắc' ? CountType.VOUCHER_B_K100 : CountType.VOUCHER_N_K100;
        case VoucherTypes.K50: return region === 'Bắc' ? CountType.VOUCHER_B_K50 : CountType.VOUCHER_N_K50;
        case VoucherTypes.P3: return region === 'Bắc' ? CountType.VOUCHER_B_P3 : CountType.VOUCHER_N_P3;
      }
    })();
    const thisRegion = this.counts.find(val => val.type === type);
    console.log(type, thisRegion);
    return !!thisRegion ? thisRegion.count : 0;
  }

  getCountLuckyDraw = (region) => {
    const type = region === 'Bắc' ? CountType.LUCKYDRAW_B : CountType.LUCKYDRAW_N;
    const thisRegion = this.counts.find(val => val.type === type);
    return !!thisRegion ? thisRegion.count : 0;
  }

  getCountBill = (region) => {
    const type = region === 'Bắc' ? CountType.BILL_B : CountType.BILL_N;
    const thisRegion = this.counts.find(val => val.type === type);
    return !!thisRegion ? thisRegion.count : 0;
  }

  getCountCGV = (region) => {
    const type = region === 'Bắc' ? CountType.CGV_B : CountType.CGV_N;
    const thisRegion = this.counts.find(val => val.type === type);
    return !!thisRegion ? thisRegion.count : 0;
  }

  getUserStepStats = async () => {
    if (!!this.userStepStats.step1 || !!this.userStepStats.step2 || !!this.userStepStats.step3) return;
    const res = await Request.get('/counts/user-step-stats', {});
    if (res.data.success) {
      const { all, voucherk50, voucherk100, voucherp3 } = res.data.data;
      const step1 = Math.floor((voucherk50 / all) * 100);
      const step2 = Math.floor((voucherk100 / all) * 100);
      const step3 = Math.floor((voucherp3 / all) * 100);
      this.userStepStats = {
        step1, step2, step3
      }
    }
  }

  _convertNum = (num) => (num).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})

  getUserStatsWeekly = async () => {
    if (this.userWeekly.nam.length > 0 || this.userWeekly.bac.length > 0) return;
    const res = await Request.get('/counts/user-weekly', {});
    if (res.data.success) {
      const getUserWeekly = (key) => new Array(7).fill(1).map((val, i) => {
        const month = moment().subtract(6 - i, 'days').month() + 1;
        const dayOfMonth = moment().subtract(6 - i, 'days').date();
        const findData = res.data.data[key].find(d => d._id.month === month && d._id.dayOfMonth === dayOfMonth);
        return { date: `${this._convertNum(dayOfMonth)}/${this._convertNum(month)}`, count: !!findData ? findData.count : 0 }
      });
      this.userWeekly = {
        bac: getUserWeekly('bac'),
        nam: getUserWeekly('nam'),
      }
    }
  }

  getLuckydrawStatsWeekly = async () => {
    if (this.luckydrawWeekly.nam.length > 0 || this.luckydrawWeekly.bac.length > 0) return;
    const res = await Request.get('/counts/luckydraw-weekly', {});
    if (res.data.success) {
      const getLuckyDrawWeekly = (key) => new Array(7).fill(1).map((val, i) => {
        const month = moment().subtract(6 - i, 'days').month() + 1;
        const dayOfMonth = moment().subtract(6 - i, 'days').date();
        const findData = res.data.data[key].find(d => d._id.month === month && d._id.dayOfMonth === dayOfMonth);
        return { date: `${this._convertNum(dayOfMonth)}/${this._convertNum(month)}`, count: !!findData ? findData.count : 0 }
      });
      this.luckydrawWeekly = {
        bac: getLuckyDrawWeekly('bac'),
        nam: getLuckyDrawWeekly('nam'),
      }
    }
  }

  getCountVoucherMoneyMienNam = async () => {
    if (!!this.voucherMoneyMienNam.voucherUsed || !!this.voucherMoneyMienNam.totalMoney) return this.voucherMoneyMienNam;
    const res = await Request.get('/counts/money-voucher-mien-nam', {});
    if (res.data.success) {
      this.voucherMoneyMienNam = {
        totalMoney: !!res.data.data.totalMoney[0] ? res.data.data.totalMoney[0].afterVoucherMoneyVAT : 0,
        voucherUsed: res.data.data.voucher,
        totalMoneyVoucher: !!res.data.data.totalMoneyVoucher[0] ? res.data.data.totalMoneyVoucher[0].afterVoucherMoneyVAT : 0,
      }
      return this.voucherMoneyMienNam;
    }
    return {};
  }

  getCountVoucherMoneyMienBac = async () => {
    if (!!this.voucherMoneyMienBac.voucherUsed || !!this.voucherMoneyMienBac.totalMoney) return this.voucherMoneyMienBac;
    const res = await Request.get('/counts/money-voucher-mien-bac', {});
    if (res.data.success) {
      this.voucherMoneyMienBac = {
        totalMoney: !!res.data.data.totalMoney[0] ? res.data.data.totalMoney[0].afterVoucherMoneyVAT : 0,
        voucherUsed: res.data.data.voucher,
        totalMoneyVoucher: !!res.data.data.totalMoneyVoucher[0] ? res.data.data.totalMoneyVoucher[0].afterVoucherMoneyVAT : 0,
      }
      return this.voucherMoneyMienBac;
    }
    return {};
  }
}

export default new Count();
