import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Table
} from "react-bootstrap";

import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import Container from "components/Misc/Container";
import CompState from "components/Misc/CompStateData";
import { Link } from 'react-router-dom';
import avatar from "assets/img/user.png";
import cover from "assets/img/user_cover.jpg";
import Store from "../store";
import { thArray, tdArray } from "variables/Variables.jsx";
import Select from "react-select";
import MoneyImage from "components/MoneyImage/MoneyImage"

const orderStatusOptions = [
  { label: 'Chưa xử lý', value: 'PENDING' },
  { label: 'Hoàn thành', value: 'PAID_AND_SHIPPED' },
  { label: 'Đã nhận tiền, chưa ship', value: 'PAID_AND_NOT_SHIPPED' },
  { label: 'Đang ship, chưa nhận tiền', value: 'SHIPPING_AND_NOT_PAID' },
  { label: 'Đang ship, đã nhận tiền', value: 'SHIPPING_AND_PAID' },
];

const currencyOptions = [
  { label: 'VND', value: 'VND' },
  { label: 'USD', value: 'USD' },
];

class SMoneyDetail extends Component {

  state = {
    notFound: false,
    seri: '',
    code: '',
    displayValue: '',
    salePrice: '',
    currency: '',
    tags: [],
    hashtag: '',
    selectOrderStatus: { label: 'Chưa xử lý', value: 'PENDING' },
    selectCurrency: { value: 'VND', label: 'VND' },

    id: 0,
    customerName: '',
    customerPhone: '',
    customerAddress: '',
    customerNote: '',
    carts: [],
    shippingFee: 0,
    status: '',
    paymentMethod: '',
  }

  dot = CompState.dot(this);

  onReady = async () => {
    console.log('here');
    const id = this.props.match.params.id;
    if (!id) return this.setState({ notFound: true })
    const res = await Store.Order.detail(id);
    if (res.success) {
      this.setState({
        ...res.data,
        selectOrderStatus: orderStatusOptions.find(val => val.value === res.data.status),
      });
    } else {
      window.notify.error(res.message);
      if (res.message === "NOT_EXISTED") this.setState({ notFound: true })
    }
  }

  updateOrder = async (e) => {
    e.preventDefault();
    const res = await Store.Order.update(this.state.id, {
      status: this.state.selectOrderStatus.value,
    });
    if (res.success) {
      window.notify.success('Cập nhật đơn hàng thành công');
      this.props.history.push("/admin/dashboard");
    } else {
      window.notify.error('Có lỗi xảy ra: ' + res.message);
    }
  }

  render() {
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Xem chi tiết đơn hàng"
                  category=""
                  content={
                    <form onSubmit={this.updateOrder}>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Tên khách hàng",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerName,
                            onChange: e => {},
                            readyOnly: true,
                          }
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Số điện thoại",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerPhone,
                            onChange: e => {},
                            readyOnly: true,
                          }
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Địa chỉ",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerAddress,
                            onChange: e => {},
                            readyOnly: true,
                          }
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Ghi chú của khách",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.customerNote,
                            onChange: e => {},
                            readyOnly: true,
                          }
                        ]}
                      />

                      <FormGroup>
                        <ControlLabel>Sản phẩm</ControlLabel>
                        <div>
                          {this.state.carts.map((p, i) => (
                            <ControlLabel>
                              {i !== 0 && <br />}
                              {p.displayValue} | {p.seri} | giá bán: {p.salePrice}
                            </ControlLabel>
                          ))}
                        </div>
                        <ControlLabel>Phí Ship: {this.state.shippingFee}</ControlLabel><br />
                        <ControlLabel>Tổng: {this.state.totalPrice}</ControlLabel>
                      </FormGroup>

                      <FormGroup>
                        <ControlLabel>Trạng thái đơn hàng</ControlLabel>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          name="singleSelect"
                          value={this.state.selectOrderStatus}
                          onChange={value =>
                            this.setState({ selectOrderStatus: value })
                          }
                          options={orderStatusOptions}
                          placeholder=""
                        />
                      </FormGroup>

                      <Button bsStyle="danger" pullRight fill type="submit">
                        Cập nhật
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default SMoneyDetail;
