import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Container from "components/Misc/Container";
import Request from '../../store/_Request';

class AddVideo extends Component {

  state = {
    name: '',
    url: '',
    thumb: '',
    isLoading: false,
  }

  createVideo = async (e) => {
    e.preventDefault();
    const { name, url, thumb } = this.state;
    if (!name || !url || !thumb) {
      return window.notify.error('Cần điền đủ thông tin');
    }
    const res = await Store.Video.createVideo({
      name, url, thumb,
    });
    if (res.success) {
      window.notify.success('Thêm thành công');
      this.props.history.push("/admin/dancing-clip-mau");
    } else {
      window.notify.error('Có lỗi xảy ra');
    }
  }

  uploadImage = async (e) => {
    try {
      this.setState({ isLoading: true });
      const file = e.target.files[0];
      const uploadedUrl = await Request.upload(file);
      this.setState({ thumb: uploadedUrl, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  uploadVideo = async (e) => {
    try {
      this.setState({ isLoading: true });
      const file = e.target.files[0];
      const uploadedUrl = await Request.upload(file);
      this.setState({ url: uploadedUrl, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  render() {
    return (
      <Container>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Thêm video mẫu"
                  content={
                    <form onSubmit={this.createVideo}>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Tên",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.name,
                            onChange: e => this.setState({ name: e.target.value }),
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Video",
                            customRender: () => this.state.url ? (
                              <div>
                                <video style={{ width: 500, height: 'auto' }} controls>
                                  <source src={this.state.url} type="video/mp4" />
                                </video>
                                <i className="fa fa-times" onClick={() => this.setState({ url: '' })} />
                              </div>
                            ) : (
                                <div>
                                  <Button bsStyle="danger" fill onClick={() => this.uploadVideoRef.click()} disabled={this.state.isLoading}>
                                    Upload Video
                                  </Button>
                                  <input type="file" ref={ref => this.uploadVideoRef = ref} style={{ display: 'none' }} onChange={this.uploadVideo} />
                                </div>
                              )
                          },
                        ]}
                      />

                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Ảnh Thumbnail Video",
                            customRender: () => this.state.thumb ? (
                              <div>
                                <img src={this.state.thumb} type="video/mp4" style={{ width: 500, height: 'auto' }} />
                                <i className="fa fa-times" onClick={() => this.setState({ thumb: '' })} />
                              </div>
                            ) : (
                                <div>
                                  <Button bsStyle="danger" fill onClick={() => this.uploadVideoRef.click()} disabled={this.state.isLoading}>
                                    Upload Thumbnail
                                  </Button>
                                  <input ref={ref => this.uploadVideoRef = ref} type="file" style={{ display: 'none' }} onChange={this.uploadImage} />
                                </div>
                              )
                          },
                        ]}
                      />

                      <Button bsStyle="danger" pullRight fill type="submit">
                        Thêm Video
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(AddVideo);
