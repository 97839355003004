import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

const CustomMap = withScriptjs(
  withGoogleMap(props => {
    return (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{ lat: 21.0278, lng: 105.8342 }}
        defaultOptions={{
          scrollwheel: false,
          zoomControl: true
        }}
        center={!!props.location ? props.location : { lat: 21.0278, lng: 105.8342 }}
      >
        <Marker position={props.location} />
      </GoogleMap>
    )
  })
);

export default CustomMap;