import React, { Component } from "react";
import { Grid } from "react-bootstrap";
import MessengerCustomerChat from 'react-messenger-customer-chat';

class Footer extends Component {
  render() {
    return (
      <footer className="footer">
        <Grid fluid>
          <nav className="pull-left">
            <ul>
              <li>
                <a href="https://www.facebook.com/tiennamsinhducanh" target="_blank">fb.com/tiennamsinhducanh</a>
              </li>
              {/* <li>
                <a href="#pablo">Company</a>
              </li>
              <li>
                <a href="#pablo">Portfolio</a>
              </li>
              <li>
                <a href="#pablo">Blog</a>
              </li> */}
            </ul>
          </nav>
          <p className="copyright pull-right">
            &copy; {new Date().getFullYear()}{" "}
            <a href="/admin/dashboard">
              Kho tiền năm sinh
            </a>
            , made with love for a better web
          </p>
          <MessengerCustomerChat
            pageId="411938035546369"
            appId="321756205822559"
            themeColor="#fb404b"
          />
        </Grid>
      </footer>
    );
  }
}

export default Footer;
