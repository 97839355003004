import User from './User';
import Voucher from './Voucher';
import Count from './Count';
import Bill from './Bill';
import Frame from './Frame';
import Thumbnail from './Thumbnail';
import Video from './Video';
import Card from './Card';
import CGVCode from './CGVCode';


import Order from './Order';
import SMoney from './SMoney';

const Store = {
  User,
  Voucher,
  Count,
  Bill,
  Frame,
  Thumbnail,
  Video,
  Card,
  CGVCode,


  Order,
  SMoney,
};

window.store = Store;

export default Store;