import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Voucher extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable listMienBac = [];
  @observable listMienNam = [];

  giveVoucherTest = async ({ user_id }) => {
    const res = await Request.post('/vouchers/giveTest', { user_id });
    return res.data;
  }

  getVouchers = async ({ registerRegion, status, type, code, userName, limit }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
      'filter[registerRegion]': registerRegion,
    };
    if (status) {
      defaultQuery['filter[status]'] = status;
    }
    if (type) {
      defaultQuery['filter[type]'] = type;
    }
    if (code) {
      defaultQuery['filter[code][$regex]'] = code;
    }
    if (userName) {
      defaultQuery['filter[userName][$regex]'] = userName;
    }
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    const url = Request.querify('/vouchers', defaultQuery);
    const res = await Request.post(url, { registerRegion });

    const shouldSaveToStore = !!limit ? limit <= 100 : true;
    if (res.data.success && shouldSaveToStore) {
      if (registerRegion === 'Bắc') this.listMienBac = res.data.data;
      if (registerRegion === 'Nam') this.listMienNam = res.data.data;
    }
    return res.data;
  }

  createSampleInstantVoucher = async () => {
    const res = await Request.post('/vouchers/createSampleInstantVoucher', { });
    return res.data;
  }

  getUserCGV = async (userId) => {
    const res = await Request.get('/cgv/get-user-cgv', { id: userId });
    return res.data;
  }
}

export default new Voucher();
