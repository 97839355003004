const TIME = {
  FORMAT: 'YYYY-MM-DD HH:mm:ss',
  TIMEZONE: 'Asia/Ho_Chi_Minh',
};

const moment = require('moment-timezone');

class TimeHelper {
  state = {
    runOnceInTimestamp: 0
  }

  FORMAT = TIME.FORMAT;

  runOnceInTimestamp;

  waitDelay = miliseconds => new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, miliseconds);
  });

  runOnceIn = miliseconds => func => {
    let newTimestamp = new Date().getTime();
    if(newTimestamp - this.state.runOnceInTimestamp > miliseconds) {
      this.runOnceInTimestamp = newTimestamp;
      if (typeof func === "function") return func();
    }
  }

  now = (format = TIME.FORMAT) => {
    return moment().tz(TIME.TIMEZONE).format(format);
  };

  nowAddDays = (days, format) => {
    return moment().add(days, 'days').tz(TIME.TIMEZONE).format(format || TIME.FORMAT);
  }

  addYear = (date, year, format) => {
    return moment(date, format).add(year, 'years').tz(TIME.TIMEZONE).format(format || TIME.FORMAT);
  }

  changeFormat = (format = this.FORMAT, timeString, customTimeStringFormat) => {
    return moment(timeString, customTimeStringFormat).tz(TIME.TIMEZONE).format(format);
  }

  yesterday = (format) => {
    return moment().subtract(1, 'day').format(format);
  }

  fromNow = (time, timeFormat = TIME.FORMAT) => {
    return moment(time, timeFormat).fromNow();
  }

  time = (time, format) => moment(time, format);

  toTime = (time) => {
    return moment().to(time);
  }

  timeLeft = (time, format) => {
    let now = moment();
    let deadline = moment(time, format);
    if (now.isAfter(deadline)) {
      // disable RSVP button here
      return 'Closed';
    } else {
      // enable RSVP button here
      // returns “in x hours”, “in x minutes”, “in a few seconds”
      return deadline.from(now).replace('in ', '') + ' ' + 'left';
    }
  }

  diff = time => {
    return moment().diff(time, 'days');
  }

  isAfter = time => {
    let now = moment();
    let deadline = moment(time);
    return now.isAfter(deadline);
  }

  isRecentDays = (time, days) => {
    const diffDays = this.diff(time);
    return diffDays >= 0 && days > diffDays;
  }

  isValid = (time, format) => {
    const foo = moment(time, format);
    return foo.isValid();
  }

  twoDigits = (num) => {
    if (num > 10) return num;
    return '0' + num;
  }

  
}

export default new TimeHelper();