import 'cross-fetch/polyfill';
import axios from 'axios';
import { HOST } from './_constants';
const qs = require('qs');
const GlobalEvent = require('js-events-listener');

class RequestHelper {

  _token

  setToken = (token) => {
    this._token = token;
  }

  getToken = () => {
    return this._token;
  }

  makeHeader = method => {
    let token = this.getToken();
    let headers = {};
    if(token != undefined) headers["Authorization"] = token;
    if(method == "POST") headers["Content-Type"] = "application/json";
    return headers;
  }

  querify = (url, queryObject) => {
    let newUrl = url;
    if(queryObject == undefined) return newUrl;
    newUrl += "?" + qs.stringify(queryObject);
    return newUrl;
  }

  querifyPath = (path, queryObject) => {
    let url = HOST + path;
    return this.querify(url, queryObject);
  }

  upload = async (file, returnAllObject = false) => {
    const url = HOST + '/upload';
    const formData = new FormData();
    formData.append('file',file);
    const config = {
      headers: {
          'content-type': 'multipart/form-data',
          'Authorization': this.getToken()
      }
    }
    const { data } = await axios.post(url, formData,config);
    // const uploadedUrl = HOST + data.url;
    const uploadedUrl = data.url;
    return returnAllObject ? data : uploadedUrl;
  }

  uploadApi = async (url, file, otherBody) => {
    const apiUrl = HOST + url;
    const formData = new FormData();
    formData.append('file',file);
    if (!!otherBody) {
      for (let key in otherBody) {
        formData.append(key, otherBody[key]);
      }
    }
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'Authorization': this.getToken()
      }
    }
    const { data } = await axios.post(apiUrl, formData,config);
    return data;
  }

  uploadOld = async (URL, file) => {
    const fileWithFilename = {
      ...file,
      filename: file.name
    }
    const data = new FormData();
    data.append('file', fileWithFilename);
    return fetch(URL, {
      method: "POST",
      headers: {
        ...this.makeHeader("GET"),
        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
      },
      body: data
    })
  }

  post_old = async (URL, bodyObject) => {
    return fetch(URL, {
      method: "POST",
      headers: this.makeHeader("POST"),
      body: JSON.stringify(bodyObject)
    })
  }

  get_old = async (URL, queryObject) => {
    const urlWithQuery = this.querify(URL, queryObject)
    return fetch(urlWithQuery, {
      method: "GET",
      headers: this.makeHeader("GET")
    })
  }

  get = async (URL, queryObject) => {
    const urlWithQuery = Object.keys(queryObject).length === 0 ? HOST + URL : this.querify(HOST + URL, queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'get',
      headers: this.makeHeader("GET"),
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }
  getNoHeader = async (URL, queryObject = {}) => {
    const urlWithQuery = this.querify(HOST + URL, queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'get',
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  delete = async (URL, queryObject) => {
    const urlWithQuery = this.querify(HOST + URL, queryObject);
    const res = await axios.request({
      url: urlWithQuery,
      method: 'delete',
      headers: this.makeHeader("GET"),
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  post = async (URL, bodyObject) => {
    const res = await axios.request({
      url: HOST + URL,
      method: 'post',
      headers: this.makeHeader("POST"),
      data: JSON.stringify(bodyObject)
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  put = async (URL, bodyObject) => {
    const res = await axios.request({
      url: HOST + URL,
      method: 'put',
      headers: this.makeHeader("POST"),
      data: JSON.stringify(bodyObject)
    });
    return {
      headers: res.headers,
      json: async () => res.data,
      text: async () => res.data,
      data: res.data,
    };
  }

  isValidResponse = (res) => {
    if (typeof res.data === 'object' && res.data.error === "NOT_AUTHEN" ) {
      GlobalEvent.emit('NOT_AUTHEN', res);
      return false;
    }
    return true;
  }

  _didHandleInvalidResponse = false;

  handleInvalidResponse = async (res) => {
    return res
  }

  notAuthenListener;

  onNotAuthen = (callback) => {
    this.notAuthenListener = GlobalEvent.on('NOT_AUTHEN', (res) => {
      typeof callback === 'function' && callback(res);
    });
  }

  removeNotAuthenListener = () => {
    GlobalEvent.on('NOT_AUTHEN', this.notAuthenListener);
  }
}

export default new RequestHelper();