import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"
import ExportExcel from "components/Paging/ExportExcel"
import Search from "components/Search/Search"

import { TimeHelper } from "../../helpers"

@observer
class ListTheCao extends Component {

  state = {
    activePage: 1,
    lastPage: 1,
    list: [],
  }

  onReady = async () => {
    this.getList();
  }

  getList = async () => {
    const { activePage, tempSearch } = this.state;
    if (!!tempSearch) return this.onSearch(tempSearch, activePage);
    const res = await Store.Card.getCards({ }, activePage);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.page,
        lastPage: res.pages,
        total: res.total,
      })
    }
  }

  getListAll = async () => {
    const { registerRegion } = this.props;
    const { tempSearch } = this.state;
    let obj = { registerRegion, limit: 1000000 };
    if (!!tempSearch) obj[tempSearch.key] = tempSearch.value;
    return Store.Card.getCards(obj, 1);
  }

  onSearch = async ({ key, value }, page = 1) => {
    const res = await Store.Card.getCards({ [key]: value }, page);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.page,
        lastPage: res.pages,
        total: res.total,
        tempSearch: { key, value }
      })
    }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.getList());
  }

  filterData = (list, forExport = false) => {
    const thArray = ["Mã", "Trạng thái", "Giờ kích hoạt", "Nhà hàng", "Số check", "Mã bốc thăm", "Thời gian cập nhật"];
    const tdArray = list.map(val => {
      return [
        val._id,
        val.code,
        val.used ? 'Đã sử dụng' : 'Chưa sử dụng',
        !!val.activeAt ? TimeHelper.changeFormat(undefined, val.activeAt) : "",
        val.address,
        !!val.soCheck ? val.soCheck : '',
        val.luckyDrawCode,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ]
    })
    return forExport ? [
      ["ID", ...thArray],
      ...tdArray,
    ] : {
      thArray, tdArray
    }
  }

  render() {
    const { list } = this.state;
    // const thArray = ["Mã", "Trạng thái", "Giờ kích hoạt", "Nhà hàng", "Số check", "Mã bốc thăm", "Thời gian cập nhật"];
    // const tdArray = list.map(val => {
    //   return [
    //     val._id,
    //     val.code,
    //     val.used ? 'Đã sử dụng' : 'Chưa sử dụng',
    //     !!val.activeAt ? TimeHelper.changeFormat(undefined, val.activeAt) : "",
    //     val.address,
    //     !!val.soCheck ? val.soCheck : '',
    //     val.luckyDrawCode,
    //     TimeHelper.changeFormat(undefined, val.updatedAt),
    //   ]
    // })
    const { thArray, tdArray } = this.filterData(list);
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/card/import">
                  <Button bsStyle="danger" block fill>
                    Tạo mã thẻ cào
                    </Button>
                </Link>
              </Col>
            </Row>
            <Search
              options={[
                { value: 'code', label: 'Mã Thẻ', type: 'text' },
                {
                  value: 'used', label: 'Trạng thái sử dụng', type: 'list', selectOptions: [
                    { value: 'true', label: 'Đã sử dụng' },
                    { value: 'false', label: 'Chưa sử dụng' },
                  ]
                },
              ]}
              onSearch={this.onSearch}
            />
            <Row>
              <Col md={12}>
                <Card
                  title={`Danh sách mã thẻ cào`}
                  category={`Phục vụ cho các cửa hàng miền Bắc gửi cho khách hàng để lấy mã lucky draw`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    {/* <Link to={"/admin/user-profile/" + prop[0]} style={{ color: 'black' }}> */}
                                      {val}
                                    {/* </Link> */}
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'right' }}>
                  <ExportExcel getListAll={this.getListAll} name="Card.xlsx" filterData={this.filterData} />
                  <Paging
                    last={this.state.lastPage || 1}
                    activePage={this.state.activePage}
                    onChangePage={this.onChangePage}
                    total={this.state.total || 0}
                  />
                </div>
                <div className="clearfix" />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(ListTheCao);