import React, { Component } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Container from "components/Misc/Container";
import Maps from "components/Maps/Maps";

const locationGetGuide =
  `B1: Truy cập https://maps.google.com
B2: Nhập địa chỉ chi tiết của nhà hàng để tìm trên google map
B3: Kéo map sao cho vị trí nhà hàng ở chính giữa
B4: Nhìn lên địa chỉ URL, sẽ thấy tọa độ cần lấy
VD: https://www.google.com/maps/@21.0098476,105.7695493,15z -> lat: 21.0098476 và long: 105.7695493
`;



class RestaurantCreate extends Component {

  state = {
    email: '',
    password: '',
    address: '',
    lat: '',
    lng: '',
    mienBac: false,
    mienNam: false,
    phone: '',
    name: '',
  }

  createRestaurant = async (e) => {
    e.preventDefault();
    const { name, phone, email, password, address, lat, lng, mienBac, mienNam } = this.state;
    if (!email || !password || !address || !lat || !lng || !name || !phone) {
      return window.notify.error('Cần điền đủ thông tin');
    }
    if (!mienBac && !mienNam) {
      return window.notify.error('Cần chọn miền Bắc hoặc miền Nam');
    }
    const res = await Store.User.createRestaurant({
      phone, name, email, password, address, lat, lng, registerRegion: mienBac ? 'Bắc' : 'Nam',
    })
    if (res.success) {
      window.notify.success('Tạo tài khoản nhà hàng thành công');
      this.props.history.push("/admin/restaurant");
    } else {
      window.notify.error('Có lỗi xảy ra');
    }
  }

  onChangeRegion = (stateLabel) => {
    this.setState({
      mienBac: false,
      mienNam: false,
      [stateLabel]: true,
    })
  }

  render() {
    return (
      <Container>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Tạo tài khoản nhà hàng"
                  category="Mỗi nhà hàng chỉ có 1 tài khoản để sử dụng"
                  content={
                    <form onSubmit={this.createRestaurant} autoComplete="off">
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Tên đăng nhập",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "VD ten_ko_dau@kichi.com",
                            value: this.state.email,
                            onChange: e => this.setState({ email: e.target.value }),
                          },
                          {
                            label: "Mật khẩu",
                            type: "password",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.password,
                            onChange: e => this.setState({ password: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Tên nhà hàng (hiển thị trên microsite)",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Tên có dấu",
                            value: this.state.name,
                            onChange: e => this.setState({ name: e.target.value }),
                          },
                          {
                            label: "Số điện thoại",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.phone,
                            onChange: e => this.setState({ phone: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Địa chỉ đầy đủ",
                            type: "text",
                            bsClass: "form-control",
                            placeholder: "Điền bao gồm quận, huyện, tỉnh thành",
                            value: this.state.address,
                            onChange: e => this.setState({ address: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-6", "col-md-6"]}
                        properties={[
                          {
                            label: "Tọa độ - Latitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.lat,
                            onChange: e => this.setState({ lat: e.target.value }),
                          },
                          {
                            label: "Tọa độ - Longtitude",
                            type: "number",
                            bsClass: "form-control",
                            placeholder: "",
                            value: this.state.lng,
                            onChange: e => this.setState({ lng: e.target.value }),
                          },
                        ]}
                      />
                      <Row>
                        <Col md={12} style={{ height: 200 }}>
                          <Maps
                            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBkTHYX9Mh_AGgb88JMWwNjWknHs3IjCII"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div style={{ height: `100%` }} />}
                            mapElement={<div style={{ height: `100%` }} />}
                            location={!!this.state.lat && !!this.state.lng ? {
                              lat: +this.state.lat,
                              lng: +this.state.lng,
                            } : undefined}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormGroup controlId="formControlsTextarea">
                            <ControlLabel>Hướng dẫn lấy tọa độ</ControlLabel>
                            <FormControl
                              value={locationGetGuide}
                              rows="7"
                              componentClass="textarea"
                              bsClass="form-control"
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Checkbox
                        number={1}
                        isChecked={this.state.mienBac}
                        label="Miền Bắc"
                        onChange={() => this.onChangeRegion('mienBac')}
                        usePropChecked
                      />
                      <Checkbox
                        number={2}
                        isChecked={this.state.mienNam}
                        label="Miền Nam"
                        onChange={() => this.onChangeRegion('mienNam')}
                        usePropChecked
                      />
                      <Button bsStyle="danger" pullRight fill type="submit">
                        Tạo tài khoản
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(RestaurantCreate);
