import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

class Thumbnail extends StoreClass {

  constructor() {
    super();
    // this.loadInitialData();
  }

  @observable thumbs = [];

  getThumbs = async () => {
    const res = await Request.get('/thumbs', {});
    if (res.data.success) {
      this.thumbs = res.data.data;
    }
    return res.data;
  }

  getThumbDetail = async (id) => {
    const res = await Request.get('/thumbs/'+id, {});
    return res.data;
  }

  createThumb = async ({ preview, matchUrl, description, layers }) => {
    const res = await Request.post('/thumbs', { preview, matchUrl, description, layers });
    return res.data;
  }

  removeThumb = async (id) => {
    const res = await Request.post('/thumbs/delete', { id });
    return res.data;
  }

  updateCurrentThumb = async (id, { preview, matchUrl, description, layers }) => {
    const res = await Request.post('/thumbs/update-current/'+id, { preview, matchUrl, description, layers });
    return res.data;
  }
}

export default new Thumbnail();
