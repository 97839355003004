import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"
import ExportExcel from "components/Paging/ExportExcel"
import Search from "components/Search/Search"
import { TimeHelper } from "../../helpers"

@observer
class ListCGV extends Component {

  state = {
    activePage: 1,
    lastPage: 1,
    list: [],
  }

  onReady = async () => {
    this.getList();
  }

  getList = async () => {
    const { activePage, tempSearch } = this.state;
    if (!!tempSearch) return this.onSearch(tempSearch, activePage);
    const res = await Store.CGVCode.getCGVCodes({}, activePage);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.page,
        lastPage: res.pages,
        total: res.total,
      })
    }
  }

  getListAll = async () => {
    const { registerRegion } = this.props;
    const { tempSearch } = this.state;
    let obj = { registerRegion, limit: 1000000 };
    if (!!tempSearch) obj[tempSearch.key] = tempSearch.value;
    return Store.CGVCode.getCGVCodes(obj, 1);
  }

  onSearch = async ({ key, value }, page = 1) => {
    const { registerRegion } = this.props;
    const res = await Store.CGVCode.getCGVCodes({ registerRegion, [key]: value }, page);
    if (res.success) {
      this.setState({
        list: res.data,
        activePage: res.page,
        lastPage: res.pages,
        total: res.total,
        tempSearch: { key, value }
      })
    }
  }

  onChangePage = async page => {
    this.setState({ activePage: page }, () => this.getList());
  }

  markUsed = async (val, i) => {
    const shouldDo = window.confirm("Bạn có chắc chắn muốn đổi trạng thái của mã này thành ĐÃ SỬ DỤNG? Một khi đổi bạn sẽ không đổi được lại");
    if (!shouldDo) return;
    const res = await Store.CGVCode.markUsed(val._id);
    if (res.success) {
      let { list } = this.state;
      list[i].used = true;
      this.setState({ list });
    } else {
      window.notify.error(res.message)
    }
  }

  filterData = (list, forExport = false) => {
    const thArray = ["Số thứ tự", "Site", "Item", "Serial No.", "Pin No.", "Trạng thái", "Người chơi", "Thời gian cập nhật", ""];
    const tdArray = list.map((val, i) => {
      return [
        val._id,
        !!val.user ? val.user.id : "",
        val.no,
        val.site,
        val.name,
        val.code,
        val.pin,
        val.used ? 'Đã sử dụng' : 'Chưa sử dụng',
        !!val.user ? val.user.name : "",
        TimeHelper.changeFormat(undefined, val.updatedAt),
        val.used ? "" : <Button onClick={() => this.markUsed(val, i)}>Đánh dấu đã dùng</Button>
      ]
    })
    return forExport ? [
      ["ID", "USER ID", ...thArray],
      ...tdArray,
    ] : {
      thArray, tdArray
    }
  }

  render() {
    const { list } = this.state;
    // const thArray = ["Số thứ tự", "Site", "Item", "Serial No.", "Pin No.", "Trạng thái", "Người chơi", "Thời gian cập nhật", ""];
    // const tdArray = list.map((val, i) => {
    //   return [
    //     val._id,
    //     !!val.user ? val.user.id : "",
    //     val.no,
    //     val.site,
    //     val.name,
    //     val.code,
    //     val.pin,
    //     val.used ? 'Đã sử dụng' : 'Chưa sử dụng',
    //     !!val.user ? val.user.name : "",
    //     TimeHelper.changeFormat(undefined, val.updatedAt),
    //     val.used ? "" : <Button onClick={() => this.markUsed(val, i)}>Đánh dấu đã dùng</Button>
    //   ]
    // })
    const { thArray, tdArray } = this.filterData(list);
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/cgv-code/import">
                  <Button bsStyle="danger" block fill>
                    Nhập mã CGV
                  </Button>
                </Link>
              </Col>
            </Row>
            <Search
              options={[
                { value: 'code', label: 'Mã Serial', type: 'text' },
                { value: 'pin', label: 'Mã Pin', type: 'text' },
                {
                  value: 'used', label: 'Trạng thái sử dụng', type: 'list', selectOptions: [
                    { value: 'true', label: 'Đã sử dụng' },
                    { value: 'false', label: 'Chưa sử dụng' },
                  ]
                },
              ]}
              onSearch={this.onSearch}
            />
            <Row>
              <Col md={12}>
                <Card
                  title={`Danh sách mã CGV`}
                  category={`Phục vụ việc tặng mã CGV khi khách hàng mua 2 combo trở lên`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                if (index === 1) return null;
                                return (
                                  <td key={index}>
                                    {index === 8 && !!val ? (
                                      <Link to={"/admin/user-profile/" + prop[1]} style={{ color: 'black' }}>
                                        {val}
                                      </Link>
                                    ) : val}
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <div style={{ float: 'right' }}>
                  <ExportExcel getListAll={this.getListAll} name="CGV.xlsx" filterData={this.filterData} />
                  <Paging
                    last={this.state.lastPage || 1}
                    activePage={this.state.activePage}
                    onChangePage={this.onChangePage}
                    total={this.state.total || 0}
                  />
                </div>
                <div className="clearfix" />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(ListCGV);