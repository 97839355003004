import StoreClass from './_StoreClass';
import Request from './_Request';

import { observable, autorun } from 'mobx';

const SHEET_ID = '1kFpgr2h_7jGTlJWCNkt8mJeG0kfzq9DdUoP59FmlUxQ';

class SMoney extends StoreClass {

  constructor() {
    super();
    this.fetchMoneyType();
  }

  @observable list = [];

  moneyType = [];

  getSMoney = async ({ limit, seri, code, tags, displayValue }, page = 1) => {
    const defaultQuery = {
      'sort[updatedAt]': -1,
      'page[number]': page,
    };
    if (limit) {
      defaultQuery['limit'] = limit;
    }
    if (seri) {
      defaultQuery['filter[seri][$regex]'] = seri;
    }
    if (code) {
      defaultQuery['filter[code]'] = code;
    }
    if (tags) {
      defaultQuery['filter[tags][$regex]'] = tags;
    }
    if (displayValue) {
      defaultQuery['filter[displayValue]'] = displayValue;
    }
    const url = Request.querify('/smoneys', defaultQuery);
    const res = await Request.get(url, {});
    return res.data;
  }

  create = async (data) => {
    const res = await Request.post('/smoneys/create', data);
    return res.data;
  }

  update = async (id, data) => {
    const res = await Request.put(`/smoneys/${id}/update`, data);
    return res.data;
  }

  detail = async (id) => {
    const res = await Request.get(`/smoneys/${id}/detail`, {});
    return res.data;
  }

  lookupDOB = async ({ date, year, month }) => {
    const res = await Request.post('/smoneys/lookup-dob', { date, year, month });
    return res.data;
  }

  importSMoney = async (file, yearFirstTwoDigits) => {
    const res = await Request.uploadApi('/smoneys/import', file, { yearFirstTwoDigits });
    return res;
  }

  fetchMoneyType = async () => {
    const url = `https://khotiensinhnhat.github.io/dulieu-quy-uoc.json?t=${new Date().getTime()}`;
    const res = await fetch(url);
    const json = await res.json();
    this.moneyType = json;
  }

  searchAndParseGSheet = async (month) => {
    const url = `https://khotiensinhnhat.github.io/dulieu-thang-${month}.json?t=${new Date().getTime()}`;
    const res = await fetch(url);
    const json = await res.json();

    return this.withSort(json);
  }

  searchAndParseGSheetYearInPair = async () => {
    const url = `https://khotiensinhnhat.github.io/dulieu-cap-doi.json?t=${new Date().getTime()}`;
    const res = await fetch(url);
    const json = await res.json();
    return this.withSort(json);
  }

  withSort = (sMoneyArray) => {
    // const order = ['2', '1', '500000', '200000', '100000', '50000', '20000', '10000', '5000', '500', '2000', '1000', '100', '200'];
    const order = this.moneyType.map(val => val.short);
    const sortedList = sMoneyArray.sort((a, b) => {
      const indexA = order.indexOf(a.short);
      const indexB = order.indexOf(b.short);
      return indexA < indexB ? -1 : 1;
    });
    return sortedList;
  }

  // withPrice = sMoneyArray => sMoneyArray.map(val => ({
  //   ...val,
  //   salePrice: (() => {
  //     if (['500', '1000', '2000', '5000'].includes(val.displayValue)) return 100000;
  //     if (['1','100', '200', '10000', '20000'].includes(val.displayValue)) return 150000;
  //     if (['50000'].includes(val.displayValue)) return 200000;
  //     if (['2', '100000'].includes(val.displayValue)) return 250000;
  //     if (['200000'].includes(val.displayValue)) return 400000;
  //     return 0;
  //   })(),
  // }))

  // withPriceInPair = sMoneyArray => sMoneyArray.map(val => ({
  //   ...val,
  //   salePrice: (() => {
  //     if (['500', '1000', '2000', '5000'].includes(val.displayValue)) return 100000;
  //     if (['1','100', '200', '10000', '20000'].includes(val.displayValue)) return 150000;
  //     if (['50000'].includes(val.displayValue)) return 200000;
  //     if (['2', '100000'].includes(val.displayValue)) return 250000;
  //     if (['200000'].includes(val.displayValue)) return 400000;
  //     return 0;
  //   })(),
  // }))
}

export default new SMoney();
