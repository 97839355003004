import React, { Component, Fragment } from "react";
import {
  Grid,
  Row,
  Col,
  FormGroup,
  ControlLabel,
  FormControl,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { withRouter } from 'react-router-dom'
import { Card } from "components/Card/Card.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import { UserCard } from "components/UserCard/UserCard.jsx";
import Button from "components/CustomButton/CustomButton.jsx";
import RedirectWhenNotAuthen from "components/Misc/RedirectWhenNotAuthen";
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import Store from "../../store";
import Request from "../../store/_Request";
import Container from "components/Misc/Container";
import Select from 'react-select'
import CompState from "components/Misc/CompStateData";
import arrayMove from 'array-move';
import ReactResizeDetector from 'react-resize-detector';

import threeFlatsLayerImg from '../../assets/img/three_flat__layers.png';

const LAYER_TYPE = {
  BACKGROUND: 'BACKGROUND',
  AVATAR_SQUARE: 'AVATAR_SQUARE',
  AVATAR_CIRCLE: 'AVATAR_CIRCLE',
}

const BG = {
  BG1: "https://firebasestorage.googleapis.com/v0/b/happy-cow-90840.appspot.com/o/files%2F1562788891580-Master Trúng Lớn (FA)-17.png?alt=media",
}

const preBGs = [
  { value: BG.BG1, label: 'Background 1' },
  { value: 'UPLOAD', label: 'Upload ảnh mới' },
]

class WebThumbnailCreate extends Component {

  state = {
    description: '',
    matchUrl: '',
    dummyUserAvatar: 'https://randomuser.me/api/portraits/men/97.jpg',
    layers_temp: {
      type: undefined,
      subType: undefined,
      data: {},
    },
    layers: [],
    frame: '',
  }

  dot = CompState.dot(this);

  onReady = () => {
    this.getDummyAvt();
    this.getCurrentFrame();
  }

  getCurrentFrame = async () => {
    await Store.Frame.getFrames();
    let framePublic = Store.Frame.frames.slice().filter(val => val.public);
    const activeUrl = framePublic.length > 0 ? framePublic[0].url : '';
    this.setState({ frame: activeUrl })
  }

  getDummyAvt = async () => {
    const res = await fetch('https://randomuser.me/api/');
    const json = await res.json();
    try {
      this.setState({
        dummyUserAvatar: json.results[0].picture.large || this.state.dummyUserAvatar,
      })
    } catch (err) {

    }
  }

  uploadImage = async (e) => {
    try {
      this.setState({ isLoading: true });
      const file = e.target.files[0];
      // console.log('file', file);
      const uploadedUrl = await Request.upload(file);
      // this.setState({ tempUrl: uploadedUrl });
      this.dot.setValue('layers_temp.data.background', uploadedUrl);
    } catch (err) {
      console.log(err)
    }
  }

  uploadImagePreview = async (e) => {
    try {
      this.setState({ isLoading: true });
      const file = e.target.files[0];
      // console.log('file', file);
      const uploadedUrl = await Request.upload(file);
      // this.setState({ tempUrl: uploadedUrl });
      this.dot.setValue('preview', uploadedUrl);
    } catch (err) {
      console.log(err)
    }
  }

  createThumb = async (e) => {
    e.preventDefault();
    const { layers, preview, description, matchUrl } = this.state;
    if (!description || !preview) {
      return window.notify.error('Cần điền thông tin về ảnh preview và description');
    }
    if (layers.length === 0) {
      return window.notify.error('Cần có ít nhất 1 layer');
    }
    const res = await Store.Thumbnail.createThumb({
      layers, preview, description, matchUrl
    })
    if (res.success) {
      window.notify.success('Tạo thành công');
      this.props.history.push("/admin/thumbnail");
    } else {
      window.notify.error('Có lỗi xảy ra');
    }
  }

  saveTempLayer = () => {
    let { layers_temp, layers } = this.state;
    const useAvatar = !!layers_temp.type ? (layers_temp.type.value !== LAYER_TYPE.BACKGROUND) : false;
    layers.push(
      {
        type: layers_temp.type,
        background: !!layers_temp.data.background ? layers_temp.data.background : '',
        useAvatar,
        avatar: useAvatar ? {
          circleAvatar: !!layers_temp.data.isCircle ? layers_temp.data.isCircle : false,
          top: layers_temp.data.top,
          left: layers_temp.data.left,
          size: layers_temp.data.size,
          includeFrame: Boolean(layers_temp.data.includeFrame),
        } : {}
      },
    );

    this.setState({
      layers,
      layers_temp: {
        type: null,
        subType: null,
        data: {},
      },
    })
  }

  renderImage = ({top, left, size, url, useCircle, includeFrame}) => {
    const { frame } = this.state;
    const styleRadius = !useCircle ? {  } : { borderRadius: '100%', overflow: 'hidden' };
    return (
      <div style={{ position: 'absolute', top, left, width: size, height: size, ...styleRadius }}>
        {!!url && <img src={url} style={{ position: 'absolute', width: size, height: size, objectFit: 'cover', ...styleRadius}} />}
        {includeFrame && <img src={frame} style={{ position: 'absolute', width: size, height: size, objectFit: 'cover', ...styleRadius}} />}
      </div>
    )
  }

  renderThumbnailLayers = () => {
    const { layers_temp, layers, dummyUserAvatar, preview } = this.state;
    return (
      <Fragment>
        <Row>
          <Col md={3}>
            <Select
              className="react-select primary"
              classNamePrefix="react-select"
              name="singleSelect"
              value={layers_temp.type}
              onChange={value => {
                this.dot.setValue('layers_temp.type', value);
                // if (!!value && value.value === LAYER_TYPE.BACKGROUND) {
                //   this.input.click()
                // }
              }}
              options={[
                { value: LAYER_TYPE.BACKGROUND, label: "Ảnh background 1200x628" },
                { value: LAYER_TYPE.AVATAR_SQUARE, label: "Avatar vuông" },
                { value: LAYER_TYPE.AVATAR_CIRCLE, label: "Avatar tròn" },
              ]}
              placeholder="Chọn loại layer"
              readOnly
            />
            {
              !!layers_temp.type && layers_temp.type.value === LAYER_TYPE.BACKGROUND && (
                <div style={{ marginTop: 25 }}>
                  <Select
                    className="react-select primary"
                    classNamePrefix="react-select"
                    name="singleSelect"
                    value={layers_temp.subType}
                    onChange={value => {
                      this.dot.setValue('layers_temp.subType', value);
                      if (!!value) {
                        if (value.value === 'UPLOAD') {
                          this.input.click()
                        } else {
                          this.dot.setValue('layers_temp.data.background', value.value);
                        }
                      }
                    }}
                    options={preBGs}
                    placeholder="Chọn background"
                    readOnly
                  />
                </div>
              )
            }
            {
              !!layers_temp.type && (layers_temp.type.value === LAYER_TYPE.AVATAR_CIRCLE || layers_temp.type.value === LAYER_TYPE.AVATAR_SQUARE) && (
                <div style={{ marginTop: 25 }}>
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    properties={[
                      {
                        label: "Top",
                        type: "number",
                        name: "description",
                        bsClass: "form-control",
                        placeholder: "px",
                        value: layers_temp.data.top,
                        onChange: e => this.dot.setValue('layers_temp.data.top', e.target.value),
                      },
                      {
                        label: "Left",
                        type: "number",
                        name: "description",
                        bsClass: "form-control",
                        placeholder: "px",
                        value: layers_temp.data.left,
                        onChange: e => this.dot.setValue('layers_temp.data.left', e.target.value),
                      }
                    ]}
                  />
                  <FormInputs
                    ncols={["col-md-6", "col-md-6"]}
                    properties={[
                      {
                        label: "Size",
                        type: "number",
                        name: "description",
                        bsClass: "form-control",
                        placeholder: "px",
                        value: layers_temp.data.size,
                        onChange: e => this.dot.setValue('layers_temp.data.size', e.target.value),
                      },
                      {
                        label: "Frame",
                        customRender: () => (
                          <Checkbox
                            number={1}
                            isChecked={layers_temp.data.includeFrame}
                            label="Include?"
                            onChange={() => this.dot.setValue('layers_temp.data.includeFrame', !layers_temp.data.includeFrame)}
                            usePropChecked
                          />
                        )
                      },
                    ]}
                  />
                </div>
              )
            }
            <Button bsStyle="danger" block fill style={{ marginTop: 25 }} onClick={this.saveTempLayer}>
              Lưu layer
            </Button>
            <hr className="hr" style={{ marginTop: 25 }} />
            <div>
              {layers.map((val, i) => (
                <div key={"layer-name-item-" + i}
                  style={{ padding: '10px 20px', marginTop: 10, fontSize: 10, backgroundColor: 'rgba(0,0,0,0.2)', textAlign: 'center', color: 'white' }}>
                  {val.type.label}
                  <i className="fa fa-arrow-up" onClick={() => this.setState({ layers: arrayMove(layers, i, i + 1), })} />
                  <i className="fa fa-arrow-down" onClick={() => this.setState({ layers: arrayMove(layers, i, i - 1), })} />
                  <i className="fa fa-times" onClick={() => {
                    this.setState({ layers: [...layers.slice(0, i), ...layers.slice(i + 1, layers.length)] })
                  }} />
                </div>
              ))}
            </div>
          </Col>
          <Col md={9}>
            <div style={{ width: '100%', paddingTop: '52.33%', backgroundColor: 'rgba(0,0,0,0.1)', position: 'relative' }}>
              {layers.map((val, i) => (
                <div key={"layer-container-item-" + i}
                  style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                  {!!val.background ? (
                    <img src={val.background} style={{ width: '100%', height: 'auto' }} />
                  ) : (
                      // <img
                      //   src={dummyUserAvatar}
                      //   style={{
                      //     position: 'absolute',
                      //     width: !!val.avatar.size ? +val.avatar.size / this.state.ratio : 0,
                      //     height: !!val.avatar.size ? +val.avatar.size / this.state.ratio : 0,
                      //     top: !!val.avatar.top ? +val.avatar.top / this.state.ratio : 0,
                      //     left: !!val.avatar.left ? +val.avatar.left / this.state.ratio : 0,
                      //     borderRadius: val.avatar.circleAvatar ? '100%' : undefined,
                      //   }}
                      // />
                      this.renderImage({
                        size: !!val.avatar.size ? +val.avatar.size / this.state.ratio : 0,
                        top: !!val.avatar.top ? +val.avatar.top / this.state.ratio : 0,
                        left: !!val.avatar.left ? +val.avatar.left / this.state.ratio : 0,
                        includeFrame: val.avatar.includeFrame,
                        url: dummyUserAvatar,
                      })
                    )}
                </div>
              ))}
              {
                !!layers_temp.type && layers_temp.type.value === LAYER_TYPE.BACKGROUND && (
                  <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    {!!layers_temp.data.background && (
                      <img src={layers_temp.data.background} style={{ width: '100%', height: 'auto' }} />
                    )}
                  </div>
                )
              }
              {
                !!layers_temp.type && (layers_temp.type.value === LAYER_TYPE.AVATAR_CIRCLE || layers_temp.type.value === LAYER_TYPE.AVATAR_SQUARE) && (
                  <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                    {/* <img
                      src={dummyUserAvatar}
                      style={{
                        position: 'absolute',
                        width: !!layers_temp.data.size ? +layers_temp.data.size / this.state.ratio : 0,
                        height: !!layers_temp.data.size ? +layers_temp.data.size / this.state.ratio : 0,
                        top: !!layers_temp.data.top ? +layers_temp.data.top / this.state.ratio : 0,
                        left: !!layers_temp.data.left ? +layers_temp.data.left / this.state.ratio : 0,
                        borderRadius: layers_temp.type.value === LAYER_TYPE.AVATAR_CIRCLE ? '100%' : undefined,
                      }}
                    /> */}
                    {this.renderImage({
                      size: !!layers_temp.data.size ? +layers_temp.data.size / this.state.ratio : 0,
                      top: !!layers_temp.data.top ? +layers_temp.data.top / this.state.ratio : 0,
                      left: !!layers_temp.data.left ? +layers_temp.data.left / this.state.ratio : 0,
                      includeFrame: Boolean(layers_temp.data.includeFrame),
                      url: dummyUserAvatar,
                    })}
                  </div>
                )
              }
              <ReactResizeDetector
                handleWidth
                handleHeight
                onResize={(w, h) => this.setState({ w, h, ratio: w != 0 ? 1200 / w : 1 })}
              />
            </div>
            <input ref={ref => this.input = ref} type="file" style={{ display: 'none' }} onChange={this.uploadImage} accept=".png,.webp" />
          </Col>
        </Row>
        <hr className="hr" style={{ margin: "25px 0px" }} />
        <Row>
          <Col md={3}>
            <Button bsStyle="danger" block fill onClick={() => this.input2.click()}>
              Upload ảnh preview
            </Button>
          </Col>
          <Col md={9}>
            <div style={{ width: '100%', paddingTop: '52.33%', backgroundColor: 'rgba(0,0,0,0.1)', position: 'relative' }}>
              <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>
                {!!preview && (
                  <img src={preview} style={{ width: '100%', height: 'auto' }} />
                )}
              </div>
            </div>
          </Col>
        </Row>
        <input ref={ref => this.input2 = ref} type="file" style={{ display: 'none' }} onChange={this.uploadImagePreview} accept=".png,.webp" />
        <hr className="hr" style={{ margin: "25px 0px" }} />
      </Fragment>
    );
  }

  render() {
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Tạo Thumbnail"
                  category="Size 1200x628. Là hình ảnh được hiện ra trên post của Facebook, Messenger..."
                  content={
                    <form onSubmit={this.createThumb}>
                      <Row>
                        <Col md={9}>
                          <p style={{ marginTop: 20 }}>Thumbnail có thể là động (cá nhân hóa với từng user) hoặc tĩnh (ảnh giống nhau với tất cả user)</p>
                          <p>VD: Trang chủ, thể lệ chương trình, FAQ, đăng nhập, đăng ký, thumbnail hiển thị như nhau với mọi user</p>
                          <p>VD: Trang profile cá nhân, trang phần thi, thumbnail hiển thị có avatar của user...</p>
                          <p>Designer sẽ kết hợp các layer để server xử lý tạo ra ảnh theo thiết kế</p>
                          <p>Lưu ý: Nên dùng tối đa 3 layers, nên design kỹ trước khi cho lên trang này (trang này chỉ dùng để điền thông số)</p>
                          <p>Lưu ý: Những địa chỉ web không có trong danh sách Thumbnail được tạo, sẽ lấy ảnh KV mặc định làm thumbnail </p>
                        </Col>
                        <Col md={3}>
                          <img src={threeFlatsLayerImg} style={{ width: '100%', height: 'auto' }} />
                        </Col>
                      </Row>
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Mô tả",
                            type: "text",
                            name: "description",
                            bsClass: "form-control",
                            placeholder: "Thumbnail này sẽ dùng cho trang nào",
                            value: this.state.description,
                            onChange: e => this.setState({ description: e.target.value }),
                          }
                        ]}
                      />
                      <FormInputs
                        ncols={["col-md-12"]}
                        properties={[
                          {
                            label: "Matching URL",
                            type: "text",
                            name: "match-url",
                            bsClass: "form-control",
                            placeholder: "Để trống trường này cho Developer điền vào để match với url của microsite",
                            value: this.state.matchUrl,
                            onChange: e => this.setState({ matchUrl: e.target.value }),
                          }
                        ]}
                      />
                      {this.renderThumbnailLayers()}
                      <Button bsStyle="danger" pullRight fill type="submit">
                        Tạo ảnh thumbnail
                      </Button>
                      <div className="clearfix" />
                    </form>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }
}

export default withRouter(WebThumbnailCreate);
