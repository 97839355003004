import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.jsx";
import { Collapse } from "react-bootstrap";
// import logo from "assets/img/kichi_logo.png";

import Store from '../../store';
import { observer } from 'mobx-react';

function Icon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 64 64"
      {...props}
    >
      <path d="M62 31h-8.586l9.293-9.293a.999.999 0 000-1.414l-19-19a.999.999 0 00-1.414 0L12.586 31H2a1 1 0 00-1 1v26a1 1 0 001 1h14.586l3.707 3.707a.997.997 0 001.414 0L25.415 59H62a1 1 0 001-1V32a1 1 0 00-1-1zM43 3.414L60.586 21l-10 10h-7.635a10.913 10.913 0 00-3.173-6.778c-4.288-4.288-11.267-4.289-15.557 0A10.923 10.923 0 0021.048 31h-5.635zM27.227 31a2.001 2.001 0 01.53-1.828L29.586 31zm10.788 0a3.906 3.906 0 00-.358-.414 4.005 4.005 0 00-5.657 0l-2.829-2.828c.756-.755 2.072-.755 2.828 0l1.414-1.414c-.756-.755-1.76-1.171-2.828-1.171s-2.073.416-2.828 1.171l-.001.001-.707-.707-1.414 1.414.707.707a3.968 3.968 0 00-1.143 3.243h-2.141a8.935 8.935 0 012.576-5.364c3.51-3.509 9.219-3.508 12.729 0a8.93 8.93 0 012.576 5.364h-2.924zM21 60.586L19.414 59h3.172zM61 57H3V33h58z"></path>
      <path d="M29 48h-2c0 2.206 1.794 4 4 4v1h2v-1c2.206 0 4-1.794 4-4s-1.794-4-4-4v-4c1.103 0 2 .897 2 2h2c0-2.206-1.794-4-4-4v-1h-2v1c-2.206 0-4 1.794-4 4s1.794 4 4 4v4c-1.103 0-2-.897-2-2zm0-6c0-1.103.897-2 2-2v4c-1.103 0-2-.897-2-2zm4 4c1.103 0 2 .897 2 2s-.897 2-2 2z"></path>
      <path d="M32 56c6.065 0 11-4.935 11-11s-4.935-11-11-11-11 4.935-11 11 4.935 11 11 11zm0-20c4.963 0 9 4.038 9 9s-4.037 9-9 9-9-4.038-9-9 4.037-9 9-9zM59 54V36a1 1 0 00-1-1H46v2h11v16H46v2h12a1 1 0 001-1zM6 55h12v-2H7V37h11v-2H6a1 1 0 00-1 1v18a1 1 0 001 1z"></path>
      <path d="M46 49h2v2h-2zM50 49h2v2h-2zM46 39h2v2h-2zM50 39h2v2h-2zM43.272 9.414l11.313 11.314-7.778 7.778 1.414 1.414 8.485-8.485a.999.999 0 000-1.414L43.979 7.293a1.03 1.03 0 00-1.414 0l-8.485 8.485 1.414 1.414z"></path>
      <path
        d="M44.394 24.678h2v2h-2z"
        transform="rotate(-45 45.393 25.687)"
      ></path>
      <path
        d="M47.222 21.849h2v2h-2z"
        transform="rotate(-45 48.22 22.86)"
      ></path>
      <path
        d="M37.322 17.607h2v2h-2z"
        transform="rotate(-45 38.322 18.615)"
      ></path>
      <path
        d="M40.15 14.778h2v2h-2z"
        transform="rotate(-45 41.15 15.787)"
      ></path>
      <path d="M12 39h2v2h-2zM16 39h2v2h-2zM12 49h2v2h-2zM16 49h2v2h-2z"></path>
    </svg>
  );
}


@observer
class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth
    };
  }
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  updateDimensions() {
    this.setState({ width: window.innerWidth });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  render() {
    const isAuth = Store.User.isAuth();
    const routes = isAuth ? this.props.routes : [this.props.routes[0], this.props.routes[1]];
    const sidebarBackground = {
      backgroundImage: "url(" + this.props.image + ")"
    };
    return (
      <div
        id="sidebar"
        className="sidebar"
        data-color={this.props.color}
        data-image={this.props.image}
      >
          {this.props.hasImage ? (
            <div className="sidebar-background" style={sidebarBackground} />
          ) : (
            null
          )}
        <div className="logo">
          <a
            href="/admin/dashboard"
            className="simple-text logo-mini"
          >
            <div className="logo-img">
              {/* <img src={logo} alt="logo_image" /> */}
              <Icon fill="#fff" />
            </div>
          </a>
          <a
            href="/admin/dashboard"
            className="simple-text logo-normal"
          >
            Kho tiền năm sinh
          </a>
        </div>
        <div className="sidebar-wrapper">
          <ul className="nav">
            {this.state.width <= 991 ? <AdminNavbarLinks /> : null}
            {this.createLinks(routes)}
            {/* routes.map((prop, key) => {
              if (prop.hideInSidebar) return null;
              if (Store.User.userData.type === 'RESTAURANT' && prop.showInRestaurant !== true) return null;
              if (Store.User.userData.type === 'ADMIN' && prop.showInAdmin === false) return null;
              if (!prop.redirect)
                return (
                  <li
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : this.activeRoute(prop.layout + prop.path)
                    }
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            }) */}
          </ul>
        </div>
      </div>
    );
  }

  createLinks = (routes) => {
    const isAuth = Store.User.isAuth();
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.hideInSidebar) return null;
      if (Store.User.userData.type === 'RESTAURANT' && prop.showInRestaurant !== true) return null;
      if (Store.User.userData.type === 'ADMIN' && prop.showInAdmin === false) return null;
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <a
              href="#pablo"
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}
            >
              <i className={prop.icon} />
              <p>
                {prop.name}
                <b
                  className={
                    this.state[prop.state] ? "caret rotate-180" : "caret"
                  }
                />
              </p>
            </a>
            <Collapse in={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink
            to={prop.layout + prop.path}
            className="nav-link"
            activeClassName="active"
          >
            {prop.icon ? (
              <>
                <i className={prop.icon} />
                <p>{isAuth ? prop.nameWhenLoggedIn || prop.name : prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini">{prop.mini}</span>
                <span className="sidebar-normal">{isAuth ? prop.nameWhenLoggedIn || prop.name : prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };

  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
}

export default Sidebar;
