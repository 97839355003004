import React from 'react';
import { VarHelper } from 'helpers';

const dot = (parent) => {
  const dotParent = VarHelper.dotParent(parent);
  return {
    getValue: (label) => dotParent.get(label),
    setValue: (label, value, callback = () => {}) => dotParent.set(label, value, callback),
  }
}

interface Props {
  parent: any,
  children(getSetObject: {
    getParentValue: any,
    setParentValue: any,
  }): any,
}

const CompStateData = (props: Props) => {
  const { getValue, setValue } = dot(props.parent);
  return (
    props.children({ getParentValue: getValue, setParentValue: setValue })
  );
};

CompStateData.dot = dot;

export default CompStateData;