import React, { Component } from 'react';
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Button from "../CustomButton/CustomButton.jsx";
import Select from "react-select";

export default class Search extends Component {

  state = {
    keyword: '',
    searchType: undefined,

  }

  handleClick = (e) => {
    !!e && e.preventDefault();
    const { searchType, keyword } = this.state;
    const { onSearch } = this.props;
    if (!searchType) return window.notify.error('Cần chọn loại search');
    let obj = {
      key: searchType.value,
      value: !!searchType.selected ? searchType.selected.value : keyword,
    };
    onSearch(obj);
  }

  render() {
    const { searchType, keyword } = this.state;
    const { options } = this.props;
    return (
      <form style={{ marginBottom: 25 }} onSubmit={this.handleClick}>
        <FormInputs
          ncols={["col-md-4", "col-md-6", "col-md-2"]}
          properties={[
            {
              label: "Loại",
              customRender: () => (
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={searchType}
                  onChange={value =>
                    this.setState({ searchType: value })
                  }
                  options={options}
                  placeholder="Trường tìm kiếm"
                />
              )
            },
            !searchType || searchType.type === 'text' ? {
              label: "Keyword",
              type: "text",
              bsClass: "form-control",
              placeholder: "",
              value: keyword,
              onChange: e => this.setState({ keyword: e.target.value }),
              onKeyDown: e => e.keyCode === 13 && this.handleClick(),
            } : {
              label: "Keyword",
              customRender: () => (
                <Select
                  className="react-select primary"
                  classNamePrefix="react-select"
                  name="singleSelect"
                  value={searchType.selected}
                  onChange={value =>
                    this.setState({ searchType: { ...searchType, selected: value } })
                  }
                  options={searchType.selectOptions}
                  placeholder="Trường tìm kiếm"
                />
              )
            },
            {
              label: "",
              customRender: () => (
                <Button bsStyle="danger" block fill style={{ marginTop: 5 }} onClick={this.handleClick}>
                  Tìm kiếm
              </Button>
              )
            }
          ]}
        />
      </form>
    );
  }

}