import React, { Component } from "react";
import { Grid, Row, Col, Table } from "react-bootstrap";
import Request from '../store/_Request';
import Card from "components/Card/Card.jsx";
import Container from "components/Misc/Container";
import Button from "components/CustomButton/CustomButton.jsx";
import { FormInputs } from "components/FormInputs/FormInputs.jsx";
import Store from '../store'
import Checkbox from "components/CustomCheckbox/CustomCheckbox.jsx";
import { observer } from 'mobx-react';

@observer
class Frame extends Component {

  state = {
    isLoading: false,
    tempUrl: '',
    avatar: '',
    shapeSquare: true,
    shapeCircle: false,
  }

  onReady = async () => {
    await Store.Frame.getFrames();
  }

  uploadImage = async (e) => {
    try {
      this.setState({ isLoading: true });
      const file = e.target.files[0];
      const uploadedUrl = await Request.upload(file);
      this.setState({ tempUrl: uploadedUrl, isLoading: false });
    } catch (err) {
      this.setState({ isLoading: false });
    }
  }

  createFrame = async () => {
    const { tempUrl } = this.state;
    if (!tempUrl) return window.notify.error('Vui lòng upload 1 ảnh Frame lên để tiếp tục');
    this.setState({ isLoading: true });
    
    const res = await Store.Frame.createFrame({ url: tempUrl });
    if (!res.success) {
      window.notify.error(res.message);
    } else {
      Store.Frame.getFrames();
    }
    this.setState({ isLoading: false, tempUrl: '' });
    window.scrollTo(0,0);
  }

  removeFrame = async (val, i) => {
    const res = await Store.Frame.removeFrame(val._id);
    await Store.Frame.getFrames();
  }

  chooseDefault = async (val, i) => {
    const res = await Store.Frame.updateCurrentFrame(val._id);
    await Store.Frame.getFrames();
  }

  renderImage = (size, url) => {
    const { avatar, shapeSquare } = this.state;
    const styleRadius = shapeSquare ? {  } : { borderRadius: '100%', overflow: 'hidden' };
    return (
      <div style={{ position: 'relative', width: size, height: size, ...styleRadius }}>
        {!!avatar && <img src={avatar} style={{ position: 'absolute', width: size, height: size, objectFit: 'cover', ...styleRadius}} />}
        {!!url && <img src={url} style={{ position: 'absolute', width: size, height: size, objectFit: 'cover', ...styleRadius}} />}
      </div>
    )
  }

  render() {
    const { tempUrl, shapeSquare } = this.state;
    const styleRadius = shapeSquare ? {  } : { borderRadius: '100%', overflow: 'hidden' };
    let frameNotPublic = Store.Frame.frames.slice().filter(val => val.public === false);
    let framePublic = Store.Frame.frames.slice().filter(val => val.public);
    const activeUrl = framePublic.length > 0 ? framePublic[0].url : '';
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row>
              <Col md={12}>
                <Card
                  title="Mẫu Frame đang dùng"
                  category="Người chơi tham gia Microsite sẽ thấy mẫu frame này"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div style={{ padding: 15 }}>
                      <Row>
                        <Col md={4}>
                          <div style={{ position: 'relative', backgroundColor: 'rgba(0,0,0,0.2)', width: 250, height: 250, marginBottom: 25, ...styleRadius }}>
                            {/* {!!tempUrl && <img src={tempUrl} style={{ width: 250, height: 250, objectFit: 'cover', ...styleRadius }} />} */}
                            {this.renderImage(250, activeUrl)}
                          </div>
                        </Col>
                        <Col md={8}>
                          <p>Các mẫu Frame hiện có</p>
                          <div>
                            {frameNotPublic.map((val, i) => (
                              <div style={{ display: 'inline-block', marginLeft: 15 }} key={"frame-"+i}>
                                {/* <img src={val.url} style={{ width: 150, height: 150, objectFit: 'cover', ...styleRadius }} /> */}
                                {this.renderImage(150, val.url)}
                                <p>
                                  <span className="cursor" onClick={() => this.removeFrame(val, i)}>Xóa</span>
                                  <span className="cursor pull-right" onClick={() => this.chooseDefault(val, i)}>Chọn</span>
                                </p>
                                <div className="clearfix" />
                              </div>
                            ))}
                          </div>
                        </Col>
                        <Col md={12}>
                          <FormInputs
                            ncols={["col-md-12"]}
                            properties={[
                              {
                                label: "Test với ảnh avatar",
                                type: "text",
                                bsClass: "form-control",
                                placeholder: "",
                                value: this.state.avatar,
                                onChange: e => this.setState({ avatar: e.target.value }),
                              },
                            ]}
                          />

                          <Checkbox
                            number={1}
                            isChecked={this.state.shapeCircle}
                            label="Tròn"
                            onChange={() => this.onChangeShapre('Circle')}
                            usePropChecked
                          />
                          <Checkbox
                            number={2}
                            isChecked={this.state.shapeSquare}
                            label="Vuông"
                            onChange={() => this.onChangeShapre('Square')}
                            usePropChecked
                          />
                        </Col>
                      </Row>
                    </div>
                  }
                />
              </Col>

              <Col md={12}>
                <Card
                  title="Đăng mẫu Frame mới"
                  category="Một mẫu Frame được chọn sẽ hiển thị cho Người chơi sử dụng"
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <div style={{ padding: 15 }}>
                      <div style={{ position: 'relative', backgroundColor: 'rgba(0,0,0,0.2)', width: 250, height: 250, marginBottom: 25, ...styleRadius }}>
                        {/* {!!tempUrl && <img src={tempUrl} style={{ width: 250, height: 250, objectFit: 'cover', ...styleRadius }} />} */}
                        {this.renderImage(250, tempUrl)}
                      </div>
                      <input ref={ref => this.input = ref} type="file" style={{ display: 'none' }} onChange={this.uploadImage} accept=".png,.webp" />
                      <div>
                      <Button bsStyle="primary" fill onClick={() => this.input.click()} disabled={this.state.isLoading} style={{ marginRight: 15 }}>
                        Chọn ảnh
                      </Button>
                      <Button bsStyle="default" fill onClick={() => this.setState({ tempUrl: '' })} disabled={this.state.isLoading} style={{ marginRight: 15 }}>
                        Xóa ảnh
                      </Button>
                      <Button bsStyle="danger" fill onClick={this.createFrame} disabled={this.state.isLoading} style={{ marginRight: 15 }}>
                        Đăng Frame
                      </Button>
                      </div>
                      <div className="clearfix" />
                    </div>
                  }
                />
              </Col>
            </Row>
          </Grid>
        </div>
      </Container>
    );
  }

  onChangeShapre = (label) => {
    this.setState({
      shapeCircle: false,
      shapeSquare: false,
      ['shape'+label]: true,
    });
  }
}

export default Frame;
