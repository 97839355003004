import React, { Component } from "react";
import { Grid, Row, Col, Table, Pagination } from "react-bootstrap";
import Button from "components/CustomButton/CustomButton.jsx";
import Card from "components/Card/Card.jsx";
import { Link } from "react-router-dom";
import Store from '../../store';
import Container from "components/Misc/Container";
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom'
import Paging from "components/Paging/Paging"

import { TimeHelper } from "../../helpers"

@observer
class WebThumbnail extends Component {

  onReady = async () => {
    this.getListUser();
  }

  getListUser = async () => {
    const res = await Store.Thumbnail.getThumbs();
  }
  render() {
    const { isBac, isNam, registerRegion } = this.props;
    const thArray = ["Ảnh Preview", "Description", "Thời gian cập nhật"];
    const tdArray = Store.Thumbnail.thumbs.slice().map(val => {
      return [
        val._id,
        val.preview,
        val.description,
        TimeHelper.changeFormat(undefined, val.updatedAt),
      ]
    })
    return (
      <Container onReady={this.onReady}>
        <div className="content">
          <Grid fluid>
            <Row style={{ marginBottom: 25 }}>
              <Col md={3}>
                <Link to="/admin/thumbnail/create">
                  <Button bsStyle="danger" block fill>
                    Tạo ảnh Web Thumbnail
                  </Button>
                </Link>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <Card
                  title={`Danh sách các ảnh thumbnail khi share trên mạng xã hội`}
                  category={`Mỗi 1 cụm url khác nhau sẽ có ảnh khác nhau. Trang này dùng cho designer`}
                  ctTableFullWidth
                  ctTableResponsive
                  content={
                    <Table striped hover>
                      <thead>
                        <tr>
                          {thArray.map((prop, key) => {
                            return <th key={key}>{prop}</th>;
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {tdArray.map((prop, key) => {
                          return (
                            <tr key={key}>
                              {prop.map((val, index) => {
                                if (index === 0) return null;
                                return (
                                  <td key={index}>
                                    <Link to={"/admin/thumbnail/detail/" + prop[0]} style={{ color: 'black' }}>
                                      {index === 1 ? (
                                        <img src={val} style={{ width: 400, height: 'auto' }} />
                                      ) : val}
                                    </Link>
                                  </td>
                                )
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  }
                />
              </Col>
            </Row>
          </Grid>


        </div>
      </Container>
    );
  }
}

export default withRouter(WebThumbnail);
