import React, { Component } from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import { observer } from 'mobx-react';
import Store from '../../store'

@observer
export default class RedirectWhenNotAuthen extends Component {

  state = {
    isAuth: true,
  }

  async componentDidMount() {
    await Store.User.onReady();
    // this.setState({
    //   isAuth: Store.User.isAuth(),
    // })
  }

  render() {
    const isAuth = Store.User.isAuth();
    return isAuth ? null : <Redirect to="/admin/dashboard" />
  }
}